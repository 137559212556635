import React, { useState, useMemo, useContext } from "react";
import {
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useImmer } from "use-immer";
import EditIcon from "@mui/icons-material/Edit";

const EditSingleDataSeries = ({
  index,
  dataset,
  dataSeries,
  resample,
  setIsTargetFeature,
  lookupMetadata,
  handleUpdateDataSeries,
  handleRemoveDataSeries,
}) => {
  const [tempValues, setTempValues] = useImmer(dataSeries || {});
  const [open, setOpen] = useState(false);
  const [applyToAll, setApplyToAll] = useState(false);

  let correspondingMetadata = useMemo(
    () => lookupMetadata(dataSeries.id),
    [dataSeries.id]
  );

  const handleChange = (prop) => (event) => {
    setTempValues({ ...tempValues, [prop]: event.target.value });
  };

  const handleCheckboxChange = (prop) => (event) => {
    setIsTargetFeature(index);
  };

  const handleClickOpen = () => {
    setApplyToAll(false);
    setTempValues({ ...dataSeries }); // Copy current values to temporary state before opening the dialog
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    handleUpdateDataSeries(applyToAll ? -1 : index, { ...tempValues });
    setOpen(false);
  };

  const showValueConversion =
    (dataSeries.value_multiplier && dataSeries.value_multiplier != 1.0) ||
    (dataSeries.value_adder && dataSeries.value_adder != 0.0);
  const showValueFilter =
    dataSeries.exclude_value_lt || dataSeries.exclude_value_gt;
  const showTimestampShift =
    dataSeries.time_shift &&
    dataSeries.time_shift != 0 &&
    dataSeries.time_shift_units;
  const showFillMethod = resample && dataSeries.fill_method;
  const showAny =
    showValueConversion ||
    showValueFilter ||
    showTimestampShift ||
    showFillMethod;
  return (
    <Card
      sx={{
        mt: "10px",
        mb: "0px",
        p: 3,
        pb: 0,
        borderTop: "1px",
        borderTopColor: "rgba(127,127,127,0.3)",
        borderTopStyle: "solid",
        minHeight: "50px",
      }}
      elevation={0}
    >
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Tooltip
            title={
              <div style={{ whiteSpace: "pre-line" }}>
                Short data series label (click to edit)
              </div>
            }
            arrow
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                mb: 1,
                cursor: "pointer",
                display: "inline-block",
              }}
              onClick={handleClickOpen}
            >
              <span>{dataSeries.label}</span>
            </Typography>
          </Tooltip>
          <Tooltip
            title={
              <div style={{ whiteSpace: "pre-line" }}>
                {dataSeries.full_desc +
                  "\n\n" +
                  correspondingMetadata?.path_descr}
              </div>
            }
            arrow
          >
            <Typography variant="body1" sx={{ mb: 1 }}>
              Unique ID: {correspondingMetadata?.user_sk}
            </Typography>
          </Tooltip>
          <Tooltip
            title={
              correspondingMetadata?.first_measurement &&
              correspondingMetadata?.last_measurement
                ? correspondingMetadata?.first_measurement.split("T", 1) +
                  "  - " +
                  correspondingMetadata?.last_measurement.split("T", 1) +
                  " (UTC)"
                : ""
            }
            arrow
          >
            <Typography sx={{ mb: 1, display: "inline-block" }} variant="body1">
              Samples/day: {correspondingMetadata?.avg_values_per_day}
            </Typography>
          </Tooltip>
          <Box display="flex" alignItems="right">
            <IconButton
              size="small"
              sx={{
                ml: "3px",
                width: "20px",
                "&:hover": { color: "secondary.main" },
              }}
              onClick={() => handleRemoveDataSeries(index)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
            <FormControlLabel
              sx={{ ml: "10px" }}
              control={
                <Checkbox
                  size="small"
                  checked={dataSeries.is_target_feature}
                  onChange={handleCheckboxChange("is_target_feature")}
                  inputProps={{
                    "aria-label": "Is Target Feature",
                  }}
                />
              }
              label="Is Target Feature"
            />
          </Box>
        </Grid>
        <Grid item xs={5} sx={{ borderLeft: "1px solid lightgrey", pl: 2 }}>
          <Box
            onClick={handleClickOpen}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              cursor: "pointer",
              borderRadius: 2,
              "&:hover": {
                boxShadow: 2,
                backgroundColor: "#f2f0f0",
              },
              p: 1,
            }}
          >
            {showAny ? (
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Filters and Transformations:
              </Typography>
            ) : null}
            <ul>
              {showValueConversion ? (
                <li>
                  <Typography variant="body1">
                    Value conversion: (Value *{" "}
                    {dataSeries.value_multiplier
                      ? dataSeries.value_multiplier
                      : "1.0"}
                    ) +{dataSeries.value_adder ? dataSeries.value_adder : "0"}
                  </Typography>
                </li>
              ) : null}
              {showValueFilter ? (
                <li>
                  <Typography variant="body1">
                    Exclude values{" "}
                    {dataSeries.exclude_value_lt
                      ? `less than ${dataSeries.exclude_value_lt}`
                      : null}
                    {dataSeries.exclude_value_lt && dataSeries.exclude_value_gt
                      ? " or "
                      : null}
                    {dataSeries.exclude_value_gt
                      ? `greater than ${dataSeries.exclude_value_gt}`
                      : null}
                  </Typography>
                </li>
              ) : null}
              {showTimestampShift ? (
                <li>
                  <Typography variant="body1">
                    Timestamps will be shifted by{" "}
                    {Math.abs(dataSeries.time_shift)}
                    {Math.abs(dataSeries.time_shift) == 1.0
                      ? " " + dataSeries.time_shift_units.slice(0, -1) + " "
                      : " " + dataSeries.time_shift_units + " "}
                    towards the {dataSeries.time_shift < 0 ? "past" : "future"}
                  </Typography>
                </li>
              ) : null}
              {showFillMethod ? (
                <li>
                  <Typography variant="body1">
                    Missing values will be filled using {dataSeries.fill_method}
                  </Typography>
                </li>
              ) : null}
            </ul>
            {showAny ? null : (
              <Typography variant="body1">
                No filters or transformations
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"></DialogTitle>
        <DialogContent m="10px 0">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0px",
              flexWrap: "wrap",
              alignItems: "left",
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: "20px" }}>
              {correspondingMetadata?.user_sk}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                flexWrap: "wrap",
                alignItems: "left",
              }}
            >
              <TextField
                variant="standard"
                sx={{ width: "80%", minWidth: "80px" }}
                label="Column or Legend Label"
                value={tempValues.label}
                onChange={handleChange("label")}
              />
            </Box>

            <Typography variant="h5" sx={{ fontWeight: "bold", mt: "20px" }}>
              Filters and Transformations
            </Typography>
            <Typography variant="body" sx={{ fontWeight: "bold", mt: "20px" }}>
              Convert
            </Typography>
            <Typography variant="body" sx={{ mb: "12px" }}>
              Transform incoming values using a fixed multiplier and/or adder.
              For example, to convert values from F to C, specify a multiplier
              of 0.5555 and an adder of -17.777
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                flexWrap: "wrap",
                alignItems: "left",
              }}
            >
              <TextField
                size="small"
                variant="outlined"
                type="number"
                sx={{ width: "40%", minWidth: "80px" }}
                label="Value Multiplier"
                value={tempValues.value_multiplier}
                onChange={handleChange("value_multiplier")}
              />
              <TextField
                size="small"
                variant="outlined"
                type="number"
                sx={{ width: "40%", minWidth: "80px" }}
                label="Value Adder"
                value={tempValues.value_adder}
                onChange={handleChange("value_adder")}
              />
            </Box>

            <Typography variant="body" sx={{ fontWeight: "bold", mt: "20px" }}>
              Filter
            </Typography>
            <Typography variant="body" sx={{ mb: "12px" }}>
              Filter incoming values. Filters are applied after any multiplier
              and adder. This filter applies only to this data series--other
              measurements with the same timestamp are unaffected
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                flexWrap: "wrap",
                alignItems: "left",
              }}
            >
              <TextField
                size="small"
                variant="outlined"
                type="number"
                sx={{ width: "40%", minWidth: "150px" }}
                label="Exclude values less than"
                value={tempValues.exclude_value_lt}
                onChange={handleChange("exclude_value_lt")}
              />
              <TextField
                size="small"
                variant="outlined"
                type="number"
                sx={{ width: "40%", minWidth: "150px" }}
                label="Exclude values greater than"
                value={tempValues.exclude_value_gt}
                onChange={handleChange("exclude_value_gt")}
              />
            </Box>

            <Typography variant="body" sx={{ fontWeight: "bold", mt: "20px" }}>
              Time Shift
            </Typography>
            <Typography variant="body" sx={{ mb: "12px" }}>
              Translate the incoming timestamps of this data series by a fixed
              amount. Positive values are towards future
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                flexWrap: "wrap",
                alignItems: "left",
              }}
            >
              <TextField
                size="small"
                variant="outlined"
                type="number"
                sx={{ width: "40%", minWidth: "75px" }}
                value={tempValues.time_shift}
                onChange={handleChange("time_shift")}
              />
              <Select
                size="small"
                variant="outlined"
                sx={{ width: "40%", minWidth: "75px" }}
                value={tempValues.time_shift_units}
                onChange={(event) => handleChange("time_shift_units")(event)}
              >
                <MenuItem value="seconds">seconds</MenuItem>
                <MenuItem value="minutes">minutes</MenuItem>
                <MenuItem value="hours">hours</MenuItem>
                <MenuItem value="days">days</MenuItem>
                <MenuItem value="weeks">weeks</MenuItem>
                <MenuItem value="months">months</MenuItem>
                <MenuItem value="years">years</MenuItem>
              </Select>
            </Box>
            <Typography variant="body" sx={{ fontWeight: "bold", mt: "20px" }}>
              Fill
            </Typography>
            <Typography variant="body" sx={{ mb: "12px" }}>
              How missing values are imputed if resampling
            </Typography>
            <Select
              size="small"
              variant="outlined"
              sx={{ width: "40%", minWidth: "75px" }}
              value={tempValues.fill_method}
              onChange={(event) => handleChange("fill_method")(event)}
            >
              <MenuItem value="ffill">Forward Fill</MenuItem>
              <MenuItem value="bfill">Backwards Fill</MenuItem>
              <MenuItem value="interpolate">Linear Interpolation</MenuItem>
            </Select>
          </Box>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox
                checked={applyToAll}
                onChange={(event) => setApplyToAll(event.target.checked)}
                color="primary"
              />
            }
            label="Apply to all data series"
          />
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="secondary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default EditSingleDataSeries;
