import React, { useMemo, useState, useContext, useRef, useEffect } from "react";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import EditSingleDataSeries from "./EditSingleDataSeries";

const DataSeriesListDisplay = ({
  dataSeriesList,
  setIsTargetFeature,
  lookupMetadata,
  handleUpdateDataSeries,
  handleRemoveDataSeries,
}) => {
  return (
    <Box>
      <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
        {dataSeriesList ? dataSeriesList.length : "0"} Data series selected:
      </Typography>
      {dataSeriesList &&
        dataSeriesList.map((dataSeries, index) => (
          <EditSingleDataSeries
            dataSeriesList={dataSeriesList}
            key={index}
            index={index}
            dataSeries={dataSeries}
            lookupMetadata={lookupMetadata}
            handleUpdateDataSeries={handleUpdateDataSeries}
            handleRemoveDataSeries={handleRemoveDataSeries}
            setIsTargetFeature={setIsTargetFeature}
          />
        ))}
    </Box>
  );
};

export default DataSeriesListDisplay;
