import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  createContext,
  Fragment,
} from "react";
import { Box, useTheme } from "@mui/material";
import axios from "axios";
import Header from "../../components/Header";
import { tokens, colorToRgba } from "../../theme";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ChatDisplay from "./ChatDisplay";
import ChatEntry from "./ChatEntry";
import ContextDisplay from "./ContextDisplay";
import ContextSelector from "./ContextSelector";
import { useImmer } from "use-immer";
import { UserContext } from "../../Auth.js";
import { ApiContext } from "../../App";
import { getImageMimeType } from "./ChatContextCard";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import QueryBuilder from "../../components/queryBuilder/QueryBuilder.jsx";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Tooltip from "@mui/material/Tooltip";
import { CollapsedContext } from "../../App.js";
import ChatContextCard from "./ChatContextCard";

export const ActiveChatContextRidContext = createContext(0);
export const SelectedModelRidContext = createContext(0);

const { v4: uuidv4 } = require("uuid");

const ChatComponent = () => {
  const [contextSelectorOpen, setContextSelectorOpen] = useState(false);
  const user = useContext(UserContext);
  const api = useContext(ApiContext);

  function getNewSessionId() {
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().padStart(4, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const hour = currentDate.getHours().toString().padStart(2, "0");
    const minute = currentDate.getMinutes().toString().padStart(2, "0");
    const second = currentDate.getSeconds().toString().padStart(2, "0");
    const millisecond = currentDate
      .getMilliseconds()
      .toString()
      .padStart(3, "0");
    const random = Math.floor(Math.random() * 1000000)
      .toString()
      .padStart(6, "0");
    return `${year}-${month}-${day}-${hour}-${minute}-${second}-${millisecond}-${random}`;
  }

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const collapsedContext = useContext(CollapsedContext);
  const isCollapsedState = collapsedContext.isCollapsed;
  const [sessionId, setSessionId] = useState(getNewSessionId());
  const [chatHistory, setChatHistory] = useImmer([]);
  const [pendingResponse, setPendingResponse] = useState({});
  const [isWaiting, setIsWaiting] = useState(false);
  const [showThoughts, setShowThoughts] = useState(false);
  const [showFullPrompt, setShowFullPrompt] = useState(false);
  const [chatContexts, setChatContexts] = useState([
    { title: "", description: "", examples: [] },
  ]);

  const [activeChatContextRid, setActiveChatContextRid] = useState(
    //localStorage.getItem("activeChatContextRid")
    user.get_default_chat_context_rid
  );
  const cancelTokenSourceRef = useRef(null);
  const [inputValue, setInputValue] = useState(null);

  const switchContext = (newActiveContextRid) => {
    if (isWaiting) {
      cancelRequest();
    }
    clearChatHistory();
    setActiveChatContextRid(newActiveContextRid);
  };

  const fetchChatContexts = async () => {
    try {
      const response = await api.get("/chat/get_contexts");
      setChatContexts(response.data);
      if (response.data.some((obj) => obj.rid === activeChatContextRid)) {
        localStorage.setItem("activeChatContextRid", activeChatContextRid);
      } else {
        setActiveChatContextRid(response.data[0].rid);
        localStorage.setItem("activeChatContextRid", response.data[0].rid);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [selectedModel, setSelectedModel] = useState(0);

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  //  Get list of available chat contexts
  useEffect(() => {
    fetchChatContexts();
  }, []);

  const [isScrolledToTop, setIsScrolledToTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolledToTop(window.scrollY < 500);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const _sendMessageLongPoll = async (
    paramPendingResponse,
    message,
    llmChatEndpointRid,
    useWebSearchOpt,
    webSearchRid
  ) => {
    let newPendingResponse = JSON.parse(JSON.stringify(paramPendingResponse));
    try {
      cancelTokenSourceRef.current = axios.CancelToken.source();
      api
        .post(
          "/question_long_poll",
          {
            user: message,
            sessionId: sessionId,
            requestId: newPendingResponse.requestId,
            contextRidList: newPendingResponse.contextRidList,
            llmChatEndpointRid: llmChatEndpointRid,
            webSearchRid: webSearchRid,
            useWebSearch: useWebSearchOpt,
          },
          {
            timeout: 600000, // 10 minutes in milliseconds
            cancelToken: cancelTokenSourceRef.current.token,
          }
        )
        .then((res) => {
          //  Construct full response text
          let hasFinalResponse = false;
          res.data.forEach((chatStreamingResponse, index) => {
            if (chatStreamingResponse.message_type === "full_prompt") {
              // Construct full prompt
              if (!newPendingResponse.fullPrompt) {
                let fullPromptText = "## Full Prompt\n---\n";
                chatStreamingResponse.payload.forEach((obj, index) => {
                  fullPromptText +=
                    "### " + obj.role + "\n```text" + obj.content + "```\n";

                  // Add a newline between objects, but not after the last object
                  if (index < chatStreamingResponse.payload.length - 1) {
                    fullPromptText += "\n";
                  }
                });

                newPendingResponse.fullPrompt = fullPromptText;
              }
            } else if (
              chatStreamingResponse.message_type === "iteration_header"
            ) {
              if (!newPendingResponse.thought) {
                newPendingResponse.thought = "## Thought Chain\n---\n";
              }
              newPendingResponse.thought =
                newPendingResponse.thought +
                "\n\n### " +
                chatStreamingResponse.payload;
            } else if (chatStreamingResponse.message_type === "thought") {
              if (!newPendingResponse.thought) {
                newPendingResponse.thought = "## Thought Chain\n---\n";
              }
              newPendingResponse.thought =
                newPendingResponse.thought +
                "\n" +
                chatStreamingResponse.payload;

              //  Display progress message and percentage
              newPendingResponse.progressMsg =
                chatStreamingResponse.status_message;
              newPendingResponse.progressPcnt =
                chatStreamingResponse.estimated_completion_pcnt;

              //  Display elapsed time and tokens used
              if (
                chatStreamingResponse.elapsed_time_sec ||
                chatStreamingResponse.prompt_tokens ||
                chatStreamingResponse.completion_tokens
              ) {
                newPendingResponse.thought +=
                  "\n**(" +
                  chatStreamingResponse.elapsed_time_sec.toFixed(1) +
                  "s";

                if (chatStreamingResponse.prompt_tokens > 0) {
                  newPendingResponse.thought +=
                    ", " +
                    chatStreamingResponse.prompt_tokens +
                    " prompt tokens";
                }
                if (chatStreamingResponse.completion_tokens > 0) {
                  newPendingResponse.thought +=
                    ", " +
                    chatStreamingResponse.completion_tokens +
                    " completion tokens";
                }
                if (
                  chatStreamingResponse.completion_tokens +
                    chatStreamingResponse.prompt_tokens >
                  0
                ) {
                  newPendingResponse.thought +=
                    ", " +
                    (
                      chatStreamingResponse.completion_tokens +
                      chatStreamingResponse.prompt_tokens
                    ).toFixed(0) +
                    " total tokens";
                }
                newPendingResponse.thought += ")**";
              }
            } else if (
              chatStreamingResponse.message_type === "final_response"
            ) {
              newPendingResponse.text += chatStreamingResponse.payload;
            } else if (chatStreamingResponse.message_type === "error") {
              newPendingResponse.error += chatStreamingResponse.payload;
            }
            if (!newPendingResponse.figures) {
              newPendingResponse.figures = [];
            }
            if (chatStreamingResponse.figures) {
              newPendingResponse.figures = [
                ...newPendingResponse.figures,
                ...chatStreamingResponse.figures,
              ];
            }

            hasFinalResponse =
              hasFinalResponse || chatStreamingResponse.final_response;
          });

          if (hasFinalResponse) {
            if (newPendingResponse.thought) {
              newPendingResponse.thought += "\n\n## Response\n---\n";
            }
            setIsWaiting(false);
            setPendingResponse({});
            setChatHistory((prevHistory) => [
              ...prevHistory,
              newPendingResponse,
            ]);
          } else {
            setPendingResponse((prev) => newPendingResponse);
            _sendMessageLongPoll(
              newPendingResponse,
              "",
              llmChatEndpointRid,
              useWebSearchOpt,
              webSearchRid
            );
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            newPendingResponse.error = "Cancelled by user";
          } else {
            console.log("CHAT RESPONSE Error:", error);
            console.log("CHAT RESPONSE Stack:", error.stack);
            newPendingResponse.error = JSON.stringify(error);
          }
          setIsWaiting(false);
          setPendingResponse({});
          setChatHistory((prevHistory) => [...prevHistory, newPendingResponse]);
        })
        .finally(() => {});
    } catch (error) {
      console.error("CHAT: Error sending message:", error);
    }
  };

  const sendMessageLongPoll = async (
    message,
    llmChatEndpointRid,
    useWebSearchOpt,
    webSearchRid
  ) => {
    if (message === "") return;

    console.log("UseWebSearchOpt", useWebSearchOpt);
    // Add new user message to chat history
    const newMessage = { type: "user", text: message };
    setChatHistory((prevHistory) => [...prevHistory, newMessage]);

    // Create a blank pending response object
    let newPendingResponse = {
      type: "api",
      requestId: uuidv4(),
      contextRidList: [activeChatContextRid],
      fullPrompt: "",
      text: "",
      error: "",
      progressPcnt: 0,
      progressMsg: "Sending request...",
      thought: "",
    };
    setPendingResponse(newPendingResponse);
    setIsWaiting(true);

    _sendMessageLongPoll(
      newPendingResponse,
      message,
      llmChatEndpointRid,
      useWebSearchOpt,
      webSearchRid
    );
  };

  const sendMessageStream = async (message) => {
    if (message === "") return;

    // Update chat history
    const newMessage = { type: "user", text: message };
    setChatHistory((prevHistory) => [...prevHistory, newMessage]);

    // Add placeholder for response
    let apiMessage = {
      type: "api",
      fullPrompt: "",
      text: "",
      error: "",
      progressPcnt: 0,
      progressMsg: "",
      thought: "",
    };
    setChatHistory((prevHistory) => [...prevHistory, apiMessage]);

    setIsWaiting(true);
    try {
      let API_URL = "";
      if (process.env.NODE_ENV === "production") {
        API_URL = `https://${window.location.hostname}:8000/question_test`;
      } else {
        API_URL = `http://${window.location.hostname}:8000/question_test`;
      }

      const eventSource = new EventSource(API_URL);

      eventSource.onmessage = (event) => {
        const incomingMsg = JSON.parse(event.data);
        //  Get current response message
        let apiMessage = chatHistory[chatHistory.length - 1];
        if (!apiMessage) {
          apiMessage = {
            type: "api",
            fullPrompt: "",
            text: "",
            error: "",
            progressPcnt: 0,
            progressMsg: "",
            thought: "",
          };
        }
        if (incomingMsg.message_type === "full_prompt") {
          // Construct full prompt
          let fullPromptText = "";
          incomingMsg.payload.forEach((obj, index) => {
            fullPromptText += "### " + obj.role + "\n" + obj.content + "\n";

            // Add a newline between objects, but not after the last object
            if (index < fullPromptText.length - 1) {
              fullPromptText += "\n";
            }
          });
          apiMessage.fullPrompt = fullPromptText;
        } else if (incomingMsg.message_type === "thought") {
          apiMessage.thought = apiMessage.thought + "\n" + incomingMsg.payload;
        } else if (incomingMsg.message_type === "final_response") {
          apiMessage.text += incomingMsg.payload;
          console.log(apiMessage.text);
        } else if (incomingMsg.message_type === "error") {
          apiMessage.error += incomingMsg.payload;
        }
        setChatHistory((prevHistory) => [...prevHistory, apiMessage]);
      };

      eventSource.onerror = (obj) => {
        eventSource.close();
        setIsWaiting(false);
      };
    } catch (error) {
      console.error("CHAT: Error sending message:", error);
      setIsWaiting(false);
      let apiMessage = {
        type: "api",
        error: JSON.stringify(error),
        thought: "",
      };
      setChatHistory((prevHistory) => [
        ...prevHistory.slice(0, -1),
        apiMessage,
      ]);
    }
  };

  const cancelRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel("Request cancelled manually.");
    }
  };

  const clearChatHistory = () => {
    setSessionId(getNewSessionId());
    setChatHistory([]);
  };

  let activeChatContext = chatContexts.find(
    (obj) => obj["rid"] === activeChatContextRid
  );

  const showWebSearchToggle =
    activeChatContext?.show_web_search_toggle &&
    activeChatContext?.web_search_rid > 0;
  console.log("showWebSearchToggle", showWebSearchToggle);
  const [useWebSearch, setUseWebSearch] = useState(undefined);

  if (
    useWebSearch === undefined &&
    showWebSearchToggle &&
    activeChatContext?.web_search_toggle_default
  ) {
    setUseWebSearch(true);
  }

  console.log("activeChatContext", activeChatContext);
  return (
    <ActiveChatContextRidContext.Provider value={activeChatContextRid}>
      <SelectedModelRidContext.Provider value={selectedModel}>
        <Box
          m="0px"
          mt="20px"
          mr="20px"
          display="grid"
          gridTemplateColumns={contextSelectorOpen ? "300px 1fr" : "0px 1fr"}
          gap="10px"
          sx={{
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(data:${getImageMimeType(
                activeChatContext?.title_background_image_b64
              )};base64,${activeChatContext?.title_background_image_b64})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundAttachment: "fixed",

              opacity: !isScrolledToTop
                ? 0.0
                : activeChatContext?.title_style?.background_image?.opacity ||
                  1,
              transition: "opacity 0.3s ease-in-out", // Add transition for smooth opacity change
              zIndex: -1,
            },
            padding: "20px", // Adjust padding as needed
          }}
        >
          <Box
            sx={{
              transform: `translateX(${contextSelectorOpen ? "0%" : "-200%"})`,
              transition: "transform 0.3s ease-in-out",
              borderRight: "1px",
              marginTop: "10px",
              backdropFilter: "blur(20px)",
            }}
          >
            {contextSelectorOpen && (
              <ContextSelector
                chatContexts={chatContexts}
                activeChatContextRid={activeChatContextRid}
                setActiveChatContextRid={switchContext}
                fetchChatContexts={fetchChatContexts}
                contextSelectorOpen={contextSelectorOpen}
                setContextSelectorOpen={setContextSelectorOpen}
              />
            )}
          </Box>
          <Box ml="20px">
            <ChatContextCard
              chatContexts={chatContexts}
              activeChatContextRid={activeChatContextRid}
              contextSelectorOpen={contextSelectorOpen}
              setContextSelectorOpen={setContextSelectorOpen}
              setInputValue={setInputValue}
              sendMessage={sendMessageLongPoll}
              useWebSearch={useWebSearch}
              webSearchRid={activeChatContext?.web_search_rid}
            />
            <Box>
              <ChatDisplay
                sessionId={sessionId}
                chatHistory={chatHistory}
                pendingResponse={pendingResponse}
                isWaiting={isWaiting}
                showThoughts={showThoughts}
                showFullPrompt={showFullPrompt}
              />
            </Box>
            <Box
              sx={{
                position: "fixed",
                width: "-webkit-fill-available",
                padding: "10px",
                paddingTop: "0px",
                paddingBottom: "0px",
                margin: "0px",
                marginLeft: "50px",
                marginRight: "40px",

                borderRadius: "14px",
                bottom: "0px",
                zIndex: 1,
                alignmentBaseline: "alphabetic",
                borderWidth: "0px",
                backgroundColor: colorToRgba(
                  theme.palette.background.default,
                  0.75
                ),
                backdropFilter: "blur(6px)",
              }}
            >
              <ChatEntry
                sx={{
                  maxWidth: isCollapsedState
                    ? "calc(100dvw - 150px)"
                    : "calc(100dvw - 310px)",
                }}
                inputValue={inputValue}
                setInputValue={setInputValue}
                isWaiting={isWaiting}
                showThoughts={showThoughts}
                setShowThoughts={setShowThoughts}
                showFullPrompt={showFullPrompt}
                setShowFullPrompt={setShowFullPrompt}
                sendMessage={sendMessageLongPoll}
                cancelRequest={cancelRequest}
                clearChatHistory={clearChatHistory}
                selectedModel={selectedModel}
                handleModelChange={handleModelChange}
                allowWebSearch={showWebSearchToggle}
                useWebSearch={useWebSearch}
                setUseWebSearch={setUseWebSearch}
                webSearchRid={activeChatContext?.web_search_rid}
              />
            </Box>
          </Box>
        </Box>
      </SelectedModelRidContext.Provider>
    </ActiveChatContextRidContext.Provider>
  );
};

export default ChatComponent;
