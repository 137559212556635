import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import {
  Box,
  Container,
  Grid,
  CircularProgress,
  Typography,
  List,
  ListItem,
  IconButton,
  useTheme,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { tokens, collapsedContext, ColorModeContext } from "../../theme";
import {
  blue,
  lightBlue,
  green,
  orange,
  red,
  amber,
  brown,
  purple,
} from "@mui/material/colors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { CollapsedContext } from "../../App.js";
import InsightsIcon from "@mui/icons-material/Insights";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { SelectedModelRidContext } from "./index.jsx";
import { active } from "d3";

// Helper function to determine MIME type of base64 encoded image
export const getImageMimeType = (base64String) => {
  // Detect the file type by checking the magic number
  if (!base64String) {
    return "image/jpeg";
  }
  const signatures = {
    "image/jpeg": "/9j/4",
    "image/png": "iVBORw0KGgo",
    "image/svg+xml": "PHN2Zy",
    "image/svg+xml": "PD94bW",
  };

  for (const [mimeType, signature] of Object.entries(signatures)) {
    if (base64String.startsWith(signature)) {
      return mimeType;
    }
  }

  // Default to JPEG if no signature matches
  return "image/jpeg";
};

function ChatContextCard({
  chatContexts,
  activeChatContextRid,
  contextSelectorOpen,
  setContextSelectorOpen,
  setInputValue,
  sendMessage,
  useWebSearch,
  webSearchRid,
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const colorMode = useContext(ColorModeContext);

  const collapsedContext = useContext(CollapsedContext);
  const selectedLlmChatEndpointRid = useContext(SelectedModelRidContext);

  let activeChatContext = chatContexts.find(
    (obj) => obj["rid"] === activeChatContextRid
  );

  const handleApply = (prompt) => {
    // Logic to paste the prompt into the chat window
    sendMessage(
      `<p>${prompt} </p>`,
      selectedLlmChatEndpointRid,
      useWebSearch,
      webSearchRid
    );
  };

  const iconMap = {
    Visualize: (
      <InsightsIcon sx={{ ml: "20px", mr: "10px", mb: "-5px", fontSize: 35 }} />
    ),
    Analyze: (
      <QueryStatsIcon
        sx={{ ml: "20px", mr: "10px", mb: "-5px", fontSize: 35 }}
      />
    ),
    Optimize: (
      <ArrowCircleUpIcon
        sx={{ ml: "20px", mr: "10px", mb: "-5px", fontSize: 35 }}
      />
    ),
    Troubleshoot: (
      <TroubleshootIcon
        sx={{ ml: "20px", mr: "10px", mb: "-5px", fontSize: 35 }}
      />
    ),
    Consult: (
      <SupportAgentIcon
        sx={{ ml: "20px", mr: "10px", mb: "-5px", fontSize: 35 }}
      />
    ),
    "Interactive Troubleshoot": (
      <QuestionAnswerOutlinedIcon
        sx={{ ml: "20px", mr: "10px", mb: "-5px", fontSize: 35 }}
      />
    ),
  };
  console.log("ActiveChatContext:", activeChatContext);
  console.log(getImageMimeType(activeChatContext?.title_logo_image_b64));
  return (
    <Box
      sx={{
        mb: "1em",
        mt: "1em",
        ml: "1em",
        mr: "4em",
        minHeight: "100dvh",
      }}
    >
      {activeChatContext ? (
        <>
          <Container>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between", // Space items so the first is at the start, last at the end
                alignItems: "center", // Optional: if you want to align items vertically
              }}
            >
              {/* Introductory Title and Description */}
              <IconButton
                sx={{ mt: "0px", ml: "-44px" }}
                onClick={() => setContextSelectorOpen(!contextSelectorOpen)}
              >
                <MenuIcon />
              </IconButton>

              <IconButton
                sx={{ mt: "-30px", mr: "-70px", verticalAlign: "top" }}
                onClick={colorMode.toggleColorMode}
              >
                {theme.palette.mode === "dark" ? (
                  <DarkModeOutlinedIcon />
                ) : (
                  <LightModeOutlinedIcon />
                )}
              </IconButton>
            </Box>
            <Box>
              <Typography
                variant="h3"
                gutterBottom
                style={{ fontWeight: "bold", fontSize: "1.7rem" }}
              >
                <Box display="flex" alignItems="center" gap="10px">
                  <div>
                    {activeChatContext?.title_logo_image_b64 ? (
                      <img
                        src={`data:${getImageMimeType(
                          activeChatContext?.title_logo_image_b64
                        )};base64,${activeChatContext?.title_logo_image_b64}`}
                        alt={""}
                        style={{
                          height: activeChatContext?.title_style?.logo?.height
                            ? activeChatContext?.title_style?.logo?.height
                            : "35px",
                        }}
                      />
                    ) : null}
                  </div>

                  {/* Title */}
                  <Typography
                    variant="h3"
                    gutterBottom
                    sx={{
                      mt: "10px",

                      fontWeight: "bold",
                      fontSize: "1.7rem",
                      ...(activeChatContext?.title_style?.title?.color
                        ? { color: activeChatContext.title_style.title.color }
                        : {}),
                    }}
                  >
                    {activeChatContext.title}
                  </Typography>
                </Box>
              </Typography>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  mt: "2em",
                  ml:
                    activeChatContext.title == "CrystalClear Containers Corp"
                      ? "65px"
                      : "0px",
                  fontSize: activeChatContext?.title_style?.description
                    ?.fontSize
                    ? activeChatContext?.title_style?.description?.fontSize
                    : "1 rem",

                  ...(activeChatContext?.title_style?.title?.color
                    ? { color: activeChatContext.title_style.title.color }
                    : {}),
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: activeChatContext.description,
                  }}
                />
              </Typography>
            </Box>
            {/* Categories Grid */}
            {activeChatContext.examples ? (
              <Grid
                sx={{ mt: "15px" }}
                container
                spacing={3}
                alignItems="stretch"
                justifyContent="flex-start"
              >
                {activeChatContext.examples.map((category, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={activeChatContext.examples.length % 4 === 0 ? 3 : 4}
                    key={index}
                  >
                    <Box
                      sx={{
                        backgroundColor:
                          theme.palette.mode === "light"
                            ? "rgba(0, 0, 0, 0.02)"
                            : "rgba(255, 255, 255, 0.04)",
                        borderRadius: "8px",
                        paddingTop: "20px",
                        paddingBottom: "8px",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        height: "100%",
                        margin: "0px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        gutterBottom
                        sx={{
                          color: [
                            red[900],
                            orange[900],
                            green[800],
                            purple[800],
                            blue[800],
                          ][index],
                          color: "rgba(40,110,190,1)",
                          fontWeight: "600",
                          textAlign: "left",
                          fontSize: "1.2rem",
                        }}
                      >
                        {iconMap[category.title]} {/* Display the icon */}
                        {category.title}
                      </Typography>
                      <List>
                        {category.prompts.map((prompt, idx) => (
                          <ListItem
                            key={idx}
                            alignItems="center"
                            sx={{ marginBottom: "2px" }}
                          >
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                padding: "8px 16px",
                                background: `linear-gradient(to right, ${blue[400]},  ${blue[200]})`,
                                borderRadius: "16px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                overflow: "hidden",
                              }}
                            >
                              <ListItemText
                                primary={prompt}
                                align="left"
                                style={{ color: "#FFF" }}
                              />

                              <IconButton
                                sx={{
                                  position: "absolute",
                                  bottom: "0px",
                                  right: "5px",
                                  color: blue[700],
                                }}
                                onClick={() => handleApply(prompt)}
                                size="small"
                              >
                                <KeyboardArrowDownIcon />
                              </IconButton>
                            </div>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : null}
          </Container>
        </>
      ) : (
        <CircularProgress
          size={33}
          sx={{
            color: colors.greenAccent[400],
            mt: "14px",
            ml: "13px",
            mr: "23px",
          }}
        />
      )}
    </Box>
  );
}

export default ChatContextCard;
