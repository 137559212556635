import React, { useState, useEffect, useContext } from 'react';
import { Typography, Box, useTheme, Grid, Checkbox, Button, IconButton, SvgIcon} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ColorModeContext, useMode, tokens} from '../theme';
import { useNavigate } from 'react-router-dom';
import { UserContext, AccessJwtContext } from "../Auth.js"
import createAxiosApi from "../lib/api.jsx"


const theme = createTheme({
    palette: {
      primary: {
        main: '#3da58a', // replace hexColor with your color of choice
      },
      secondary: {
        main: '#3da58a', // replace hexColor with your color of choice
      },
    },
  });
  
export default function GetUser({setUser, signout}) {
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate()
    //  Get current user

    const fetchUser = () => {
        const api = createAxiosApi(setUser, signout)

        api.get("/auth/me")
            .then( (res) => {
                setUser( res.data );
            })
            .catch( (error) => {
                signout()
            }
        )
    }
    
    useEffect( () => {fetchUser() }, [])
    ;

    return (
        <Box></Box>
    );
    }