import React, { useState } from 'react';
import { useTheme, Box } from "@mui/material";
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { tokens } from "../theme";

function FileUploadButton({uploadHandler}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleFileInput = (e) => {
        uploadHandler(e.target.files[0]);
    }

    return (
        <Box>
            <input
                accept="application/pdf"
                style={{ display: 'none' }}
                id="contained-button-file"
                multiple
                type="file"

                onChange={handleFileInput}
            />
            <label htmlFor="contained-button-file">
                <Button 
                    variant="contained" 
                    sx={{"&:hover, &.GridButton.Mui-selected": 
                        {backgroundColor: colors.greenAccent[500]}, 
                        backgroundColor: colors.greenAccent[400], 
                        color: colors.primary[400] }} 
                    component="span" startIcon={<CloudUploadIcon />}>
                    Upload Document
                </Button>
            </label>
        </Box>
    );
}

export default FileUploadButton;