import React from "react";
import { Autocomplete, TextField } from "@mui/material";

/*  
Note: 

This component internally processes tags in the format
[{'category': 'Category1', 'value': 'Value1'},
{'category': 'Category1', 'value': 'Value2'},
{'category': 'Category2', 'value': 'Value3'},] 

However the parent stores tags in the format
{'Category1': ['Value1', 'Value2'],
 'Category2': ['Value3']}

So the functions transformTagList() and reverseTransform are used to convert the formats
back and forth
*/

function transformTagList(tagList) {
  const transformedTags = [];

  for (const category in tagList) {
    const values = tagList[category];

    for (const value of values) {
      transformedTags.push({ category, value });
    }
  }

  return transformedTags;
}

function reverseTransform(valuesList) {
  const originalTags = {};

  for (const tag of valuesList) {
    const { category, value } = tag;
    if (!originalTags[category]) {
      originalTags[category] = [];
    }
    originalTags[category].push(value);
  }

  return originalTags;
}

export default function TagSelector({ allTags, selectedTags, onUpdateTags }) {
  const transformedSelectedTags = transformTagList(selectedTags);

  const setSelectedTags = (newValue) => {
    let newSelectedTags = reverseTransform(newValue);
    onUpdateTags(newSelectedTags);
  };

  const allTagsList = transformTagList(allTags);

  // Sort the tag list and remove items already selected
  const sortedAndFilteredTags = allTagsList
    .sort((a, b) => {
      if (a.category === b.category) {
        return a.value.localeCompare(b.value);
      }
      return a.category.localeCompare(b.category);
    })
    .filter(
      (tag) =>
        !transformedSelectedTags.some(
          (selectedTag) =>
            selectedTag.value === tag.value &&
            selectedTag.category === tag.category
        )
    );

  return (
    <Autocomplete
      multiple
      sx={{ minWidth: "200px" }}
      id="grouped-autocomplete"
      size="small"
      options={sortedAndFilteredTags}
      groupBy={(option) => option.category}
      getOptionLabel={(option) => option.value}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      value={transformedSelectedTags}
      onChange={(event, newValue) => {
        setSelectedTags(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Filter by Tags" placeholder="" />
      )}
    />
  );
}
