import React, { useEffect } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  IconButton,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import HelpIcon from "@mui/icons-material/Help";
import ParameterPill from "../../subcomponents/ParameterPill";
import InputOutlinedIcon from "@mui/icons-material/InputOutlined";

const explanations = {
  blisterProd: "Blister Tons per Hour = (Feed Rate) * (Blister Copper Conv %)",
  afFillTime:
    "Fill time = (Anode Production Charge Tons) / (Blister Tons per Hour)",
  numberOfTaps:
    "Number of Taps = (Anode Production Charge Tons) / (Blister Tons per Tap)",
  timeBetweenTaps:
    "Time Between Taps Hours = (Fill Time - (Number of Taps * Tapping Time)) / (Number of Taps)",
  castTimePerFurnace:
    "Cast Time = (Anode Production Charge Tons) / (Casting Rate)",
  anodeCyclePerFurnace:
    "Anode Cycle Time per Furace = (Cast Time) + (Refining Time) + (Fill Time).",
  cyclesPerDay: "Number of complete cycles performed in one day.",
};

function FurnaceParameterEntry({ data, onDataUpdate }) {
  function mround(value, multiple) {
    return Math.round(value / multiple) * multiple;
  }

  const updateCalculatedFields = (data) => {
    data.refiningTime = parseFloat(data.refiningTime);
    data.blisterProd = (data.fcFeedRate * data.blisterToCopperConv).toFixed(1);

    if (data.availableFurnaces === "East Only") {
      data.firstFurnace = "eastState";
      data.secondFurnace = "westState";
    } else if (data.availableFurnaces === "West Only") {
      data.firstFurnace = "westState";
      data.secondFurnace = "eastState";
    } else if (data.availableFurnaces === "East and West") {
      data.firstFurnace = "westState";
      data.secondFurnace = "eastState";
    }

    data.afFillTime = mround(
      data.anodeProductionCharge / data.blisterProd,
      0.5
    );
    data.numberOfTaps = Math.ceil(
      data.anodeProductionCharge / data.blisterPerTap
    );
    data.timeBetweenTaps = Math.round(
      (data.afFillTime - data.numberOfTaps * data.tappingTime) /
        data.numberOfTaps
    );
    data.castTimePerFurnace = mround(
      data.anodeProductionCharge / data.avgCastingRate,
      0.5
    );
    data.anodeCyclePerFurnace =
      data.castTimePerFurnace + data.refiningTime + data.afFillTime;
    data.cyclesPerDay = 1;
  };

  updateCalculatedFields(data);

  const handleChange = (prop) => (event) => {
    const newValue = event.target.value;
    const newData = { ...data, [prop]: newValue };
    updateCalculatedFields(newData);
    onDataUpdate(newData); // Callback to parent component
  };

  return (
    <Box sx={{ ml: "1em", mt: "1em" }}>
      <ParameterPill
        isLoading={false}
        defaultCaption={"Assumptions"}
        iconSmall={
          <InputOutlinedIcon
            sx={{
              mr: "10px",
              fontSize: "1.3rem",
            }}
          />
        }
        iconExpanded={
          <InputOutlinedIcon
            sx={{
              mr: "10px",
              fontSize: "1.8rem",
            }}
          />
        }
        pillColor="rgba(210,210,210,10)"
        sx={{ mt: "10px" }}
      >
        <Grid container spacing={1}>
          {Object.entries({
            matGrade: "Matt Grade (%)",
            blisterCopper: "Blister Copper (%)",
            recovery: "Recovery (%)",
            fcOnlineTime: "FC Online Time (hours)",
            anodeProductionCharge: "Anode Production/Charge (tons)",
            blisterPerTap: "Blister per Tap (tons)",
            shaftMeltingRate: "Shaft Melting Rate (tons/hour)",
            blisterToCopperConv: "Blister To Copper Conv (%)",
            //tapsToFillFurnace: "Taps to Fill Furnace",
          }).map(([key, label]) => (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <TextField
                label={label}
                type="number"
                inputProps={{
                  step:
                    key.includes("Time") || key.includes("Rate") ? "0.5" : "1",
                }}
                value={data[key]}
                onChange={handleChange(key)}
                margin="dense"
                fullWidth
                size="small"
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Tapping Time (Hours)"
              type="number"
              inputProps={{ step: "0.5" }}
              value={data.tappingTime}
              onChange={handleChange("tappingTime")}
              margin="dense"
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
      </ParameterPill>

      <Box>
        <Grid sx={{ pr: "3em", mt: "0.5em" }} container spacing={1}>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <FormControl fullWidth margin="dense" size="small">
              <InputLabel>Available Furnaces</InputLabel>
              <Select
                value={data.availableFurnaces}
                onChange={handleChange("availableFurnaces")}
                label="Available Furnaces"
              >
                <MenuItem value="East Only">East Only</MenuItem>
                <MenuItem value="West Only">West Only</MenuItem>
                <MenuItem value="East and West">East and West</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <TextField
              label="FC Feed Rate (tons/hour)"
              type="number"
              inputProps={{ step: 5 }}
              value={data.fcFeedRate}
              onChange={handleChange("fcFeedRate")}
              margin="dense"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <TextField
              label="Refining Time (hours)"
              type="number"
              inputProps={{ step: "0.5" }}
              value={data.refiningTime}
              onChange={handleChange("refiningTime")}
              margin="dense"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <TextField
              label="Casting Rate (tons/hour)"
              type="number"
              inputProps={{ step: "5" }}
              value={data.avgCastingRate}
              onChange={handleChange("avgCastingRate")}
              margin="dense"
              fullWidth
              size="small"
            />
          </Grid>
          {!(data.availableFurnaces === "East and West") ? (
            <>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <FormControl fullWidth margin="dense" size="small">
                  <InputLabel>Drain</InputLabel>
                  <Select
                    value={data.drain}
                    onChange={handleChange("drain")}
                    label="Drain"
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <TextField
                  label="Drain Time (Hours)"
                  type="number"
                  inputProps={{ step: "0.5" }}
                  value={data.drainTime}
                  onChange={handleChange("drainTime")}
                  margin="dense"
                  fullWidth
                  size="small"
                />
              </Grid>{" "}
            </>
          ) : null}
        </Grid>
      </Box>

      <Box sx={{ pl: "1em", pr: "3em", mt: "1em" }}>
        <Grid container spacing={1}>
          {Object.entries({
            blisterProd: "Blister Prod (tons/hour)",
            afFillTime: "AF Fill Time (hours)",
            numberOfTaps: "Number of Taps",
            castTimePerFurnace: "Cast Time Per Furnace (hours)",
            anodeCyclePerFurnace: "Anode Cycle Per Furnace (hours)",
          }).map(([key, label]) => (
            <Grid item xs={12} sm={6} lg={4} key={key}>
              <Typography variant="body1">
                <Tooltip
                  title={explanations[key] || "No explanation available"}
                >
                  <IconButton size="small" sx={{ marginRight: "0.5em" }}>
                    <HelpIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                {label}: <strong>{data[key]}</strong>
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default FurnaceParameterEntry;
