import axios from "axios";

export function clearAccessToken() {
  localStorage.removeItem("accessJwt");
  sessionStorage.removeItem("accessJwt");
}

export function getAccessToken() {
  let token = localStorage.getItem("accessJwt");
  if (!token) {
    token = sessionStorage.getItem("accessJwt");
  }
  return token;
}

export function clearRefreshToken() {
  localStorage.removeItem("refreshJwt");
  sessionStorage.removeItem("refreshJwt");
}

export function getRefreshToken() {
  let token = localStorage.getItem("refreshJwt");
  if (!token) {
    token = sessionStorage.getItem("refreshJwt");
  }
  return token;
}

function createAxiosApi(signout) {
  async function refreshTheToken(refreshToken, signout) {
    try {
      const response = await api.post("/auth/token/refresh", {
        refresh_token: refreshToken,
      });

      const { access_token } = response.data;
      if (localStorage.getItem("accessJwt")) {
        localStorage.setItem("accessJwt", access_token);
      } else {
        sessionStorage.setItem("accessJwt", access_token);
      }

      return access_token;
    } catch (error) {
      console.log("Failed to refresh token", error);
      signout();
      throw error;
    }
  }

  let BASE_URL = `${window.location.protocol}//${window.location.hostname}:8000`;

  // create an axios instance
  const api = axios.create({
    baseURL: BASE_URL,
    timeout: 600000, // 10 minutes
  });

  api.interceptors.request.use(
    async (config) => {
      const token = getAccessToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      // Any status code that lies within the range of 2xx cause this function to trigger
      // Do something with the response data
      return response;
    },
    async (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const refreshToken = getRefreshToken();
          if (refreshToken) {
            const newAccessToken = await refreshTheToken(refreshToken, signout);
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return api(originalRequest);
          } else {
            signout();
          }
        }
      } else {
        // Handle error with response
        console.log("Error without resopnse detected", error.message);
      }
      return Promise.reject(error);
    }
  );

  return api;
}

export default createAxiosApi;
