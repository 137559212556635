import React, { Fragment, useMemo, useState, useRef, useEffect } from "react";
import RangeSlider from "./RangeSlider";
import { ColorModeContext, tokens } from "../../theme";
import {
  Box,
  Tab,
  useTheme,
  Typography,
  Button,
  TextField,
  TextareaAutosize,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  LinearProgress,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MaterialReactTable } from "material-react-table";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import GetAppIcon from "@mui/icons-material/GetApp";
import RestoreIcon from "@mui/icons-material/Restore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { colorToRgba } from "../../theme";

import { useImmer } from "use-immer";
import { exportToCSV } from "../../lib/export";
import { styled } from "@mui/material/styles";

function stringToFloat(str, def) {
  // Attempt to convert the string to an integer
  const number = parseFloat(str, 10);

  // Check if the result is NaN (which means the string didn't contain a valid number)
  // If it is NaN, default to 0, otherwise use the parsed number
  return isNaN(number) ? def : number;
}

const StyledRightLabelBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: theme.spacing(1.5),
  height: "50px",
  minWidth: "150px",
  width: "25%",
  position: "relative",
  "&:before, &:after": {
    content: '""',
    position: "absolute",
    left: 0, // Aligning to the left
    width: "30px", // Fixed border length
    height: "1px",
    backgroundColor: "rgba(127,127,127,0.4)",
  },
  "&:before": {
    top: 0,
  },
  "&:after": {
    bottom: 0,
  },
}));

const TargetVisualization = ({
  rightLabel,
  bottomLabel,
  inRangePercentage,
  goodPercentage,
  badPercentage,
  alpha,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // Ensure the percentages sum up to 100
  const outOfRangePercentage = 100 - inRangePercentage;

  const goodColor = `rgba(72,192,178, ${alpha})`;
  const badColor = `rgba(192,92,142, ${alpha})`;
  const noneColor = `rgba(107,107,137,${alpha / 2})`;
  const borderColor = "rgba(127,127,127,0.1)";

  return (
    <Box sx={{ display: "flex", alignItems: "top" }}>
      {/* Container for Visualization Box and its Bottom Label */}
      <Box
        sx={{
          width: "70%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Visualization Box */}
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: 50,

            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
            border: "2px solid rgba(127,127,127,${alpha})",
          }}
        >
          {/* Top region (Out of Range) */}
          <Box
            sx={{
              height: `${outOfRangePercentage}%`,
              backgroundColor: noneColor,
              position: "absolute",
              top: 0,
              left: 0,
              borderBottom: "1px solid",
              borderBottomColor:
                theme.palette.mode === "dark"
                  ? "rgba(0,0,0,1)"
                  : "rgba(240,240,240,1)",
              right: 0,
            }}
          />
          {/* Bottom region (In Range) */}
          {/* Good Percentage */}
          <Box
            sx={{
              height: `${inRangePercentage}%`,
              width: `${goodPercentage}%`,
              backgroundColor: goodColor,
              position: "absolute",
              borderRight: "1px solid",
              borderRightColor:
                theme.palette.mode === "dark"
                  ? "rgba(0,0,0,1)"
                  : "rgba(240,240,240,1)",
              bottom: 0,
              left: 0,
            }}
          />
          {/* Bad Percentage */}
          <Box
            sx={{
              height: `${inRangePercentage}%`,
              width: `${badPercentage}%`,
              backgroundColor: badColor,
              position: "absolute",
              bottom: 0,
              right: 0,
            }}
          />
        </Box>

        {/* Bottom label */}
        <Box
          sx={{
            textAlign: "center",
            mt: 1,
            borderLeft: "1px solid rgba(127,127,127,0.5)",
            borderRight: "1px solid rgba(127,127,127,0.5)",
            width: "100%",
          }}
        >
          <Typography sx={{ whiteSpace: "pre-line" }}>{bottomLabel}</Typography>
        </Box>
      </Box>

      {/* Right label */}

      <StyledRightLabelBox>
        <Typography sx={{ whiteSpace: "pre-line" }}>{rightLabel}</Typography>
      </StyledRightLabelBox>
    </Box>
  );
};

const PercentageBar = ({ good, bad, none }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // Calculate the total to get proportions
  let goodInt = stringToFloat(good, 0);
  let badInt = stringToFloat(bad, 0);
  let noneInt = stringToFloat(none, 100);

  const total = goodInt + badInt + noneInt;

  // Avoid division by zero
  const safeTotal = total === 0 ? 1 : total;

  // Calculate percentage for each category
  const goodPercentage = (goodInt / safeTotal) * 100;
  const badPercentage = (badInt / safeTotal) * 100;
  const nonePercentage = (noneInt / safeTotal) * 100;

  // Define colors for each category
  const goodColor = colors.greenAccent[500]; // green
  const badColor = colors.redAccent[600]; // red
  const noneColor = "#e0e0e0"; // grey

  return (
    <Box
      display="flex"
      alignItems="center"
      width={150} // fixed width of the entire bar
      height={24}
      borderRadius={2.5}
      overflow="hidden"
      sx={{
        mt: "0.5em",
        mb: "0.5em",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: colors.grey[300],
      }}
    >
      <Box
        width={`${goodPercentage}%`}
        sx={{ backgroundColor: goodColor }}
        height="100%"
      />
      <Box width={`${badPercentage}%`} bgcolor={badColor} height="100%" />
      <Box width={`${nonePercentage}%`} bgcolor={noneColor} height="100%" />
    </Box>
  );
};

export default function OptimalRange({ config, data }) {
  const [tabValue, setTabValue] = React.useState("0");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  /* 
    Below is a diagram of the data structures produced and used by the Optimal Range Component
    and the associated WhatIf feature.

    The Optimal (Recommended) Range calculates a recommended range (min, max) for each feature
    based on the observed set of records where target value criteria is satisfied without user input. 

    The Custom (WhatIf) range feature allows the user to specify a custom min, max for each feature
    and the component will calculate the number of records in this subset that satisfy the target value
    criteria.

    The 6 lists built for this process, as well as how data is transform between each list is shown below



                             Unfiltered                                 Filtered 
                             by Target Criteria                          by Target criteria
                        -------------------------               --------------------------------
       Unfiltered       |                       |    Step 1     |                              |
       by Feature       |   data                |     -->       |  filteredData                |
       Range            |                       |               |                              |
                        -------------------------               --------------------------------

                          |    Step 3                                 |   Step 2
                          |    dataRanged =                           |   recommendedRange = 
                         \ /      filterDataByFeatures(              \ /      calculateFeatureRange( filteredData )
                                      data, recommendedRange)             filteredRangedData = 
                                                                              filterDataByFeatures( filteredData, recommendedRange )

                        -------------------------               ---------------------------------
       Filtered  by     |                       |               |                               |
       Recommended      |   dataRanged          |               |  filteredRangedData           |
       Range            |                       |               |                               |
                        -------------------------               ---------------------------------
                        (From Data, not dataRanged)             (from filteredData, not filteredRangedData)
                          |    Step 3                                 |   Step 2
                          |    dataCustomRanged =                     |   const [customRage, setCustomRange] = useImmer(recommendedRange) 
                         \ /      filterDataByFeatures(              \ /      
                                      data, customRange)                  filteredCustomRangedData = 
                                                                              filterDataByFeatures( filteredData, customRange )
                        
                        -------------------------               ---------------------------------
       Filtered by      |                       |               |                               |
       Custom           |  dataCustomRanged     |               |  filteredCustomRangedData     |
       Range            |                       |               |                               |
                        -------------------------               ---------------------------------


        Output statistics:
            Recommended Range:
                Recommended range performance %: filteredRangedData.length / dataRanged.length
                Baseline target performance %: filteredData.length / data.length

            WhatIf:
                Custom range performance %: filteredCustomRangedData.length / dataCustomRanged.length
                Recommended range performance %: filteredRangedData.length / dataRanged.length
                Baseline target performance %: filteredData.length / data.length
                    

    */

  //  If no target value is provided, set a default by sampling the source data
  function getPercentile(data, targetCol, targetLTorGT, percentile) {
    // Filter out any objects missing the target column, then sort by target column
    const sortedData = data
      .filter((item) => item[targetCol] !== undefined)
      .map((item) => item[targetCol])
      .sort((a, b) => a - b);

    // Calculate index for 60th percentile
    if (targetLTorGT == "GT") {
      var mult = percentile;
    } else {
      var mult = 1.0 - percentile;
    }
    const index = Math.ceil(sortedData.length * mult) - 1;
    return sortedData[index];
  }

  function filterDataByTarget(data, targetColumn, targetValue, targetGTorLT) {
    //  filter array `data` by rows where target_column is gt or lt target_val
    //  targetGTorLT must be either GT or LT
    //  Returns: filtered array
    if (targetGTorLT === "LT") {
      return data.filter((row) => row[targetColumn] < targetValue);
    } else if (targetGTorLT === "GT") {
      return data.filter((row) => row[targetColumn] > targetValue);
    } else {
      console.error('Invalid value for target_gt_or_lt. Use "GT" or "LT".');
      return [];
    }
  }

  function filterDataByFeatures(data, featureRanges) {
    //  filter array `data` where all feature columns fall within featureRanges
    //  Returns: filtered array
    return data.filter((item) => {
      return Object.keys(featureRanges).every((key) => {
        return (
          item[key] >= featureRanges[key].min &&
          item[key] <= featureRanges[key].max
        );
      });
    });
  }

  function calculateFeatureRange(data, featureList) {
    /*  Calculate the min and max values found in data for each feature. 
            Returns single object structured like the following
            { 
                feature_1: {
                    min: 700,
                    max: 600},
                feature_2: {
                    min: 700,
                    max: 600},
                },
                :
            }
        */
    let featureRange = {};

    featureList.forEach((feature) => {
      let min = Infinity;
      let max = -Infinity;

      data.forEach((item) => {
        if (item[feature] < min) min = item[feature];
        if (item[feature] > max) max = item[feature];
      });

      min = parseFloat(min.toPrecision(6));
      max = parseFloat(max.toPrecision(6));
      featureRange[feature] = { min, max };
    });

    return featureRange;
  }

  //  Crteates the starting value for customRange, based on the recommendedRange and absolute Range
  function createUiRangeList(recommendedRanges, absoluteRanges) {
    let combinedRanges = [];

    for (let feature in recommendedRanges) {
      combinedRanges.push({
        featureName: feature,
        enabled: true,
        featureImportance: config.featureImportance[feature],
        rangeMin: absoluteRanges[feature].min,
        rangeMax: absoluteRanges[feature].max,
        min: recommendedRanges[feature].min,
        max: recommendedRanges[feature].max,
      });
    }

    return combinedRanges;
  }

  function createFilterRangeObject(uiRangeList) {
    let filterRangeObject = {};

    uiRangeList.forEach((range) => {
      if (range.enabled) {
        filterRangeObject[range.featureName] = {
          min: range.min,
          max: range.max,
        };
      }
    });

    return filterRangeObject;
  }

  let defaultTargetValue = config.targetValue;
  if (!defaultTargetValue) {
    defaultTargetValue = getPercentile(
      data,
      config.targetColumn,
      config.targetLTorGT,
      0.6
    );
  }

  const [targetLTorGT, setTargetLTorGT] = useState(config.targetLTorGT);
  const [targetValue, setTargetValue] = useState(defaultTargetValue);

  const filteredData = useMemo(
    () =>
      filterDataByTarget(data, config.targetColumn, targetValue, targetLTorGT),
    [data, config.targetColumn, targetValue, targetLTorGT]
  );

  const recommendedRange = useMemo(
    () => calculateFeatureRange(filteredData, config.featureColumns),
    [filteredData, config.featureColumns]
  );

  const absoluteRange = useMemo(
    () => calculateFeatureRange(data, config.featureColumns),
    [data, config.featureColumns]
  );

  const filteredRangedData = useMemo(
    () => filterDataByFeatures(filteredData, recommendedRange),
    [filteredData, recommendedRange]
  );

  const dataRanged = useMemo(
    () => filterDataByFeatures(data, recommendedRange),
    [data, recommendedRange]
  );

  const [uiRangeList, setUiRangeList] = useImmer(
    createUiRangeList(recommendedRange, absoluteRange)
  );

  useEffect(() => {
    const newUiRangeList = createUiRangeList(recommendedRange, absoluteRange);
    setUiRangeList((draft) => {
      return newUiRangeList;
    });
  }, [recommendedRange, absoluteRange, setUiRangeList]);
  const customFilterRangeObj = createFilterRangeObject(uiRangeList);

  const filteredCustomRangedData = useMemo(
    () => filterDataByFeatures(filteredData, customFilterRangeObj),
    [filteredData, uiRangeList]
  );

  const dataCustomRanged = useMemo(
    () => filterDataByFeatures(data, customFilterRangeObj),
    [data, uiRangeList]
  );

  const handleTargetLTorGTChange = (event) => {
    setTargetLTorGT(event.target.value);
  };

  const handleTargetValueChange = (event) => {
    setTargetValue(event.target.value);
  };

  function handleCustomRangeChange(feature, enabled, min, max) {
    setUiRangeList((draft) => {
      // find the item with featureName matching the provided feature
      const item = draft.find((range) => range.featureName === feature);

      if (item) {
        // if the feature is found in the list
        // update the enabled, min, and max properties
        item.enabled = enabled;
        item.min = min;
        item.max = max;
      }
    });
  }

  // Output data to be presented as table
  const baselinePcntGood = data.length
    ? parseFloat(((100 * filteredData.length) / data.length).toFixed(1))
    : 0;
  const baselineRow = {
    description: "Baseline target performance",
    percent: baselinePcntGood,
    totalPeriod: data.length,
    pcntPeriodsInRange: 100,
    pcntBad: 100 - baselinePcntGood,
    inRangeLabel: `${data.length} total ${config.timeUnits} of history analyzed`,
    goodLabel: `${baselinePcntGood}% target values were met`,
  };

  const recommendedPcntGood = dataRanged.length
    ? parseFloat(
        ((100 * filteredRangedData.length) / dataRanged.length).toFixed(1)
      )
    : 0;
  const recommendedRangeRow = {
    description: "Recommended range target performance",
    percent: recommendedPcntGood,
    totalPeriod: `${dataRanged.length} of ${data.length}`,
    pcntPeriodsInRange:
      (dataRanged.length / (data.length > 0 ? data.length : 1)) * 100,
    pcntBad: 100 - recommendedPcntGood,
    inRangeLabel: `${dataRanged.length} of ${data.length} ${config.timeUnits} within operating range`,
    goodLabel: `${recommendedPcntGood}% target values met within operating range`,
  };

  const customPcntGood = dataCustomRanged.length
    ? parseFloat(
        (
          (100 * filteredCustomRangedData.length) /
          dataCustomRanged.length
        ).toFixed(1)
      )
    : 0;

  const customRangeRow = {
    description: "Custom range target performance",
    percent: customPcntGood,
    totalPeriod: `${dataCustomRanged.length} of ${data.length}`,
    pcntPeriodsInRange:
      (dataCustomRanged.length / (data.length > 0 ? data.length : 1)) * 100,
    pcntBad: 100 - customPcntGood,
    inRangeLabel: `${dataCustomRanged.length} of ${data.length} ${config.timeUnits} within operating range`,
    goodLabel: `${customPcntGood}% target values met within operating range`,
  };

  const recommendedRangeResultsTableData = [recommendedRangeRow, baselineRow];
  const customRangeResultsTableData = [
    customRangeRow,
    recommendedRangeRow,
    baselineRow,
  ];
  const resultsTableColumns = [
    {
      accessorKey: "description", //access nested data with dot notation
      header: "",
      size: 100,
    },
    {
      accessorKey: "percent", //access nested data with dot notation
      header: "Target performance",
      size: 70,
      Header: ({ column }) => (
        <div>
          {column.columnDef.header}
          <Tooltip
            title={
              <div style={{ fontSize: "1.3em" }}>
                Percent of time during which the target performance was met
              </div>
            }
            placement="top"
          >
            <HelpOutlineOutlinedIcon
              sx={{
                fontSize: "1.5em",
                paddingLeft: "4px",
                verticalAlign: "middle",
              }}
            />
          </Tooltip>
        </div>
      ), //arrow function

      Cell: ({ renderedCellValue, row }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              width: "3em",
              overflow: "hidden", // This prevents text from spilling out
              whiteSpace: "nowrap", // This prevents text from breaking across lines
              textOverflow: "ellipsis",
            }}
          >
            {renderedCellValue}%
          </Box>
          <PercentageBar
            good={row.original.percent}
            bad={100 - row.original.percent}
            none={0}
          ></PercentageBar>
          {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
        </Box>
      ),
    },
    {
      accessorKey: "totalPeriod", //access nested data with dot notation
      header: `Total ${config.timeUnits}`,
      size: 50,
      Header: ({ column }) => (
        <div>
          {column.columnDef.header}
          <Tooltip
            title={
              <div style={{ fontSize: "1.3em" }}>
                {`Number of ${config.timeUnits} during which the process ran within specified range parameters`}
              </div>
            }
            placement="top"
          >
            <HelpOutlineOutlinedIcon
              sx={{
                fontSize: "1.5em",
                paddingLeft: "4px",
                verticalAlign: "middle",
              }}
            />
          </Tooltip>
        </div>
      ), //arrow function
    },
  ];

  //  Table data for Recommended Ranges features table
  const recommendationFeaturesTableData = Object.keys(recommendedRange).map(
    (feature) => {
      return {
        feature: feature,
        min: recommendedRange[feature].min,
        max: recommendedRange[feature].max,
        order: recommendedRange[feature].order,
      };
    }
  );

  //  Sort features by order key, if provided
  recommendationFeaturesTableData.sort((a, b) => {
    if (a.order === undefined) {
      return 1;
    }
    if (b.order === undefined) {
      return -1;
    }
    return a.order - b.order;
  });

  const recommendationFeaturesTableColumns = [
    {
      accessorKey: "feature", //access nested data with dot notation
      header: "Feature",
      size: 100,
    },
    {
      accessorKey: "min", //access nested data with dot notation
      header: "Min Value",
      size: 70,
    },
    {
      accessorKey: "max", //access nested data with dot notation
      header: "Max Value",
      size: 50,
    },
  ];

  // Button event handlers
  const handleExport = () => {
    const obj = uiRangeList
      .filter((value) => value.enabled)
      .map((value) => ({
        Feature_Name: value.featureName,
        Feature_Importance: value.featureImportance,
        Min: value.min,
        Max: value.max,
      }));
    exportToCSV(obj, "optimal_ranges");
  };

  const handleReset = () => {
    // Revert range slider to initial values
    setUiRangeList(createUiRangeList(recommendedRange, absoluteRange));
  };

  const handleApplyToAPC = () => {
    setSnackbarOpen(true); // Open the Snackbar
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        mt: "30px",
        ml: "30px",
        mr: "40px",
        mb: "30px",
      }}
    >
      <Box
        sx={{
          borderBottom: "solid",
          pt: "10px",
          pb: "20px",
          mb: "20px",
          display: "flex",
          justifyContent: "center",
          gap: 2,
          mt: 2,
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          sx={{
            fontWeight: "bold",
            mt: "1px",
            mr: "10px",
            color:
              theme.palette.mode === "dark"
                ? theme.palette.grey[400]
                : theme.palette.grey[600],
          }}
        >
          Optimal Range Analysis
        </Typography>
      </Box>
      <Typography
        component="h1"
        variant="h5"
        sx={{
          fontWeight: "bold",
          mt: "1px",
          mr: "10px",
          color:
            theme.palette.mode === "dark"
              ? "rgba(240,240,240,1)" //theme.palette.grey[400]
              : "rgba(15,15,15,1)", //theme.palette.grey[600],
        }}
      >
        <span>{`Target value: ${config.targetColumn}`} </span>
      </Typography>

      <Box
        sx={{
          mt: "10px",
          ml: "0px",
          padding: "10px",
          pr: "10px",
          pt: "5px",
          backgroundColor: "rgba(127,127,127,0.0)",
        }}
      >
        <Box sx={{ mt: "20px", display: "flex", flexDirection: "row" }}>
          <FormControl sx={{ ml: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Direction</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={targetLTorGT}
              label="Less than"
              onChange={handleTargetLTorGTChange}
            >
              <MenuItem value={"LT"}>Less than</MenuItem>
              <MenuItem value={"GT"}>Greater than</MenuItem>
            </Select>
          </FormControl>
          <TextField
            type="number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            sx={{ ml: 1, maxWidth: 120 }}
            size="small"
            value={targetValue}
            onChange={handleTargetValueChange}
          />
        </Box>
      </Box>
      <Typography
        component="h1"
        variant="h5"
        sx={{
          fontWeight: "bold",
          mt: "30px",
          color:
            theme.palette.mode === "dark"
              ? "rgba(240,240,240,1)" //theme.palette.grey[400]
              : "rgba(15,15,15,1)", //theme.palette.grey[600],
        }}
      >
        Operating Range{" "}
      </Typography>

      <Box
        sx={{
          mt: "10px",
          ml: "0px",
          padding: "10px",
          pr: "10px",
          pt: "5px",
          backgroundColor: "rgba(127,127,127,0.05)",
        }}
      >
        <Typography sx={{ ml: "10px", mt: "10px", mb: "10px" }}>
          Adjust the minimum and maximum operating range for each process
          variable:
        </Typography>
        {uiRangeList.map((item, index) => (
          <Box
            sx={{
              backgroundColor: "rgba(127,127,127,0.00)",
              pr: "10px",
              mt: "3px",
              pt: "3px",
              mb: "3px",
              pb: "3px",
              pr: "20px",
            }}
          >
            <RangeSlider
              featureName={item.featureName}
              key={index}
              enabled={item.enabled}
              featureImportance={item.featureImportance}
              rangeMin={item.rangeMin}
              rangeMax={item.rangeMax}
              min={item.min}
              max={item.max}
              handleCustomRangeChange={handleCustomRangeChange}
            />
          </Box>
        ))}
      </Box>
      <Typography
        component="h1"
        variant="h5"
        sx={{
          fontWeight: "bold",
          mt: "30px",
          color:
            theme.palette.mode === "dark"
              ? "rgba(240,240,240,1)" //theme.palette.grey[400]
              : "rgba(15,15,15,1)", //theme.palette.grey[600],
        }}
      >
        Historical Target Performance{" "}
      </Typography>
      <Box
        sx={{
          mt: "10px",
          padding: "10px",
          backgroundColor: "rgba(127,127,127,0.0)",
        }}
      >
        <Box
          sx={{
            ml: "10px",
            padding: "0px",
            backgroundColor: "rgba(127,127,127,0.0)",
          }}
        >
          <Typography
            component="h1"
            variant="h6"
            sx={{
              color:
                theme.palette.mode === "dark"
                  ? "rgba(240,240,240,1)" //theme.palette.grey[400]
                  : "rgba(15,15,15,1)", //theme.palette.grey[600],
              fontWeight: "bold",
              ml: "0px",
              mt: "10px",
              mb: "10px",
            }}
          >
            Selected operating range applied:
          </Typography>
          <TargetVisualization
            rightLabel={customRangeRow.inRangeLabel}
            bottomLabel={customRangeRow.goodLabel}
            inRangePercentage={customRangeRow.pcntPeriodsInRange}
            goodPercentage={customRangeRow.percent}
            badPercentage={customRangeRow.pcntBad}
            alpha={"1.0"}
          ></TargetVisualization>
        </Box>

        <Box
          sx={{
            ml: "10px",
            mt: "30px",
            padding: "0px",
            backgroundColor: "rgba(127,127,127,0.0)",
          }}
        >
          {" "}
          <Typography
            component="h1"
            variant="h6"
            sx={{
              color:
                theme.palette.mode === "dark"
                  ? "rgba(240,240,240,0.5)" //theme.palette.grey[400]
                  : "rgba(15,15,15,0.5)", //theme.palette.grey[600],
              fontWeight: "bold",
              ml: "0px",
              mt: "10px",
              mb: "5px",
            }}
          >
            Initial operating range recommendation applied:
          </Typography>
          <TargetVisualization
            rightLabel={recommendedRangeRow.inRangeLabel}
            bottomLabel={recommendedRangeRow.goodLabel}
            inRangePercentage={recommendedRangeRow.pcntPeriodsInRange}
            goodPercentage={recommendedRangeRow.percent}
            badPercentage={recommendedRangeRow.pcntBad}
            alpha={"0.3"}
          ></TargetVisualization>
        </Box>

        <Box
          sx={{
            ml: "10px",
            mt: "30px",
            padding: "0px",
            backgroundColor: "rgba(127,127,127,0.0)",
          }}
        >
          {" "}
          <Typography
            component="h1"
            variant="h6"
            sx={{
              color:
                theme.palette.mode === "dark"
                  ? "rgba(240,240,240,0.5)" //theme.palette.grey[400]
                  : "rgba(15,15,15,0.5)", //theme.palette.grey[600],
              fontWeight: "bold",
              ml: "0px",
              mt: "10px",
              mb: "5px",
            }}
          >
            Historical performance:
          </Typography>
          <TargetVisualization
            rightLabel={baselineRow.inRangeLabel}
            bottomLabel={baselineRow.goodLabel}
            inRangePercentage={baselineRow.pcntPeriodsInRange}
            goodPercentage={baselineRow.percent}
            badPercentage={baselineRow.pcntBad}
            alpha={"0.3"}
          ></TargetVisualization>
        </Box>
      </Box>
      <Box
        sx={{
          borderTop: "solid",
          pt: "10px",
          display: "flex",
          justifyContent: "center",
          gap: 2,
          mt: 2,
        }}
      >
        <Button
          startIcon={<GetAppIcon />}
          onClick={handleExport}
          aria-label="export"
          sx={{
            color: colors.primary[200],
            "&:hover": {
              backgroundColor: "rgba(127,127,127,0.1)",
              color: colors.primary[100],
            },
          }}
        >
          Export
        </Button>
        <Button
          startIcon={<RestoreIcon />}
          onClick={handleReset}
          aria-label="reset"
          sx={{
            color: colors.primary[200],
            "&:hover": {
              backgroundColor: "rgba(127,127,127,0.1)",
              color: colors.primary[100],
            },
          }}
        >
          Reset Values
        </Button>
        <Button
          startIcon={<CheckCircleIcon />}
          onClick={handleApplyToAPC}
          aria-label="apply to apc"
          sx={{
            color: colors.primary[200],
            "&:hover": {
              backgroundColor: "rgba(127,127,127,0.1)",
              color: colors.primary[100],
            },
          }}
        >
          Apply to APC
        </Button>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="warning"
            sx={{ width: "100%" }}
          >
            No Advanced Process Control API registered
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}
