import React from "react";
import PlotlyFigure from "./PlotlyFigure";
import TabbedGroupFigure from "./TabbedGroupFigure";
import TableFigure from "./TableFigure";
import OptimalRange from "./OptimalRange";
import CitationList from "./CitationList";
import DynamicForm from "../customUi/DynamicForm";
import Pill from "./Pill";
import { Box, useTheme } from "@mui/material";

import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Stack } from "@mui/material";
import PushPinIcon from "@mui/icons-material/PushPin";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import QueryBuilder from "../queryBuilder/QueryBuilder.jsx";

const FigureActions = () => {
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(true);

  function thumbsUpHandler() {
    console.log("thumbs up");
  }

  function thumbsDownHandler() {
    console.log("thumbs down");
  }

  function pinHandler() {
    console.log("pin");
  }

  function editQueryHandler() {
    console.log("edit query");
  }

  return (
    <Stack spacing={1}>
      <Tooltip
        id="pin-answer"
        title="Save this to my profile"
        arrow
        placement="right-start"
        onClick={() => pinHandler()}
      >
        <IconButton
          aria-label="Pin this Answer"
          sx={{
            color: "primary",
            ":hover": {
              color: "#4cceac",
            },
          }}
        >
          <PushPinIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        id="query-builder"
        title="Edit this Query"
        open={tooltipIsOpen}
        arrow
        placement="right-start"
        onMouseEnter={() => setTooltipIsOpen(true)}
        onMouseLeave={() => setTooltipIsOpen(false)}
        onClick={() => editQueryHandler()}
      >
        <IconButton
          aria-label="Edit Query"
          sx={{
            color: "primary",
            ":hover": {
              color: "#4cceac",
            },
          }}
        >
          <EditNoteIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        id="thumbs-up"
        title="This is a good answer"
        arrow
        placement="right-start"
        onClick={() => thumbsUpHandler()}
      >
        <IconButton
          aria-label="Thumbs Up"
          sx={{
            color: "primary",
            ":hover": {
              color: "#5effd4",
            },
          }}
        >
          <ThumbUpAltIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        id="thumbs-down"
        title="This is not a good answer"
        arrow
        placement="right-start"
        onClick={() => thumbsDownHandler()}
      >
        <IconButton
          aria-label="Thumbs Down"
          sx={{
            color: "primary",
            ":hover": {
              color: "#fc8d62",
            },
          }}
        >
          <ThumbDownAltIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

let renderReactionButtons = false;
function ActionReturnFigureComponent({ figure, sessionId }) {
  if (figure.type === "PLOTLY") {
    return (
      <Stack direction="row">
        <PlotlyFigure plotlyToJsonStr={figure.plotly_to_json_str} />
        {renderReactionButtons && <FigureActions />}
      </Stack>
    );
  } else if (figure.type === "OPTIMAL_RANGE") {
    return <OptimalRange config={figure.config} data={figure.data} />;
  } else if (figure.type === "TABLE") {
    return (
      <TableFigure
        tableData={figure.table_data}
        tableColumns={figure.table_columns}
        tableConfig={figure.table_config}
      />
    );
  } else if (figure.type === "TABBED_GROUP") {
    return <TabbedGroupFigure tabs={figure.tabs} />;
  } else if (figure.type === "QUERY_BUILDER") {
    return <QueryBuilder initialValues={figure.params} sessionId={sessionId} />;
  } else if (figure.type === "SOURCES") {
    return <CitationList sourceList={figure.source_list} />;
  } else if (figure.type === "DYNAMIC_FORM") {
    return (
      <DynamicForm
        variables={figure.variables}
        ui_controls={figure.ui_controls}
        auto_submit={figure.auto_submit}
        refresh_url={figure.refresh_url}
        sessionId={sessionId}
      />
    );
  } else if (figure.type === "IMAGE") {
    return (
      <Box
        component="img"
        src={figure.image_data}
        alt="From FastAPI"
        sx={{ width: "90%" }}
      />
    );
  } else if (figure.type === "PILL_STATIC") {
    return <Pill text={figure.title} expandable={false} />;
  }
}

function ActionReturnFigure({ figure, sessionId }) {
  const theme = useTheme();

  return (
    //  Set a light shaded background
    <Box
      sx={{
        ...(![
          "PILL_STATIC",
          "QUERY_BUILDER",
          "TABBED_GROUP",
          "TABLE",
          "DYNAMIC_FORM",
          "SOURCES",
        ].includes(figure.type)
          ? {
              pt: "10px",
              pl: "15px",
              pr: "10px",
              backgroundColor:
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.02)"
                  : "rgba(255, 255, 255, 0.04)",
            }
          : {}),
      }}
    >
      <ActionReturnFigureComponent figure={figure} sessionId={sessionId} />
    </Box>
  );
}

export default ActionReturnFigure;
