import { useState } from "react";
import {
  Box,
  useTheme,
  Slider,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiInput from "@mui/material/Input";
import { tokens } from "../../theme";

function valuetext(value) {
  return `${value}`;
}

const Input = styled(MuiInput)`
  width: 42px;
`;

const ValueBar = ({ value, total }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Avoid division by zero
  const safeTotal = total === 0 ? 1 : total;

  // Calculate percentage for each category
  const valuePercentage = (value / safeTotal) * 100;
  const remainingPercentage = ((safeTotal - value) / safeTotal) * 100;

  // Define colors for each category
  const goodColor = colors.greenAccent[500]; // green
  const badColor = colors.redAccent[600]; // red
  const noneColor = "rgba(127,127,127,0.1)"; // grey

  const tooltipContent = `Variable importance: ${Number(value).toFixed(4)}`;

  return (
    <Tooltip
      title={
        <div style={{ fontSize: "1.3em" }}>
          <div style={{ fontWeight: "bold" }}>{tooltipContent}</div>
          <div>
            This indicates the impact of this process variable on the target
            performance
          </div>
        </div>
      }
    >
      <Box
        display="flex"
        alignItems="center"
        width={200} // fixed width of the entire bar
        height={10}
        overflow="hidden"
        sx={{
          pt: "0px",
          pb: "0px",
          mr: "1.5em",
          ml: "1em",
          borderColor: "rgba(127,127,127,0.2)",
          bordetWidth: "1px",
          borderStyle: "solid",
        }}
      >
        <Box
          width={`${valuePercentage}%`}
          sx={{ backgroundColor: goodColor }}
          height="100%"
        />
        <Box
          width={`${remainingPercentage}%`}
          bgcolor={noneColor}
          height="100%"
        />
      </Box>
    </Tooltip>
  );
};

export default function RangeSlider({
  featureName,
  enabled,
  featureImportance,
  rangeMin,
  rangeMax,
  min,
  max,
  handleCustomRangeChange,
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleEnabledChange = (event) =>
    handleCustomRangeChange(featureName, event.target.checked, min, max);

  const handleMinInputChange = (event) =>
    handleCustomRangeChange(featureName, enabled, event.target.value, max);

  const handleMaxInputChange = (event) =>
    handleCustomRangeChange(featureName, enabled, min, event.target.value);

  const handleChange = (event, newValue) =>
    handleCustomRangeChange(featureName, enabled, newValue[0], newValue[1]);

  function calcStepsInRange(rangeMin, rangeMax) {
    let range = rangeMax - rangeMin;
    let desiredSteps = range / 500; // aiming for about 500 steps

    let powerOfTen = Math.floor(Math.log10(desiredSteps));

    // Ensure the step value is an integer power of 10
    let step = Math.pow(10, powerOfTen);

    // Handle the case where the step value would result in more than 1000 steps
    if (range / step > 500) {
      step *= 10;
    }

    return step;
  }

  return (
    <Box
      className="RangeSliderContainer"
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Switch
        sx={{
          mb: "2px",
          "& .MuiSwitch-track": {
            backgroundColor: "grey", // Color for the track when the switch is off
          },
          "& .MuiSwitch-thumb": {
            color: enabled ? "rgba(127,127,127,1)" : "rgba(192,192,192,1)", // Color for the thumb
          },
          "& .Mui-checked + .MuiSwitch-track": {
            backgroundColor: enabled
              ? "rgba(96,96,96,1)"
              : "rgba(127,127,127,0.2)", // Color for the track when the switch is on
          },
          "& .MuiSwitch-switchBase.Mui-checked": {
            color: "yellow", // Color for the thumb when the switch is on
          },
        }}
        color="primary"
        checked={enabled}
        onChange={handleEnabledChange}
      />
      <Box
        sx={{
          mt: "4px",
          flex: "0 0 15%",
          wordWrap: "break-word",
          overflow: "hidden",
          marginRight: 0,
        }}
      >
        <Typography id="range-slider" gutterBottom>
          {featureName}
        </Typography>
      </Box>
      <ValueBar value={featureImportance} total={1} />
      <Box sx={{ display: "flex", width: "100%" }}>
        <Input
          value={min}
          sx={{ width: "80px", mt: "1px", mr: "15px" }}
          size="small"
          disabled={!enabled}
          onChange={handleMinInputChange}
          inputProps={{
            step: calcStepsInRange(rangeMin, rangeMax) * 10,
            min: { rangeMin },
            max: { rangeMax },
            type: "number",
            "aria-labelledby": "input-slider",
          }}
        />
        <Slider
          getAriaLabel={() => featureName}
          value={[min, max]}
          onChange={handleChange}
          color="primary"
          disabled={!enabled}
          min={rangeMin}
          max={rangeMax}
          step={calcStepsInRange(rangeMin, rangeMax)}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          sx={{
            "& .MuiSlider-thumb": {
              color: enabled ? "rgba(127,127,127,1)" : "rgba(192,192,192,1)", // Thumb color
            },
            "& .MuiSlider-track": {
              color: enabled
                ? "rgba(127,127,127,0.8)"
                : "rgba(150,150,150,0.2)", // Track color
            },
            "& .MuiSlider-rail": {
              color: "grey", // Rail color
            },
            "& .MuiSlider-valueLabel": {
              backgroundColor: "grey", // Value label background color
            },
          }}
        />
        <Input
          value={max}
          size="small"
          disabled={!enabled}
          sx={{ width: "80px", mt: "1px", ml: "15px" }}
          onChange={handleMaxInputChange}
          inputProps={{
            step: calcStepsInRange(rangeMin, rangeMax) * 10,
            min: { rangeMin },
            max: { rangeMax },
            type: "number",
            "aria-labelledby": "input-slider",
          }}
        />
      </Box>
    </Box>
  );
}
