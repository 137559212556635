import React, { useState } from "react";
import { tokens } from "../../theme";
import {
  Button,
  CircularProgress,
  useTheme,
  Box,
  Typography,
} from "@mui/material";
import DirectionsRun from "@mui/icons-material/DirectionsRun";

function CircularProgressButton({
  buttonLabel,
  cancelLabel,
  clickHandler,
  cancelHandler,
  isLoading,
  icon = null,
  sx = {},
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const defaultSx = !isLoading
    ? {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "4px",
        color: theme.palette.getContrastText(theme.palette.secondary.main),
        fontSize: "14px", // Set font size
        "&:hover": {
          backgroundColor: theme.palette.secondary.main, // Set hover background color
        },
      }
    : {
        backgroundColor: theme.palette.warning.main, // Set background color
        color: theme.palette.getContrastText(theme.palette.warning.main),
        fontSize: "14px", // Set font size
        "&:hover": {
          backgroundColor: theme.palette.warning.main, // Set hover background color
        },
      };

  return (
    <Button
      variant="contained"
      disabled={isLoading && typeof cancelHandler !== "function"}
      onClick={
        isLoading && typeof cancelHandler === "function"
          ? cancelHandler
          : clickHandler
      }
      sx={{ ...defaultSx, ...sx }}
    >
      <Box display="flex" alignItems="center">
        {isLoading ? (
          <>
            <Typography variant="body1" sx={{ mr: "10px" }}>
              {cancelLabel}
            </Typography>
            <CircularProgress size={18} sx={{ ml: 1 }} />
          </>
        ) : (
          <>
            <Typography variant="body1" sx={{ mr: "10px" }}>
              {buttonLabel}
            </Typography>
            {icon ? icon : <DirectionsRun />}
          </>
        )}
      </Box>
    </Button>
  );
}

export default CircularProgressButton;
