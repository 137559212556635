import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FurnaceParameterEntry from "./FurnaceParameters";
import FurnaceScheduleDisplay from "./FurnaceScheduleDisplay";
import calcSchedule from "./calculateSchedule";

const FurnaceScheduleDisplayMemo = React.memo(FurnaceScheduleDisplay);
const FurnaceParameterEntryMemo = React.memo(FurnaceParameterEntry);

// Debounce function outside the component
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const FurnaceSchedule = () => {
  const [parameters, setParameters] = useState({
    matGrade: 70,
    blisterCopper: 98,
    recovery: 98,
    fcOnlineTime: 22,
    anodeProductionCharge: 540,
    tappingTime: 2,
    avgCastingRate: 80,
    refiningTime: 6,
    blisterPerTap: 180,
    shaftMeltingRate: 35,
    blisterToCopperConv: 0.64,
    tapsToFillFurnace: 2,
    availableFurnaces: "East and West",
    fcFeedRate: 55,
    drain: "No",
    drainTime: 4,
    blisterProd: "",
    afFillTime: "",
    numberOfTaps: "",
    timeBetweenTaps: "",
    castTimePerFurnace: "",
    anodeCyclePerFurnace: "",
    cyclesPerDay: "",
    firstFurnace: "westState",
    secondFurnace: "eastState",
    daysToShow: 3,
  });

  const [scheduleData, setScheduleData] = useState([]);

  const handleIncreaseDays = () => {
    if (parameters.daysToShow < 8) {
      setParameters((prev) => ({ ...prev, daysToShow: prev.daysToShow + 1 }));
      window.scrollTo(0, document.body.scrollHeight);
    }
  };

  const handleDecreaseDays = () => {
    if (parameters.daysToShow > 1) {
      setParameters((prev) => ({
        ...prev,
        daysToShow: Math.max(1, prev.daysToShow - 1),
      }));
      window.scrollTo(0, document.body.scrollHeight);
    }
  };

  const recalculateSchedule = useCallback(
    debounce(() => {
      setScheduleData(calcSchedule(parameters));
    }, 10),
    [parameters]
  );

  useEffect(() => {
    recalculateSchedule();
  }, [parameters, recalculateSchedule]);

  return (
    <Box sx={{ mt: "2em", mr: "3em", ml: "1em", pb: "3em" }}>
      <Typography variant="h2" sx={{ mt: "1em", fontWeight: "bold" }}>
        Furnace Plan Optimizer
      </Typography>
      <Box
        sx={{
          pl: "1em",
          pr: "2em",
          pt: "0.5em",
          mt: "1em",
          pb: "1em",
          mb: "3em",
          backgroundColor: "rgba(127,127,127,0.05)",
        }}
      >
        <FurnaceParameterEntryMemo
          data={parameters}
          onDataUpdate={setParameters}
        />
      </Box>
      <FurnaceScheduleDisplayMemo scheduleData={scheduleData} />
      <Box sx={{ display: "flex", alignItems: "center", mt: "-3em" }}>
        <IconButton
          onClick={handleDecreaseDays}
          aria-label="decrease days"
          disabled={parameters.daysToShow <= 1}
        >
          <RemoveIcon />
        </IconButton>
        <Typography sx={{ mx: 2 }}>{parameters.daysToShow} Days</Typography>
        <IconButton
          onClick={handleIncreaseDays}
          aria-label="increase days"
          disabled={parameters.daysToShow >= 8}
        >
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default FurnaceSchedule;
