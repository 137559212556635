import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import {
  Box,
  useTheme,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { ApiContext } from "../../App.js";
import ThumbUpIcon from "@mui/icons-material/ThumbUp"; // import thumbsUp icon
import ThumbDownIcon from "@mui/icons-material/ThumbDown"; // import thumbsDown icon
import PinIcon from "@mui/icons-material/PushPin"; // import pin icon
import Header from "../Header.jsx";
import { tokens } from "../../theme.js";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import FormControlLabel from "@mui/material/FormControlLabel";

import { ActiveChatContextRidContext } from "../../scenes/chat/index.jsx";
import { NotebookIntegrationsContext } from "../../App.js";
import { UserContext } from "../../Auth";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import JupyterIcon from "../subcomponents/Jupyter/JupyterIcon";
import { sendUserToJupyter } from "../subcomponents/Jupyter/Integration.jsx";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function getCurrentDateTime() {
  // Return current system date in yyyy-mm-dd hh:mm:ss format
  const now = new Date();
  const year = now.getFullYear();

  const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Add 1 since javascript months are zero-based
  const day = now.getDate().toString().padStart(2, "0");
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const FeedbackButtons = ({
  showPinning,
  showThumbsUpDown,
  showJupyterLink,
  chatContextId,
  sessionId,
  queryBuilderParamValues,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const api = useContext(ApiContext);
  const activeChatContextRid = useContext(ActiveChatContextRidContext);
  const notebookIntegrations = useContext(NotebookIntegrationsContext);

  const user = useContext(UserContext);

  const [notebookMenuOpen, setNotebookMenuOpen] = useState(false);
  const [notebookMenuAnchorEl, setNotebookMenuAnchorEl] = useState(null);

  const [thumbsUpActive, setThumbsUpActive] = useState(false);
  const [thumbsDownActive, setThumbsDownActive] = useState(false);
  const [pinnedRid, setPinnedRid] = useState(-1);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const [pinInTransition, setPinInTransition] = useState(false);

  const handleThumbsUpClick = () => {
    if (!thumbsUpActive && thumbsDownActive) {
      setThumbsDownActive(false);
    }
    setThumbsUpActive(!thumbsUpActive);
  };

  const handleThumbsDownClick = () => {
    if (!thumbsDownActive && thumbsUpActive) {
      setThumbsUpActive(false);
    }
    setThumbsDownActive(!thumbsDownActive);
  };

  const pinDisplay = () => {
    const payload = {
      owner_rid: user.rid,
      chat_context_rid: queryBuilderParamValues?.chat_context_rid,
      from_chat_session_id: sessionId,
      title: queryBuilderParamValues.title,
      comment: "",
      sequence: 0,
      visible: true,
      expanded: true,
      parameters: queryBuilderParamValues,
      options: null,
      refresh_interval_sec: null,
      thumbnail: null,
      tags: null,
    };
    api
      .post("/timeseries/saved-displays/create", payload, {
        timeout: 10000, // 10 seconds
      })
      .then((res) => {
        setPinInTransition(false);
        setPinnedRid(res.data.rid);
        setSnackbarMessage(`Display saved as ${queryBuilderParamValues.title}`);
        setSnackbarSeverity("success"); // Set the severity for success message
        setSnackbarOpen(true); // Open the Snackbar
      })
      .catch((error) => {
        console.log(error);
        setPinInTransition(false);
        setSnackbarMessage("Could not save display"); // Set error message
        setSnackbarSeverity("error"); // Set the severity for error message
        setSnackbarOpen(true); // Open the Snackbar
      });
    setPinInTransition(true);
  };

  const unpinDisplay = () => {
    api
      .delete(`/timeseries/saved-displays/delete/${pinnedRid}`, {
        timeout: 10000, // 10 seconds
      })
      .then((res) => {
        setPinInTransition(false);
        setSnackbarMessage(`${queryBuilderParamValues.title} removed`);
        setPinnedRid(-1);
        setSnackbarSeverity("info"); // Set the severity for info message
        setSnackbarOpen(true); // Open the Snackbar
      })
      .catch((error) => {
        console.log(error);
        setPinInTransition(false);
        setSnackbarMessage("Could not unpin display"); // Set error message
        setSnackbarSeverity("error"); // Set the severity for error message
        setSnackbarOpen(true); // Open the Snackbar
      });
    setPinInTransition(true);
  };

  const handlePinClick = () => {
    if (pinnedRid >= 0) {
      unpinDisplay();
    } else {
      pinDisplay();
    }
  };

  const handleNotebookMenuClose = () => {
    setNotebookMenuAnchorEl(null);
    setNotebookMenuOpen(false);
  };

  const handleJupyterClick = (event) => {
    //event.stopPropagation(); // Prevents the click from reaching the parent
    setNotebookMenuAnchorEl(event.currentTarget);
    setNotebookMenuOpen(true);
    //downloadNotebook(api, queryBuilderParamValues);
  };

  const downloadNotebook = async (api, params, notebook_integration) => {
    const payload = {
      params,
      notebook_integration,
    };

    try {
      const response = await api.post(
        "/timeseries/download-notebook",
        payload,
        {
          responseType: "blob",
        }
      );

      // Create a URL for the blob
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", "notebook.ipynb");
      document.body.appendChild(fileLink);

      fileLink.click();

      fileLink.remove(); // Clean up
    } catch (error) {
      // Default error message
      let errorMessage =
        "Could not download notebook due to an unexpected error.";

      // Does error response include a detailed message
      if (error.response && error.response.data && error.response.data.detail) {
        errorMessage = `Could not download notebook: ${error.response.data.detail}`;
      } else if (error.message) {
        errorMessage += ` Error message: ${error.message}`;
      }

      // Use the detailed error message for the snackbar
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);

      // Log detailed error information for debugging
      if (error.response) {
        // Server responded with a status code that falls out of the range of 2xx
        console.error("Response data:", error.response.data);
        console.error("Status code:", error.response.status);
        console.error("Headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Request:", error.request);
      }
    }
  };

  const sendUserToJupyter = async (api, params, notebook_integration) => {
    const payload = {
      params,
      notebook_integration,
    };

    try {
      const response = await api.post("/timeseries/send_to_jupyter", payload);

      const returnUrl = response.data.url;
      console.log(returnUrl);
      window.open(returnUrl, "_blank"); // Open the URL in a new tab
    } catch (error) {
      console.error("Error sending data to Jupyter:", error);

      // Default error message
      let errorMessage =
        "Could not open notebook in Jupyter due to an unexpected error.";

      // Does error response include a detailed message
      if (error.response && error.response.data && error.response.data.detail) {
        errorMessage = `Could not open notebook in Jupyter: ${error.response.data.detail}`;
      } else if (error.message) {
        errorMessage += ` Error message: ${error.message}`;
      }

      // Use the detailed error message for the snackbar
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);

      // Log detailed error information for debugging
      if (error.response) {
        // Server responded with a status code that falls out of the range of 2xx
        console.error("Response data:", error.response.data);
        console.error("Status code:", error.response.status);
        console.error("Headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Request:", error.request);
      }
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          mt: "10px",
          display: "flex",
          gap: "1",
          justifyContent: "flex-end",
          color: colors.grey[500],
        }}
      >
        {showThumbsUpDown ? (
          <>
            <Tooltip title="This response was helpful">
              <IconButton
                size="small"
                color={thumbsUpActive ? "secondary" : "inherit"}
                onClick={handleThumbsUpClick}
                sx={{ marginRight: ".1em" }}
              >
                <ThumbUpIcon sx={{ fontSize: "1.2em" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="This response was not helpful">
              <IconButton
                size="small"
                color={thumbsDownActive ? "redAccent" : "inherit"}
                onClick={handleThumbsDownClick}
                sx={{ marginRight: ".5em" }}
              >
                <ThumbDownIcon sx={{ fontSize: "1.2em" }} />
              </IconButton>
            </Tooltip>
          </>
        ) : null}
        {showPinning ? (
          <Tooltip title="Pin to dashboard">
            <IconButton
              size="small"
              color={pinnedRid >= 0 ? "secondary" : "inherit"}
              onClick={handlePinClick}
              sx={{ marginRight: "0em" }}
            >
              <PinIcon sx={{ fontSize: "1.2em" }} />
            </IconButton>
          </Tooltip>
        ) : null}
        {showJupyterLink && notebookIntegrations?.length > 0 ? (
          <>
            <Menu
              anchorEl={notebookMenuAnchorEl}
              open={notebookMenuOpen}
              onClose={handleNotebookMenuClose}
            >
              {notebookIntegrations?.map((integration, index) => {
                if (integration.integration_type === "DOWNLOAD_IPYNB") {
                  return (
                    <MenuItem
                      onClick={() => {
                        handleNotebookMenuClose();
                        downloadNotebook(
                          api,
                          queryBuilderParamValues,
                          integration
                        );
                      }}
                      sx={{ m: 0 }}
                    >
                      <ListItemIcon>
                        <DownloadIcon />
                      </ListItemIcon>
                      Download Jupyter Notebook
                    </MenuItem>
                  );
                } else if (
                  ["JUPYTER_LAB", "JUPYTER_NOTEBOOK"].includes(
                    integration.integration_type
                  )
                ) {
                  return (
                    <MenuItem
                      onClick={() => {
                        handleNotebookMenuClose();
                        sendUserToJupyter(
                          api,
                          queryBuilderParamValues,
                          integration
                        );
                      }}
                      sx={{ m: 0 }}
                    >
                      <ListItemIcon>
                        <AnalyticsIcon />
                      </ListItemIcon>
                      {integration.menu_name}
                    </MenuItem>
                  );
                }
                return null;
              })}
            </Menu>
            <Tooltip title="Analyze in Jupyter">
              <IconButton
                size="small"
                color={"inherit"}
                onClick={handleJupyterClick}
                sx={{ marginRight: "0em" }}
              >
                <JupyterIcon sx={{ fontSize: "1.2em" }} />
              </IconButton>
            </Tooltip>
          </>
        ) : null}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          // Apply negative margin to move the Snackbar up by 100px from the bottom
          marginBottom: "100px",
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
