import React, { useState, useContext, useRef, useEffect } from "react";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Typography,
  MenuItem,
  Select,
  Collapse,
  Switch,
  Tab,
  Tabs,
  TabPanel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Divider,
  useTheme,
} from "@mui/material";

import CircularProgressButton from "../subcomponents/CircularProgressButton";
import axios from "axios";
import { ApiContext } from "../../App.js";
import ChatResponse from "../chat/ChatResponse";
import DataSeriesListDisplay from "./DataSeriesListDisplay";
import DataSeriesSelector from "./DataSeriesSelector";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useImmer } from "use-immer";
import { DataSeriesListContext } from "../../App.js";
import { UserContext } from "../../Auth";
import PropTypes from "prop-types";
import { ActiveChatContextRidContext } from "../../scenes/chat";
import ParameterPill from "../subcomponents/ParameterPill";
import TimeRangePicker from "../subcomponents/TimeRangePicker";
import { theme, tokens } from "../../theme";
import { FeedbackButtons } from "../chat/FeedbackButtons";

const QueryBuilder = ({
  initialValues,
  sessionId,
  noFeedbackButtons,
  noCommentary,
}) => {
  const user = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dataSeriesList = useContext(DataSeriesListContext);
  const activeChatContextRid = useContext(ActiveChatContextRidContext);
  const [values, setValues] = useImmer(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const api = useContext(ApiContext);
  const cancelTokenSourceRef = useRef(null);

  const [actionResponse, setActionResponse] = useState(false);

  const [expanded, setExpanded] = React.useState(false);
  const handleExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [debugOpen, setDebugOpen] = React.useState(false);

  useEffect(() => {
    executeQuery();
  }, []);

  const lookupMetadata = (dataSeriesSystemSk) => {
    if (dataSeriesSystemSk && dataSeriesList) {
      const res = dataSeriesList.find(
        (meta) => meta.system_sk === dataSeriesSystemSk
      );
      return res;
    } else {
      return {};
    }
  };

  const setIsTargetFeature = (index) => {
    setValues((draft) => {
      for (let i = 0; i < draft.data_series_as_list.length; i++) {
        draft.data_series_as_list[i].is_target_feature = i === index;
      }
      draft.target_feature_index = index;
    });
  };

  const handleRemoveDataSeries = (index) => {
    setValues((draft) => {
      if (index >= 0 && index < draft.data_series_as_list.length) {
        draft.data_series_as_list.splice(index, 1);
      } else {
        console.error(`Invalid index ${index}`);
      }
    });
  };

  const handleUpdateDataSeries = (index, dataSeriesObj) => {
    setValues((draft) => {
      if (index >= 0) {
        // Update single data series
        draft.data_series_as_list[index] = {
          ...draft.data_series_as_list[index],
          ...dataSeriesObj,
        };
      } else {
        // Apply settings to all data series

        // Filter out properties that should not be applied to all data series
        const {
          id,
          user_key,
          full_desc,
          label,
          is_target_feature,
          ...filteredDataSeriesObj
        } = dataSeriesObj;
        // Apply all remaining properties from incoming dataSeriesObj
        draft.data_series_as_list = draft.data_series_as_list.map((obj) => ({
          ...obj,
          ...filteredDataSeriesObj,
        }));
      }
    });
  };

  const handleAddDataSeries = (dataSeriesSystemSk) => {
    const thisMetadata = lookupMetadata(dataSeriesSystemSk);

    const dataSeriesObj = {
      id: dataSeriesSystemSk,
      user_key: thisMetadata.user_sk,
      full_desc: thisMetadata.path_descr,
      label: thisMetadata.title,
      is_target_feature: false,
      exclude_value_lt: null,
      exclude_value_gt: null,
      value_adder: 0,
      value_multiplier: 1,
      time_shift: 0,
      time_shift_units: "hours",
      fill_method: thisMetadata.is_continuous ? "interpolate" : "ffill",
    };

    setValues((draft) => {
      draft.data_series_as_list.push(dataSeriesObj);
    });
  };

  const handleParamChange = (prop) => (event) => {
    setValues((prevValues) => ({ ...prevValues, [prop]: event.target.value }));
  };

  let defaultTags = {};
  if (values?.data_series_as_list && values?.data_series_as_list.length > 0) {
    defaultTags = values.data_series_as_list[0].tag_values;
  }

  function executeQuery() {
    cancelTokenSourceRef.current = axios.CancelToken.source();
    const payload = {
      params: values,
      context_rid_list: [values.chat_context_rid],
    };
    api
      .post("/timeseries/action", payload, {
        timeout: 600000, // 10 minutes in milliseconds
        cancelToken: cancelTokenSourceRef.current.token,
      })
      .then((res) => {
        setIsLoading(false);
        setActionResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setActionResponse({});
      });
    setIsLoading(true);
  }

  const cancelQuery = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel("Request cancelled manually.");
    }
  };

  const handleDebugOpen = () => {
    setDebugOpen(true);
  };

  const handleDebugClose = () => {
    setDebugOpen(false);
  };

  const [showResample, setShowResample] = useState(values?.resample);

  const toggleResample = () => {
    if (showResample) {
      setValues((draft) => {
        draft.resample = 0;
      });
    } else {
      if (!values?.resample || values?.resample != 0) {
        setValues((draft) => {
          draft.resample = 1;
        });
      }
    }
    setShowResample(!showResample);
  };

  const ShowDebugInfo = () => {
    return (
      <div>
        <Dialog
          open={debugOpen}
          onClose={handleDebugClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {
              "DEBUG information only- not to be used in life-threatening scenarios"
            }
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>
                <pre>{JSON.stringify(values, null, 2)}</pre>
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button contained autoFocus onClick={handleDebugClose}>
              Cool
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Box sx={{ mt: "15px" }}>
      <ParameterPill isLoading={isLoading}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            minWidth: "min-content",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              minWidth: "8em",
              mr: "50px",
              mt: "1em",
              mb: "1em",
              "& .MuiTabs-indicator": {
                // This targets the active tab indicator
                backgroundColor: "secondary.main",
              },
            }}
          >
            <Tab
              sx={{
                fontSize: "11pt",
                "&.Mui-selected": {
                  // This ensures the style is applied only on the selected tab
                  color: "secondary.main", // Use your theme's secondary color
                  fontWeight: "bold", // Optional: if you want the selected tab label to be bold
                },
              }}
              label="Time Range"
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                fontSize: "11pt",
                "&.Mui-selected": {
                  // This ensures the style is applied only on the selected tab
                  color: "secondary.main", // Use your theme's secondary color
                  fontWeight: "bold", // Optional: if you want the selected tab label to be bold
                },
              }}
              label="Data Series"
              {...a11yProps(1)}
            />
            {/*}
            <Tab
              sx={{
                fontSize: "11pt",
                "&.Mui-selected": {
                  // This ensures the style is applied only on the selected tab
                  color: "secondary.main", // Use your theme's secondary color
                  fontWeight: "bold", // Optional: if you want the selected tab label to be bold
                },
              }}
              label="Filters"
              {...a11yProps(2)}
            />
            */}
          </Tabs>
          <Box
            component="div"
            role="tabpanel"
            sx={{
              flexGrow: 1, // allows the content to take up the remaining space
            }}
          >
            {tabValue === 0 ? (
              <Stack
                spacing={3}
                direction="column"
                alignItems="flex-start"
                divider={<Divider orientation="horizontal" flexItem />}
                sx={{ mb: "70px", mt: "20px" }}
                justifyContent="flex-start"
              >
                <TimeRangePicker
                  setValues={setValues}
                  returnValues={values}
                ></TimeRangePicker>
                <Stack
                  spacing={2}
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  sx={{ mt: "0px" }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showResample}
                        onChange={toggleResample}
                      />
                    }
                    label={
                      <Typography variant="body" sx={{ fontWeight: "bold" }}>
                        Resample and Pivot
                      </Typography>
                    }
                    sx={{ ml: "0px" }}
                    labelPlacement="start"
                  />
                  <Collapse in={showResample}>
                    <Typography
                      variant="body1"
                      sx={{ color: colors.grey[600] }}
                    >
                      Select a sample rate and aggregation function.
                    </Typography>
                    <Box
                      sx={{
                        mt: "10px",
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        flexWrap: "wrap",
                        alignItems: "left",
                      }}
                    >
                      <TextField
                        size="small"
                        variant="outlined"
                        type="number"
                        sx={{ width: "100px", minWidth: "100px" }}
                        value={values?.resample}
                        onChange={handleParamChange("resample")}
                      />
                      <Select
                        size="small"
                        variant="outlined"
                        sx={{ width: "100px", minWidth: "100px" }}
                        value={values?.resample_units}
                        onChange={(event) =>
                          handleParamChange("resample_units")(event)
                        }
                      >
                        <MenuItem value="seconds">seconds</MenuItem>
                        <MenuItem value="minutes">minutes</MenuItem>
                        <MenuItem value="hours">hours</MenuItem>
                        <MenuItem value="days">days</MenuItem>
                        <MenuItem value="weeks">weeks</MenuItem>
                        <MenuItem value="months">months</MenuItem>
                        <MenuItem value="years">years</MenuItem>
                      </Select>

                      <Select
                        size="small"
                        variant="outlined"
                        sx={{ width: "120px", minWidth: "120px" }}
                        value={values?.resample_agg_function}
                        onChange={(event) =>
                          handleParamChange("resample_agg_function")(event)
                        }
                      >
                        <MenuItem value="mean">mean</MenuItem>
                        <MenuItem value="median">median</MenuItem>
                        <MenuItem value="min">min</MenuItem>
                        <MenuItem value="max">max</MenuItem>
                        <MenuItem value="sum">sum</MenuItem>
                        <MenuItem value="count">count</MenuItem>
                        <MenuItem value="first">first</MenuItem>
                        <MenuItem value="last">last</MenuItem>
                        <MenuItem value="std">std dev</MenuItem>
                      </Select>
                    </Box>
                  </Collapse>
                </Stack>
              </Stack>
            ) : null}

            {tabValue === 1 ? (
              <Stack sx={{ mb: "0px", mt: "20px" }}>
                <DataSeriesListDisplay
                  lookupMetadata={lookupMetadata}
                  handleUpdateDataSeries={handleUpdateDataSeries}
                  handleRemoveDataSeries={handleRemoveDataSeries}
                  setIsTargetFeature={setIsTargetFeature}
                  dataSeriesList={values.data_series_as_list}
                />
                <DataSeriesSelector
                  activeChatContextRid={
                    values.active_chat_context_rid
                      ? values.active_chat_context_rid
                      : activeChatContextRid
                  }
                  defaultTags={defaultTags}
                  handleAddDataseries={handleAddDataSeries}
                />
              </Stack>
            ) : null}
          </Box>
        </Box>
        <Stack spacing={2} direction="row" alignItems="right">
          <CircularProgressButton
            buttonLabel="Fetch"
            cancelLabel="Cancel"
            clickHandler={(event) => executeQuery()}
            cancelHandler={(event) => cancelQuery()}
            isLoading={isLoading}
          />
          {user.is_admin ? (
            <Button variant="outlined" onClick={handleDebugOpen}>
              Debug Info
            </Button>
          ) : null}
        </Stack>
      </ParameterPill>
      {actionResponse ? (
        <>
          <FeedbackButtons
            showPinning={!noFeedbackButtons}
            showThumbsUpDown={!noFeedbackButtons}
            showJupyterLink={true}
            queryBuilderParamValues={values}
          />
          <ChatResponse
            actionResponse={actionResponse}
            sessionId={sessionId}
            queryBuilderParamValues={values}
            noCommentary={noCommentary}
          />
        </>
      ) : null}
      <ShowDebugInfo />
    </Box>
  );
};

export default QueryBuilder;
