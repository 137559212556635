import React from "react";
import { Box } from "@mui/material";

function ImageWithShadow({
  imgProps,
  top = "1px",
  right = "1px",
  brightness = "0.9",
  grayscale = "70%",
  blur = "0.5px",
  imageHeight = "100px",
}) {
  const shadowStyle = {
    ...imgProps.style,
    filter: `brightness(${brightness}) grayscale(${grayscale}) blur(${blur})`,
    position: "absolute",
    top: top,
    left: right,
    zIndex: 1,
  };

  const originalImageStyle = {
    ...imgProps.style,
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 2,
  };

  return (
    <Box
      component="div"
      sx={{
        position: "relative",
        width: imgProps.style.width || "fit-content",
        height: imageHeight,
      }}
    >
      {/* Shadow Image */}
      <img {...imgProps} style={shadowStyle} />

      {/* Original Image */}
      <img {...imgProps} style={originalImageStyle} />
    </Box>
  );
}

export default ImageWithShadow;
