import { Box, TextField, Button, Accordion, AccordionSummary, AccordionDetails, Typography, useTheme,
FormControlLabel, Checkbox } from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { tokens } from "../../theme";
import {Fragment, useRef} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputLabel from '@mui/material/InputLabel';
import CircularProgressButton from '../../components/subcomponents/CircularProgressButton';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import dayjs  from 'dayjs';


export const timeUnitMultipliers = {
    SECONDS : 1,
    MINUTES : 60,
    HOURS   : 3600,
    DAYS    : 86400
}

export function calculateSamples( parameters ){
    let sdiff = parameters.endDateTime.diff( parameters.startDateTime, 'seconds' );
    let freq = parameters.frequency * timeUnitMultipliers[parameters.frequencyUnits]
    return Math.floor( sdiff / freq )
}



function SelectParameters( {filteredMetadata, selectedMetadata, parameters, updateParameters, executeQuery, cancelQuery, isLoading }) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const cancelTokenSourceRef = useRef(null);

    return (
        <Accordion  
        disableGutters 
        defaultExpanded="false" 
        sx={{borderRadius: "12px", 
        minHeight: 0,
        '&:before': {
          display: 'none'},
        padding: "10px 10px 10px 10px", 
        margin: "30px 30px 10px 0px",
        backgroundColor: colors.primary[400], color: colors.grey[100] }} >
          
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="headingPrompt">Select a <span>date range</span> and <span>sampling frequency</span></Typography>
          
        </AccordionSummary>
        <AccordionDetails>
            <Box >
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                        value={parameters.preset}
                        onChange={(event) => updateParameters({preset: event.target.value}, 'preset')}
                        displayEmpty
                        style = {{width: 250}}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value={'24_HOURS'}>Last 24 hours</MenuItem>
                        <MenuItem value={'3_DAYS'}>Last 3 days</MenuItem>
                        <MenuItem value={'7_DAYS'}>Last 7 days</MenuItem>
                        <MenuItem value={'1_MONTH'}>Last month</MenuItem>
                        <MenuItem value={'2_MONTHS'}>Last 2 months</MenuItem>
                        <MenuItem value={'6_MONTHS'}>Last 6 months</MenuItem>
                        <MenuItem value={'CUSTOM'}>Custom</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ display:'inline-block', m: 1, minWidth: 120 }}>
                    <DateTimePicker
                        label="Start date"
                        value={parameters.startDateTime}
                        width="10rem"
                        onChange={(newValue) => updateParameters({startDateTime: newValue}, 'startDateTime')}
                        maxDate={parameters.endDateTime}
                        minDate={dayjs().utc().subtract(3, 'year')}
                    />
                    <DateTimePicker
                        label="End date"
                        value={parameters.endDateTime}
                        onChange={(newValue) =>updateParameters({endDateTime: newValue}, 'endDateTime')}
                        minDate={parameters.startDateTime}
                    />
                    <FormHelperText>Specify all times in UTC</FormHelperText>
                </FormControl>
                <FormControl sx={{m: 1, minWidth: 120, display:"block"}}>
                    <FormControlLabel sx={{m: "10px 10px 10px -5px", display:"block"}} 
                        control={
                            <Checkbox 
                                checked={parameters.resample}
                                onChange={(event) =>updateParameters({resample: event.target.checked}, 'resample')}  />} 
                        label="Resample" />
                    { parameters.resample ? 
                        <Fragment>
                        <TextField
                            id="outlined-controlled"
                            label="Sampling Frequency"
                            style = {{width: 150}}
                            type='number'
                            value={parameters.frequency}
                            onChange={(event) => { updateParameters({frequency: event.target.value}, 'frequency')}
                            }
                        /> 
                        <Select
                            value={parameters.frequencyUnits}
                            onChange={(event) => updateParameters({frequencyUnits: event.target.value}, 'frequencyUnits')}
                            displayEmpty
                            style = {{width: 100}}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value={'SECONDS'}>second{parameters.frequency > 1 ? 's' : ''}</MenuItem>
                            <MenuItem value={'MINUTES'}>minute{parameters.frequency > 1 ? 's' : ''}</MenuItem>
                            <MenuItem value={'HOURS'}>hour{parameters.frequency > 1 ? 's' : ''}</MenuItem>
                            <MenuItem value={'DAYS'}>day{parameters.frequency > 1 ? 's' : ''}</MenuItem>
                        </Select>
                        <div style= {{margin: "10px 0 10px 0"}}>
                        {calculateSamples(parameters)} rows in result set 
                        </div>
                    </Fragment>
                        : null }
                </FormControl>
                
                <FormControl sx={{m: 1, minWidth: 120, display:"block"}}>

                    <CircularProgressButton
                        buttonLabel ='Fetch' 
                        cancelLabel = 'Cancel' 
                        clickHandler={(event) => executeQuery( )}
                        cancelHandler={(event) => cancelQuery()}
                        isLoading={isLoading}
                    />
                </FormControl>
            </Box>
        </AccordionDetails>
      </Accordion>
    )
}

export default SelectParameters;