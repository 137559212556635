import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Typography,
  useTheme,
  IconButton,
  createMuiTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Header from "../../components/Header";
import { useEffect, useState, useContext } from "react";
import { tokens } from "../../theme";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import {
  DataSourcesContext,
  ActiveDataSourceContext,
  DatasetsContext,
  ActiveDatasetsContext,
  DatasetAccordionStateContext,
  MetadataContext,
  LoadMetadataQueueContext,
} from "../../App.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CollapsedContext } from "../../App.js";

import { DataGrid } from "@mui/x-data-grid";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function SelectDataSeries({
  filteredMetadata,
  selectedMetadata,
  removeSelectedMetadata,
  addSelectedMetadata,
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [searchPhrase, setSearchPhrase] = useState("");

  const columns = [
    {
      field: "datasetDesc",
      headerName: "Dataset",
      verticalAlignment: "top",
      renderCell: (params) => (
        <div>
          <Typography
            display="block"
            variant="h6"
            sx={{ mt: "6px", mb: "6px", color: colors.grey[200] }}
          >
            {params.value}
          </Typography>
        </div>
      ),
    },

    {
      field: "item",
      headerName: "Data Series",
      flex: 3.5,
      renderCell: (params) => (
        <div>
          <Typography
            variant="h5"
            sx={{ mt: "3px", mb: "6px", color: colors.blueAccent[400] }}
          >
            {" "}
            {params.value.title}
          </Typography>
          <Typography
            display="block"
            variant="text"
            sx={{ mb: "2px", color: colors.grey[200] }}
          >
            {params.value.desc}
          </Typography>
          <Typography
            display="block"
            variant="text"
            sx={{ mb: "2px", color: colors.grey[200] }}
          >
            {params.value.pipointName}
          </Typography>
          <Typography
            display="block"
            variant="text"
            sx={{ mb: "2px", color: colors.grey[200] }}
          >
            {params.value.afPath}
          </Typography>
        </div>
      ),
    },

    {
      field: "id",
      headerName: "",
      renderCell: (params) => {
        const onSelectClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          addSelectedMetadata(params.value);
        };

        const onUnselectClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          removeSelectedMetadata(params.value);
        };

        if (selectedMetadata.indexOf(params.value) !== -1) {
          //  This data series has already been added by user.  Show a remove button
          return (
            <div>
              <Button
                key={params.value}
                className="GridButton"
                sx={{
                  "&:hover, &.GridButton.Mui-selected": {
                    backgroundColor: colors.greenAccent[500],
                  },
                  backgroundColor: colors.greenAccent[600],
                  color: colors.primary[400],
                }}
                onClick={onUnselectClick}
              >
                Selected
              </Button>
            </div>
          );
        } else {
          return (
            <div>
              <Button
                key={params.value}
                className="GridButton"
                sx={{
                  "&:hover, &.GridButton.Mui-selected": {
                    backgroundColor: colors.greenAccent[500],
                  },
                  backgroundColor: colors.primary[300],
                  color: colors.primary[400],
                }}
                onClick={onSelectClick}
              >
                Select
              </Button>
            </div>
          );
        }
      },
    },
    {
      field: "stats",
      headerName: "First - Last Measurement",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <div>
          <Typography sx={{ color: colors.grey[200] }}>
            {params.value.firstMeasurement && params.value.lastMeasurement
              ? params.value.firstMeasurement.split("T", 1) +
                "  - " +
                params.value.lastMeasurement.split("T", 1) +
                " (UTC)"
              : ""}
          </Typography>
          <Typography sx={{ color: colors.grey[200] }}>
            {params.value.numMeasurements
              ? "Samples/day: " + params.value.numMeasurements
              : ""}
          </Typography>
        </div>
      ),
    },
  ];

  //  Filter Pass #2: Filter metadata as user types in search bo
  let searchPhraseUpper = searchPhrase.toUpperCase();
  let searchTokens = searchPhraseUpper.split(" ").filter((e) => e > "");
  let filtered2Metadata = [
    { id: "ab", AF_PATH: "test", PIPOINT_NAME: "sdfsd" },
  ];
  if (!searchTokens || searchTokens.length === 0) {
    filtered2Metadata = [...filteredMetadata];
  } else {
    filtered2Metadata = filteredMetadata.filter((e) => {
      for (let token of searchTokens) {
        if (e.searchKey.indexOf(token) === -1) {
          return false;
        }
      }
      return true;
    });
  }

  function searchOnChange(text) {
    setSearchPhrase(text);
  }

  return (
    <Accordion
      disableGutters
      defaultExpanded="false"
      sx={{
        borderRadius: "12px",
        minHeight: 0,
        "&:before": {
          display: "none",
        },
        padding: "10px 10px 10px 10px",
        margin: "30px 30px 10px 0px",
        backgroundColor: colors.primary[400],
        color: colors.grey[100],
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="headingPrompt">
          Select the <span>data points</span> to analyze
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/*  SEARCH BAR */}
        <Box display="flex" borderRadius="3px">
          <TextField
            onChange={(event) => {
              searchOnChange(event.target.value);
            }}
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search"
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { borderBottom: "none" },
            "& .name-column--cell": { color: colors.greenAccent[300] },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.primary[400],
              fontSize: "1rem",
              color: colors.primary[100],
              borderColor: colors.primary[300],
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: colors.primary[400],
            },
          }}
        >
          <DataGrid
            rows={filtered2Metadata}
            columns={columns}
            getRowHeight={() => "auto"}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "6px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "12px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "18px",
              },
            }}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default SelectDataSeries;
