import React from "react";
import { ReactComponent as JupyterSvg } from "./jupyter.svg";

function JupyterIcon(props) {
  // Apply default styles with the option to override them via props
  const iconStyle = { width: "28x", height: "28px", ...props.style };

  return <JupyterSvg style={iconStyle} />;
}

export default JupyterIcon;
