import React, { useRef, useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

export function EditTitleDialog({
  open,
  onClose,
  onTitleChange,
  currentTitle,
}) {
  const [newTitle, setNewTitle] = useState(currentTitle);

  const inputRef = useRef();

  const handleSave = () => {
    // Save the new title and close the dialog
    onTitleChange(newTitle);
  };

  const handleTitleChange = (event) => {
    setNewTitle(event.target.value);
  };

  useEffect(() => {
    setNewTitle(currentTitle);
  }, [open]);

  return (
    <Dialog
      disableRestoreFocus
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: "500px", width: "80%" }, // Setting the width of the Paper component
      }}
    >
      <DialogTitle sx={{ fontWeight: "bold", fontSize: "1.3em" }}>
        New Title
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          type="text"
          fullWidth
          variant="standard"
          value={newTitle}
          onChange={handleTitleChange}
          InputProps={{
            inputRef: inputRef, // Assign the ref to the input element
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export function DeleteConfirmDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontWeight: "bold", fontSize: "1.1em" }}>
        Confirm Deletion
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
