import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect,
} from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Switch,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import Box from "@mui/material/Box";
import Header from "../../components/Header";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Button from "@mui/material/Button";
import { Delete, Edit } from "@mui/icons-material";
import { ApiContext } from "../../App";
import axios from "axios";

import Typography from "@mui/material/Typography";
import { UserModal } from "../../components/subcomponents/UserModal";

const Users = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });
  const [chatContexts, setChatContexts] = useState([]);
  const api = useContext(ApiContext);

  const fetchChatContexts = async () => {
    try {
      const response = await api.get("/chat/get_contexts");
      setChatContexts(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchChatContexts();
  }, []);

  const baseAPIURL = () => {
    if (process.env.NODE_ENV === "production") {
      return `https://${window.location.hostname}:8000`;
    } else {
      return `http://${window.location.hostname}:8000`;
    }
  };

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters,
      globalFilter,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const response = await fetch(baseAPIURL() + "/users");
      const json = await response.json();
      return { data: json };
    },
  });

  const getContextTitle = (rid) => {
    const context = chatContexts.find((context) => context.rid === rid);
    return context ? context.title : rid;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "rid",
        header: "ID",
        enableEditing: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "login",
        header: "Login",
      },
      {
        accessorKey: "full_name",
        header: "Full Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "is_admin",
        header: "Is Admin",
      },
      {
        accessorKey: "is_active",
        header: "Is Active",
      },
      {
        accessorKey: "profile_image_url",
        header: "Profile Image",
      },
      {
        accessorKey: "get_default_chat_context_rid",
        header: "Chat Context",
        Cell: ({ cell }) => getContextTitle(cell.getValue()),
        muiTableBodyCellEditTextFieldProps: {
          select: true,
          children: chatContexts.map((context) => (
            <MenuItem key={context.rid} value={context.rid}>
              {context.title}
            </MenuItem>
          )),
        },
      },
      {
        accessorKey: "force_password_change",
        header: "Pwd Reset",
      },
    ],
    [chatContexts]
  );

  const handleCreateNewRow = (values) => {
    const newUser = {
      login: values.login,
      password: values.password,
      full_name: values.full_name,
      email: values.email,
      is_admin: values.is_admin,
      force_password_change: values.force_password_change,
      default_chat_context_rid: values.get_default_chat_context_rid,
    };
    axios
      .post(baseAPIURL() + `/user`, newUser)
      .then((response) => {
        if (response.status === 201) {
          refetch();
        } else {
          console.error(response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      const updateUser = {
        rid: values.rid,
        login: values.login,
        full_name: values.full_name,
        email: values.email,
        is_admin: values.is_admin === "true",
        force_password_change: values.force_change_password === "true",
        default_chat_context_rid: values.get_default_chat_context_rid,
      };
      axios
        .patch(baseAPIURL() + `/user/${updateUser.rid}`, updateUser)
        .then((response) => {
          if (response.status === 200) {
            refetch();
          } else {
            console.error(response);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      exitEditingMode();
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row) => {
      if (
        !window.confirm(
          `Are you sure you want to delete ${row.getValue("full_name")}?`
        )
      ) {
        return;
      }
      let userData = row.getValue("rid");
      axios
        .delete(baseAPIURL() + `/user/${userData}`, userData)
        .then((response) => {
          if (response.status === 204) {
            refetch();
          } else {
            console.error(response);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [data]
  );

  return (
    <>
      <Box m="20px">
        <Header
          icon={PeopleOutlinedIcon}
          title="Users"
          subtitle="Manage application users"
        />
        <MaterialReactTable
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "center",
              },
              size: 100,
            },
          }}
          columns={columns}
          data={data?.data ?? []}
          initialState={{
            columnVisibility: {
              rid: false,
              force_password_change: false,
              is_admin: false,
              is_active: false,
              profile_image_url: false,
            },
            pagination: { pageSize: 25, pageIndex: 0 },
            showColumnFilters: false,
            density: "compact",
          }}
          enableEditing
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: "error",
                  children: "Error loading data",
                }
              : undefined
          }
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", gap: "0.2rem" }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton
                  sx={{ marginLeft: "10px" }}
                  onClick={() => table.setEditingRow(row)}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          renderTopToolbarCustomActions={() => (
            <>
              <Button
                color="secondary"
                onClick={() => setCreateModalOpen(true)}
                variant="contained"
              >
                <AddCircleOutlineOutlinedIcon
                  sx={{ marginLeft: "-10px", marginRight: "5px" }}
                />
                Add User
              </Button>
              <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isFetching,
            sorting,
          }}
        />
      </Box>
      <UserModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
        chatContexts={chatContexts}
      />
    </>
  );
};

const queryClient = new QueryClient();
const UserTable = () => (
  <QueryClientProvider client={queryClient}>
    <Users />
  </QueryClientProvider>
);
export default UserTable;
