const stateDescriptions = {
  ONLINE: "Furnace Online",
  WAITING: "Waiting",
  TAPPING: "Tapping",
  REFINING: "Refining",
  CASTING: "Casting",
};

function mround(value, multiple) {
  return Math.round(value / multiple) * multiple;
}

function validateNumber(variable, minValue, maxValue) {
  // Check if the variable is a number
  if (!minValue) {
    minValue = 1;
  }
  if (!maxValue) {
    maxValue = 9999;
  }
  if (typeof variable !== "number" || isNaN(variable) || !isFinite(variable)) {
    return false;
  }

  // Check if the number is within the specified range
  if (variable >= minValue && variable <= maxValue) {
    return true;
  } else {
    return false;
  }
}

// Populate furnace state values for a given range
function populateValues(stateArray, furnace, startIndex, count, state) {
  count = Math.round(count);
  startIndex = Math.round(startIndex);
  if (startIndex + count > stateArray.length) {
    count = stateArray.length - startIndex;
  }
  for (let i = startIndex; i < startIndex + count; i++) {
    if (stateArray[i]) {
      if (
        state !== "WAITING" &&
        stateArray[i][furnace] &&
        stateArray[i][furnace] !== "WAITING"
      ) {
        stateArray[i][furnace] = "CONFLICT";
      } else if (!(state === "WAITING" && stateArray[i][furnace])) {
        stateArray[i][furnace] = state;
      }
    }
  }
  return startIndex + count;
}

// Set furnace tapping state

function fcfWait(dataArray, anodeFurnace, startIndex, maxWaitHours) {
  // If Anode furnace is busy, put FCF offline until Anode furnace is free
  while (startIndex < dataArray.length) {
    if (dataArray[startIndex][anodeFurnace] === "WAITING") {
      break;
    }
    dataArray[startIndex]["fcfState"] = "WAITING";
    startIndex++;
  }
  return startIndex;
}

function furnaceFill(
  dataArray,
  furnace,
  startIndex,
  numberOfTaps,
  tappingTime,
  afFillTime
) {
  let i = startIndex;
  let tapsRemaining = numberOfTaps;
  let timeRemaining = afFillTime;
  let waitTime = 0;
  while (tapsRemaining > 0) {
    waitTime = mround(
      (timeRemaining - tapsRemaining * tappingTime) / tapsRemaining,
      0.5
    );
    timeRemaining -= waitTime;
    i = populateValues(dataArray, furnace, i, waitTime * 2, "WAITING");
    i = fcfWait(dataArray, furnace, i, 2.0);
    i = populateValues(dataArray, furnace, i, tappingTime * 2, "TAPPING");
    timeRemaining -= tappingTime;
    tapsRemaining--;
    if (i >= dataArray.length) {
      break;
    }
  }
  return i;
}

const calcSchedule = (parameters) => {
  // Input validation
  if (typeof parameters !== "object" || parameters === null) {
    console.error("Invalid input: Parameters should be a non-null object.");
    return [];
  }

  // Unpack parameters and attempt to convert them to numbers if they are strings
  const {
    numberOfTaps,
    tappingTime,
    timeBetweenTaps,
    refiningTime,
    castTimePerFurnace,
    drainTime,
    afFillTime,
    daysToShow,
  } = Object.fromEntries(
    Object.entries(parameters).map(([key, value]) => [
      key,
      typeof value === "string" ? parseFloat(value) : value,
    ])
  );

  const availableFurnaces = parameters.availableFurnaces;
  const hasDrain = parameters.drain === "Yes";
  const firstFurnace = parameters.firstFurnace;
  const secondFurnace = parameters.secondFurnace;

  const maxIndex = 48 * daysToShow - 1;

  // Initialize the array
  const data = [];

  // Set the fcf furnace state
  for (let i = 0; i <= maxIndex; i++) {
    const halfHour = i * 0.5;
    data.push({
      startHours: halfHour,
      fcfState: "WAITING",
      eastState: "WAITING",
      westState: "WAITING",
      shaftState: "WAITING",
    });
  }

  if (
    !validateNumber(numberOfTaps) ||
    !validateNumber(tappingTime) ||
    !validateNumber(timeBetweenTaps) ||
    !validateNumber(refiningTime) ||
    !validateNumber(castTimePerFurnace) ||
    (hasDrain && !validateNumber(drainTime))
  ) {
    return data;
  }

  // Set furnace refining and casting states
  function furnaceRefineAndCast(furnace, startIndex) {
    startIndex = populateValues(
      data,
      furnace,
      startIndex,
      refiningTime * 2,
      "REFINING"
    );
    startIndex = populateValues(
      data,
      furnace,
      startIndex,
      castTimePerFurnace * 2,
      "CASTING"
    );
    return startIndex;
  }

  let cycleStartIndex = 0;

  populateValues(data, "fcfState", 0, maxIndex + 1, "ONLINE");
  if (["East Only", "West Only"].includes(availableFurnaces)) {
    while (cycleStartIndex <= maxIndex) {
      cycleStartIndex = furnaceFill(
        data,
        firstFurnace,
        cycleStartIndex,
        numberOfTaps,
        tappingTime,
        afFillTime
      );
      if (hasDrain) {
        cycleStartIndex = populateValues(
          data,
          firstFurnace,
          cycleStartIndex,
          drainTime * 2,
          "TAPPING"
        );
      }
      cycleStartIndex = furnaceRefineAndCast(firstFurnace, cycleStartIndex);
    }
    // Set FCF state
    for (let i = 0; i <= maxIndex - 4; i++) {
      if (["REFINING", "CASTING"].includes(data[i + 4][firstFurnace])) {
        data[i]["fcfState"] = "WAITING";
      }
    }
  } else if (availableFurnaces === "East and West") {
    while (cycleStartIndex <= maxIndex) {
      let firstFillEndIndex = furnaceFill(
        data,
        firstFurnace,
        cycleStartIndex,
        numberOfTaps,
        tappingTime,
        afFillTime
      );
      let firstCastEndIndex = furnaceRefineAndCast(
        firstFurnace,
        firstFillEndIndex
      );
      let secondFillEndIndex = furnaceFill(
        data,
        secondFurnace,
        firstFillEndIndex,
        numberOfTaps,
        tappingTime,
        afFillTime
      );
      let secondCastEndIndex = furnaceRefineAndCast(
        secondFurnace,
        secondFillEndIndex
      );
      cycleStartIndex = secondFillEndIndex;
    }
  }

  return data;
};

export default calcSchedule;
