import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { Box, useTheme, Typography, IconButton } from "@mui/material";
import { tokens, collapsedContext, ColorModeContext } from "../../theme";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { UserContext } from "../../Auth.js";
import { OpenInNew } from "@mui/icons-material";
import FileUploadButton from "../../components/UploadButton";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import CloseIcon from "@mui/icons-material/Close";
import { ApiContext } from "../../App";
import ProgressBar from "../../components/subcomponents/progress";

const ContextSelector = ({
  chatContexts,
  activeChatContextRid,
  setActiveChatContextRid,
  fetchChatContexts,
  contextSelectorOpen,
  setContextSelectorOpen,
}) => {
  const theme = useTheme();
  const user = useContext(UserContext);
  const api = useContext(ApiContext);

  let activeChatContext = chatContexts.find(
    (obj) => obj["rid"] === activeChatContextRid
  );

  let canDeleteContext =
    activeChatContext &&
    activeChatContext.removable &&
    (user.is_admin || activeChatContext.owner_rid === user.rid);

  //  Refresh context status every 5 seconds while load is pending
  //  Otherwise refresh every hour
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (chatContexts.some((obj) => obj.upload_status === "PENDING")) {
        fetchChatContexts();
      }
    }, 5000);

    // Clean up function
    return () => {
      clearInterval(intervalId);
    };
  }, [chatContexts]); // Add dependencies here

  const removeDocument = () => {
    // Send a POST request to the server
    api
      .get(`/context_userdocs/remove/` + activeChatContext.rid, {
        headers: {
          Accept: "multipart/form-data",
        },
      })
      .then((response) => {
        // Handle the response from the server
        fetchChatContexts();
      })
      .catch((error) => {
        // Handle the error if the request fails
        console.error(error);
      });
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUpload = (file) => {
    setSelectedFile(file);
    if (file) {
      setIsDialogOpen(true); // Open the modal after selecting a file
    }
  };

  const handleCloseModal = () => {
    setIsDialogOpen(false);
    setSelectedFile(null); // Optional: clear the selected file when modal is closed without action
  };

  const proceedWithUpload = (includeImages) => {
    setIsDialogOpen(false); // Close the modal
    console.log("Include images", includeImages);

    let formData = new FormData();
    formData.append("document", selectedFile);
    formData.append("include_images_str", includeImages.toString());

    // Send a POST request to the server
    api
      .post(`/context_userdocs/upload`, formData, {
        headers: {
          Accept: "multipart/form-data",
        },
      })
      .then((response) => {
        // Handle the response from the server
        fetchChatContexts();
      })
      .catch((error) => {
        // Handle the error if the request fails
        console.error(error);
      });

    setSelectedFile(null); // Reset the selected file after the API call
  };

  function LoadImagesDialog({ open, onYes, onNo }) {
    return (
      <Dialog open={open} onClose={onNo}>
        <DialogTitle>Load Images?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Loading images is generally not recommended unless the document has
            been specifically curated.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onNo} color="primary">
            No
          </Button>
          <Button onClick={onYes} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          mb: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="dialogTitle">
          <span>Available Chat Contexts</span>
        </Typography>
        <IconButton
          onClick={() => setContextSelectorOpen(!contextSelectorOpen)}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <List component={Paper}>
        {chatContexts.map((context) => (
          <ListItemButton
            key={context.rid}
            selected={context.rid === activeChatContextRid}
            onClick={() => setActiveChatContextRid(context.rid)}
            sx={{
              border:
                context.rid === activeChatContextRid
                  ? "2px solid green"
                  : "none",
              borderRadius: "5px",
              // Add any other desired styles
            }}
          >
            {context.owner ? (
              <SummarizeOutlinedIcon />
            ) : (
              <InsightsOutlinedIcon />
            )}
            <Box
              sx={{
                ml: "10px",
              }}
            >
              <ListItemText
                primary={context.title}
                secondary={context.short_description}
              />
              {context.owner ? ( // If this item is ownable
                <>
                  {context.upload_status === "PENDING" ? (
                    <ProgressBar percentage={context.upload_progress} />
                  ) : (
                    <></>
                  )}
                  {context.upload_status === "ERROR" ? (
                    <Typography variant="contextUploadError">
                      <span>Error processing file</span>
                      <br></br>
                    </Typography>
                  ) : (
                    <></>
                  )}
                  <Typography variant="componentProperty">
                    <span>Owned by:</span> {context.owner.login}
                  </Typography>
                  <Typography variant="componentProperty">
                    <span>
                      <br></br>Shared with:
                    </span>{" "}
                    Everyone
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Box>
          </ListItemButton>
        ))}
      </List>
      <Box display="flex" alignItems="center" sx={{ mt: "10px" }}>
        <FileUploadButton uploadHandler={handleUpload} />

        {canDeleteContext ? (
          <Tooltip title="Remove document">
            <IconButton onClick={() => removeDocument()} sx={{ ml: "10px" }}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
      </Box>
      <LoadImagesDialog
        open={isDialogOpen}
        onYes={() => proceedWithUpload(true)}
        onNo={() => proceedWithUpload(false)}
        onRequestClose={handleCloseModal} // Handle modal close request (click outside or ESC key)
      />
    </Box>
  );
};

export default ContextSelector;
