export function filterDataSeriesDefinitions(dataSeriesDefinitions, tags) {
  return dataSeriesDefinitions.filter((dataSeries) => {
    for (const tagKey in tags) {
      if (!dataSeries.tag_values.hasOwnProperty(tagKey)) {
        return false; // DataSeries doesn't have the required tag category
      }

      const tagValues = tags[tagKey];
      const dataSeriesTagValues = dataSeries.tag_values[tagKey];

      if (tagValues.size === 0) {
        continue; // Tag category is present, no values needed
      }

      let foundMatchingValue = false;
      for (const tagValue of tagValues) {
        // Change this line to use 'includes' for arrays
        if (dataSeriesTagValues.includes(tagValue)) {
          foundMatchingValue = true;
          break; // Found a matching tag value for this category
        }
      }

      if (!foundMatchingValue) {
        return false; // No matching tag value for this category
      }
    }

    return true; // All tag categories and values match
  });
}
