import React, { useState } from "react";
import { Typography, Box, Grid, Checkbox, Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { tokens } from "../theme";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3da58a", // replace hexColor with your color of choice
    },
    secondary: {
      main: "#3da58a", // replace hexColor with your color of choice
    },
  },
});

export default function SignIn({ setAcessJwt }) {
  const colors = tokens(theme.palette.mode);

  const [error, setError] = useState();
  const [processing, setProcessing] = useState();
  const [staySignedIn, setStaySignedIn] = useState(
    localStorage.getItem("lastUserLogin") ? true : false
  );

  const handleChangeStaySignedIn = (event) => {
    setStaySignedIn(event.target.checked);
  };

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!staySignedIn) {
      localStorage.removeItem("lastUserLogin");
    }
    let data = new FormData(event.currentTarget);
    data.append("username", data.get("login"));
    data.append("password", data.get("password"));
    setProcessing(true);

    // Send a POST request to the server
    let API_URL = `${window.location.protocol}//${window.location.hostname}:8000`;

    axios
      .post(API_URL + `/auth/token`, data)
      .then((response) => {
        // Handle the response from the server
        setAcessJwt(response.data.access_token);
        if (staySignedIn) {
          localStorage.setItem("accessJwt", response.data.access_token);
          localStorage.setItem("refreshJwt", response.data.refresh_token);
          sessionStorage.removeItem("accessJwt");
          sessionStorage.removeItem("refreshJwt");
          localStorage.setItem("lastUserLogin", data.get("login"));
        } else {
          sessionStorage.setItem("accessJwt", response.data.access_token);
          sessionStorage.setItem("refreshJwt", response.data.refresh_token);
          localStorage.removeItem("accessJwt");
          localStorage.removeItem("refreshJwt");
          localStorage.removeItem("lastUserLogin");
        }
        setProcessing(false);

        navigate("/");
      })
      .catch((error) => {
        // Handle the error if the request fails
        console.error(error);
        if (error.message === "Network Error") {
          setError("Network error");
        } else {
          sessionStorage.removeItem("accessJwt");
          localStorage.removeItem("accessJwt");
          sessionStorage.removeItem("refreshJwt");
          localStorage.removeItem("refreshJwt");
          localStorage.removeItem("lastUserLogin");
          setError("Invalid username or password");
        }
        setProcessing(false);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="login"
              label="Username"
              name="login"
              autoComplete="email"
              autoFocus={localStorage.getItem("lastUserLogin") ? false : true}
              defaultValue={localStorage.getItem("lastUserLogin")}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoFocus={localStorage.getItem("lastUserLogin") ? true : false}
              autoComplete="current-password"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={staySignedIn}
                  onChange={handleChangeStaySignedIn}
                  value="remember"
                  color="primary"
                />
              }
              label="Stay signed in on this device for 30 days"
            />
            {error ? (
              <Typography
                variant="errorMessage"
                fontSize="16px"
                sx={{ display: "block" }}
                color={colors.redAccent[500]}
              >
                {error}
              </Typography>
            ) : null}

            <Button
              type="submit"
              fullWidth
              color="primary"
              disabled={processing}
              variant="contained"
              sx={{ mt: 3, mb: 2, pt: 1.5, pb: 1.5 }}
            >
              {processing ? <>Authenticating...</> : <>Sign In</>}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
