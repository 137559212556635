import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
// Define colors for each state

const stateColors = {
  ONLINE: "rgba(196,32,32,255)", // Red
  WAITING: "rgba(127,127,127,0.1)", // Transparent
  TAPPING: "#FFC107", // Amber"#FF5722", // Deep Orange
  REFINING: "#2196F3", //Blue "#9C27B0", // Purple
  CASTING: "rgba(32,196,32,255)", // Blue
  CONFLICT: "rgba(255,0,0,255)", // Bright Red
};

const stateDescriptions = {
  ONLINE: "Furnace Online",
  WAITING: "Waiting",
  TAPPING: "Tapping",
  REFINING: "Refining",
  CASTING: "Casting",
  CONFLICT: "Conflict",
};

const furnaceDescriptions = {
  fcfState: "FCF",
  eastState: "East Anode",
  westState: "West Anode",
  shaftState: "Shaft",
};

const FurnaceScheduleDisplay = ({
  scheduleData,
  setScheduleData,
  editable,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const [labelInterval, setLabelInterval] = useState(2);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 600) {
        setLabelInterval(8);
      } else if (screenWidth < 1100) {
        setLabelInterval(4);
      } else {
        setLabelInterval(2);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClick = (event, furnaceType, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedCell({
      furnaceType,
      index,
      currentState: scheduleData[index][furnaceType],
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedCell(null);
  };

  const handleChangeState = (newState) => {
    if (!selectedCell) return;
    const newData = [...scheduleData];
    newData[selectedCell.index][selectedCell.furnaceType] = newState;
    setScheduleData(newData);
    handleClose();
  };

  const days = Math.ceil(scheduleData.length / 48);

  const getFormattedTime = (index) =>
    `${String(Math.floor(index / 2)).padStart(2, "0")}:${
      index % 2 === 0 ? "00" : "30"
    }`;

  return (
    <Box sx={{}}>
      {Array.from({ length: days }).map((_, dayIndex) => (
        <Box
          key={`day-${dayIndex}`}
          sx={{
            pl: "1em",
            pr: "1em",
            mt: "0.1em",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Customize the shadow as needed
          }}
        >
          <Typography variant="h5" sx={{ mt: 2.5, mb: -0, fontWeight: "bold" }}>
            Day {dayIndex + 1}
          </Typography>
          <Grid container>
            <Grid item xs={1} />
            {Array.from({ length: 48 }, (_, i) =>
              i % labelInterval === 0 ? (
                <Grid
                  item
                  xs
                  key={`time-label-${i}`}
                  sx={{ position: "relative" }}
                >
                  <Typography
                    sx={{ borderLeft: "solid 1px", pl: "2px" }}
                    variant="caption"
                  >
                    {getFormattedTime(i)}
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs key={`time-label-${i}`} />
              )
            )}
          </Grid>
          {["fcfState", "eastState", "westState", "shaftState"].map(
            (furnace, furnaceIndex) => (
              <Grid container key={`furnace-${furnaceIndex}`}>
                <Grid item xs={1}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "medium" }}>
                    {furnaceDescriptions[furnace]}
                  </Typography>
                </Grid>
                {scheduleData
                  .slice(dayIndex * 48, (dayIndex + 1) * 48)
                  .map((entry, entryIndex) => (
                    <Grid
                      item
                      xs
                      key={`entry-${furnaceIndex}-${entryIndex}`}
                      sx={{
                        minWidth: 5,
                        maxWidth: 20,
                      }}
                    >
                      <Tooltip
                        title={`${getFormattedTime(
                          entryIndex
                        )} to ${getFormattedTime(entryIndex + 1)} : ${
                          furnaceDescriptions[furnace]
                        } - ${stateDescriptions[entry[furnace]]}`}
                        sx={{ fontSize: "2em" }} // Increased tooltip font size
                        placement="top"
                        followCursor={true}
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, 10],
                                },
                              },
                            ],
                          },
                        }}
                        arrow
                      >
                        <Paper
                          elevation={0}
                          sx={{
                            height: 20,
                            bgcolor: stateColors[entry[furnace]],
                            borderRadius: 0,
                            borderLeft: "0.2px solid",
                            borderRight: "0.2px solid",
                            borderTop: "0px solid",
                            borderBottom: "0px solid",
                            borderColor: theme.palette.background.default, // Dynamically use the background color from theme
                            elevation: 0, // Set paper elevation to 0
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            "&:hover": {
                              cursor: editable ? "pointer" : "default",
                              opacity: 0.7,
                            },
                          }}
                          onClick={(event) =>
                            handleClick(
                              event,
                              furnace,
                              dayIndex * 48 + entryIndex
                            )
                          }
                        >
                          {entry[furnace] === "CONFLICT" && (
                            <Typography
                              sx={{
                                color: "black",
                                fontSize: "1rem",
                                fontWeight: "bold",
                              }}
                            >
                              !
                            </Typography>
                          )}
                        </Paper>
                      </Tooltip>
                    </Grid>
                  ))}
              </Grid>
            )
          )}
        </Box>
      ))}
      {editable ? (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {selectedCell &&
            Object.entries(stateDescriptions).map(
              ([stateKey, _]) =>
                stateKey !== selectedCell.currentState && (
                  <MenuItem
                    key={stateKey}
                    onClick={() => handleChangeState(stateKey)}
                    sx={{
                      bgcolor: stateColors[stateKey],
                      color: theme.palette.getContrastText(
                        stateColors[stateKey]
                      ),
                      "&:hover": {
                        bgcolor: `${stateColors[stateKey]}bb`, // Adding opacity to the background color on hover
                      },
                    }}
                  >
                    Change to {stateKey}
                  </MenuItem>
                )
            )}
        </Menu>
      ) : null}
      <Box
        sx={{
          mt: 2,
          mb: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {Object.entries(stateColors).map(([state, color]) => (
          <Box
            key={state}
            sx={{ display: "flex", alignItems: "center", mr: 2 }}
          >
            <Paper
              sx={{
                width: 20,
                height: 20,
                bgcolor: color,
                mr: 0.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {state === "CONFLICT" && (
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  !
                </Typography>
              )}
            </Paper>
            <Typography variant="body2">{stateDescriptions[state]}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FurnaceScheduleDisplay;
