import React, { Fragment, useRef, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import {
  Box,
  useTheme,
  Button,
  TextField,
  TextareaAutosize,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  LinearProgress,
  Typography,
  Tooltip,
} from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

function TipWidget({ sx }) {
  const [theme, colorMode] = useMode();
  return (
    <Box
      sx={{
        ...sx,
        display: "flex", // Flexbox display
        alignItems: "center", // Vertically center the content
        gap: 1, // Optional: Add some gap between elements
      }}
    >
      <Tooltip
        title="Tip: Quickly pivot this data by selecting 'Resample' from the 'Parameters' drop-down above"
        arrow
      >
        <IconButton aria-label="help-tip">
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
      <Typography
        sx={{
          ml: "-11px",
          mb: "0px",
          pb: "0px",
          color: theme.palette.neutral.dark,
        }}
      >
        How to pivot
      </Typography>
    </Box>
  );
}

function TableFigure({ tableData, tableColumns, tableConfig }) {
  const [theme, colorMode] = useMode();
  let tableColumnsCopy;

  if (tableConfig == null || tableColumns == null || tableData == null) {
    return (
      <Typography variant="body1" color="textSecondary">
        No data available
      </Typography>
    );
  }
  if (tableColumns != null) {
    tableColumnsCopy = JSON.parse(JSON.stringify(tableColumns));
  } else {
    tableColumnsCopy = [];
  }
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showColumnHeaders: true, // Determines if columns should have headers. When set to false, the first row of the CSV will be data.
    useBom: true,
    useKeysAsHeaders: false,
    columnHeaders: tableColumnsCopy.map((c) => c.accessorKey),
  };

  const handleExportRows = (rows) => {
    console.log(tableColumnsCopy);
    console.log(rows);
    const csv = generateCsv(csvOptions)(rows.map((row) => row.original));
    download(csvOptions)(csv);
  };

  return (
    <Box
      sx={{
        mt: "20px",
        mb: "20px",
        width: tableConfig?.tableWidthFitContent ? "fit-content" : "100%",
      }}
    >
      <MaterialReactTable
        renderTopToolbarCustomActions={
          tableConfig?.allowExportToCSV
            ? ({ table }) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: "0.5rem",
                    flexWrap: "wrap",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: theme.palette.secondary.main,
                      borderRadius: "4px",
                      color: theme.palette.getContrastText(
                        theme.palette.secondary.main
                      ),
                      fontSize: "12px", // Set font size
                      "&:hover": {
                        backgroundColor: theme.palette.secondary.main, // Set hover background color
                      },
                    }}
                    disabled={
                      table.getPrePaginationRowModel().rows.length === 0
                    }
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() =>
                      handleExportRows(table.getPrePaginationRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
                    Download CSV
                  </Button>
                  <Typography
                    sx={{
                      ml: "10px",
                      mb: "6px",
                      pb: "0px",
                      color: theme.palette.neutral.dark,
                    }}
                  >
                    {table.getRowModel().rows.length < tableData.length
                      ? `${new Intl.NumberFormat().format(
                          table.getRowModel().rows.length
                        )} filtered rows`
                      : `${new Intl.NumberFormat().format(
                          tableData.length
                        )} total rows`}
                  </Typography>
                  {tableConfig?.showPivotTip ? (
                    <TipWidget
                      sx={{
                        ml: "10px",
                        mt: "4px",
                        mb: "-2px",
                      }}
                    />
                  ) : null}
                </Box>
              )
            : null
        }
        columns={tableColumnsCopy}
        data={tableData}
        enableColumnResizing={tableConfig?.enableColumnResizing ? true : false}
        enableColumnFilterModes={
          tableConfig?.enableColumnFilterModes ? true : false
        }
        enableColumnActions={tableConfig?.enableColumnActions ? true : false}
        enableColumnFilters={tableConfig?.enableColumnFilters ? true : false}
        enableColumnOrdering={tableConfig?.enableColumnOrdering ? true : false}
        enableDensityToggle={tableConfig?.enableDensityToggle ? true : false}
        enableGlobalFilter={tableConfig?.enableGlobalFilter ? true : false}
        enablePagination={tableConfig?.enablePagination ? true : false}
        enableSorting={tableConfig?.enableSorting ? true : false}
        enableBottomToolbar={tableConfig?.enableBottomToolbar ? true : false}
        enableTopToolbar={tableConfig?.enableTopToolbar ? true : false}
        enableRowVirtualization={
          tableConfig?.enableRowVirtualization ? true : false
        }
        enableColumnVirtualization={
          tableConfig?.enableColumnVirtualization ? true : false
        }
        muiTableContainerProps={{
          sx: {
            maxHeight: "50vh",
            borderColor:
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.12)"
                : "rgba(255, 255, 255, 0.14)",
            borderWidth: "1px",
            borderStyle: "solid",
          },
        }}
        initialState={{
          density: "compact",
        }}
        muiTableProps={{
          sx: {
            color:
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.12)"
                : "rgba(255, 255, 255, 0.04)",
          },
        }}
        muiTablePaperProps={{
          elevation: 0,
        }}
        muiTableBodyProps={{
          sx: tableConfig?.columnStripeShading
            ? {
                "& td:nth-of-type(odd)": {
                  // targets every other cell in each row, effectively styling columns
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.01)" // slightly darker for a better contrast ratio
                      : "rgba(255, 255, 255, 0.01)",
                },
                "& tr:hover": {
                  // Apply a style for all cells in a row on hover.
                  "& td": {
                    backgroundColor: theme.palette.action.hover, // or any other color you prefer
                  },
                },
              }
            : null,
        }}
        muiTableHeadCellProps={{
          sx: (theme) => ({
            background:
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.1)"
                : "rgba(255, 255, 255, 0.08)",
            borderRight: "1px solid ",
            borderRightColor:
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.1)"
                : "rgba(255, 255, 255, 0.2)",
            color: theme.palette.text.primary,
          }),
        }}
      />
    </Box>
  );
}

export default TableFigure;
