import React, { useRef, useState, useEffect } from "react";
import Plot from "react-plotly.js";
import { Typography } from "@mui/material";

function PlotlyFigure({ plotlyToJsonStr }) {
  if (plotlyToJsonStr == null) {
    return (
      <Typography variant="body1" color="textSecondary">
        No data available
      </Typography>
    );
  }
  let plotlyToJson = JSON.parse(plotlyToJsonStr);

  return (
    <Plot
      data={plotlyToJson.data}
      layout={plotlyToJson.layout}
      useResizeHandler={true}
      style={{ width: "100%", height: "100%" }}
    />
  );
}

export default PlotlyFigure;
