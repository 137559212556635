import React, { Fragment, useRef, useEffect, useContext } from "react";
import {
  Box,
  useTheme,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  LinearProgress,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssistantIcon from "@mui/icons-material/Assistant";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { tokens } from "../../theme";
import { CircularProgress } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ActionReturnFigure from "../../components/subcomponents/RenderActionReturnFigure";
import LoadingIndicator from "../../components/subcomponents/LoadingIndicator/LoadingIndicator";
import QuantaAvatar from "../../assets/logo/quanta_i_svg.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blue, lightBlue } from "@mui/material/colors";

import { CollapsedContext } from "../../App.js";
import ImageWithShadow from "../../components/subcomponents/ImageWithShadow";

const userTheme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: lightBlue[300],
    },
  },
});

const adjustImageSize = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  const images = div.getElementsByTagName("img");
  for (let img of images) {
    img.style.maxWidth = "100px";
    img.style.height = "auto";
  }
  return div.innerHTML;
};

const ChatDisplay = ({
  sx,
  sessionId,
  chatHistory,
  pendingResponse,
  showThoughts,
  showFullPrompt,
  isWaiting,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const collapsedContext = useContext(CollapsedContext);

  const isCollapsedState = collapsedContext.isCollapsed;

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, pendingResponse]);

  let displayList = [];
  if (isWaiting && pendingResponse) {
    displayList = [...chatHistory, pendingResponse];
  } else {
    displayList = chatHistory;
  }

  // Custom renderer for images in ReactMarkdown
  const renderers = {
    img: ({ alt, src, title }) => (
      <img
        alt={alt}
        src={src}
        title={title}
        style={{ maxWidth: "800px", maxHeight: "550px", height: "auto" }}
      />
    ),
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: "0px",
        maxWidth: isCollapsedState
          ? "calc(100dvw - 150px)"
          : "calc(100dvw - 310px)",
        height: "100%",
        minHeight: displayList.length > 0 ? "100dvh" : null,
        mb: "0px",
        mt: "0px",
        ...sx,
      }}
    >
      <List
        className="chat"
        sx={{
          flexGrow: 1,
          minWidth: "0px",
          overflowY: "auto",
          pb: "150px",
        }}
      >
        {displayList.map((item, index) => (
          <ListItem
            key={index}
            sx={{
              display: "flex",
              justifyContent: item.type === "user" ? "flex-end" : "flex-start", // Right align for user messages, left align for others
              alignItems: "flex-start",
              pl: "10px",
              pr: "10px",
              ...(item.type === "user"
                ? {
                    pt: "40px",
                  }
                : {}),
              ...(item.type === "user" && index > 1
                ? {
                    mt: "40px", // Top margin
                    borderTop: `1px solid ${colors.grey[800]}`, // Light gray border on top
                  }
                : {}),
            }}
          >
            <ListItemAvatar>
              {/* Show Error state assistant avatar icon */}
              {item.type !== "user" &&
                item.error &&
                (!isWaiting || index != displayList.length - 1) && (
                  <Avatar
                    sx={{
                      mt: "10px",
                      ml: "10px",
                      mr: "20px",
                      backgroundColor: colors.redAccent[500],
                    }}
                  >
                    <AssistantIcon />
                  </Avatar>
                )}

              {/* Show normal assistant avatar icon */}
              {item.type !== "user" &&
                !item.error &&
                (!isWaiting || index != displayList.length - 1) && (
                  //  Box for circular shaded background
                  <Box
                    sx={{
                      borderRadius: "30%", // Makes the Box circular
                      background:
                        theme.palette.mode === "light"
                          ? "linear-gradient(-45deg, rgba(235,242,238,1) 30%, rgba(255,255,255,1) 90%)" // Light mode gradient
                          : "linear-gradient(-45deg, rgba(64,192,127,0.05) 30%, rgba(32,96,64,0.0) 90%)", // Dark mode gradient

                      padding: "10px", // Adjusts the space between the content and the border of the Box
                      display: "inline-flex", // Ensures the Box is only as big as its content
                      justifyContent: "center",
                      alignItems: "center",
                      height: "45px",
                      width: "45px",
                      marginLeft: "-5px",
                      marginTop: "11px",
                      borderColor: "rgba(64,192,128,0.5)",
                      borderWidth: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    <ImageWithShadow
                      imgProps={{
                        style: {
                          width: "41px",
                          height: "auto",
                          marginRight: "0px",
                          marginLeft: "-8px",
                          marginTop: "30px",
                          marginBottom: "0px",
                          top: 0,
                          left: 0,
                          filter:
                            theme.palette.mode === "light"
                              ? "brightness(0.7)"
                              : "brightness(1.0)",
                        },
                        src: QuantaAvatar,
                        alt: "Avatar",
                      }}
                      top="0px"
                      right="0px"
                      brightness="1.3"
                      grayscale="0%"
                      blur={theme.palette.mode === "light" ? "1px" : "0px"}
                    />
                  </Box>
                )}

              {/* Show all system response block types */}
            </ListItemAvatar>
            <Box
              className="ChatDisplaySystemResponseListItemBox"
              sx={{
                display: "flex",
                flexDirection: "column",
                minWidth: "0px",

                ...(item.type !== "user"
                  ? {
                      width: "100%",
                    }
                  : {}),
              }}
            >
              {item.type !== "user" && (
                <Box
                  sx={{
                    backgroundColor: "rgba(127,127,127,0.05)",
                    borderRadius: "14px",
                    pl: "20px",
                    pr: "20px",
                    pt: "10px",
                    pb: "20px",
                    mr: "60px",
                    pr: "20px",
                    // Tail Styling
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      top: "32px", // Tail vertical adjustment
                      left: "46px", // Tail horizontal adjustment
                      borderWidth: "10px",
                      borderStyle: "solid",
                      borderColor: `transparent  rgba(127,127,127,0.05) transparent transparent `, // Right pointing tail
                    },
                  }}
                >
                  {/* Show debug prompt */}
                  {showFullPrompt && (
                    <ListItemText
                      sx={{
                        color: colors.grey[200],
                      }}
                    >
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={renderers}
                      >
                        {item.fullPrompt}
                      </ReactMarkdown>
                    </ListItemText>
                  )}

                  {/* Show chain of thought */}
                  {showThoughts && (
                    <ListItemText
                      sx={{
                        color: colors.primary[200],
                      }}
                    >
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={renderers}
                      >
                        {item.thought}
                      </ReactMarkdown>
                    </ListItemText>
                  )}

                  {/* Show error message */}
                  {item.error && (
                    <ListItemText
                      ref={messagesEndRef}
                      sx={{
                        color: colors.redAccent[200],
                        "& .chat": { fontWeight: "bold" },
                      }}
                    >
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={renderers}
                        className="chat"
                      >
                        {item.error}
                      </ReactMarkdown>
                    </ListItemText>
                  )}

                  {/* Show assistant response */}

                  {/*  Show agent selected, if context is multi-agent */}
                  {item.figures &&
                    item.figures
                      .filter((figure) => figure.type === "PILL_STATIC")
                      .map((figure, index) => (
                        <ActionReturnFigure
                          sessionId={sessionId}
                          key={index}
                          figure={figure}
                        />
                      ))}

                  {item.text && (
                    <ListItemText
                      ref={messagesEndRef}
                      sx={{
                        color: colors.primary[200],
                        "& .chat": { fontWeight: "400" },
                      }}
                    >
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={renderers}
                        className="chat"
                      >
                        {item.text}
                      </ReactMarkdown>
                    </ListItemText>
                  )}

                  {item.figures &&
                    item.figures
                      .filter((figure) => figure.type !== "PILL_STATIC")
                      .map((figure, index) => (
                        <ActionReturnFigure
                          sessionId={sessionId}
                          key={index}
                          figure={figure}
                        />
                      ))}

                  {/* Show progress messages */}
                  {isWaiting && index == displayList.length - 1 && (
                    <Fragment>
                      <ListItemText sx={{ color: colors.greenAccent[200] }}>
                        <ReactMarkdown className="chat">
                          {item.progressMsg
                            ? item.progressMsg
                            : "Communicating with Agent"}
                        </ReactMarkdown>
                      </ListItemText>
                      <LoadingIndicator></LoadingIndicator>
                      <LinearProgress
                        ref={messagesEndRef}
                        sx={{
                          width: "200px", // Set the fixed width here
                          display: "block", // Make sure the progress bar is a block-level element
                          marginTop: "-10px", // Add some margin if needed
                          backgroundColor: "rgba(0, 0, 0, 0.1)", // Change the track color
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: colors.greenAccent[400], // Change the progress bar color
                          },
                        }}
                        variant="determinate"
                        value={item.progressPcnt}
                      />
                    </Fragment>
                  )}
                </Box>
              )}
              {item.type === "user" && (
                <ListItemText
                  sx={{
                    whiteSpace: "pre-wrap",
                    pl: "15px",
                    pr: "15px",
                    mr: "0px",
                    borderRadius: "16px",
                    borderBottomRightRadius: "16px",
                    background:
                      item.type === "user"
                        ? `linear-gradient(to right, ${blue[600]},  ${blue[300]})`
                        : "inherit", // Set the background color based on item.type
                    color: colors.primary[400],
                    // Tail Styling
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      top: "62px", // Tail vertical adjustment
                      right: "51px", // Tail horizontal adjustment
                      borderWidth: "10px",
                      borderStyle: "solid",
                      borderColor: `transparent transparent transparent ${blue[300]}`, // Right pointing tail
                    },
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: adjustImageSize(item.text),
                    }}
                    className="chatUserMessage"
                    style={{
                      display: "block",
                      "& img": {
                        maxWidth: "100px",
                        height: "auto",
                      },
                    }}
                  />
                </ListItemText>
              )}
            </Box>
            {item.type === "user" && (
              <>
                <Box
                  sx={{
                    borderRadius: "30%", // Makes the Box circular
                    background:
                      theme.palette.mode === "light"
                        ? "linear-gradient(-45deg, rgba(230,240,245,1) 30%, rgba(255,255,255,1) 90%)" // Light mode gradient
                        : "linear-gradient(225deg, rgba(240,240,255,0.00) 30%, rgba(250,250,255,0.1) 90%)", // Dark mode gradient

                    padding: "10px", // Adjusts the space between the content and the border of the Box
                    display: "inline-flex", // Ensures the Box is only as big as its content
                    justifyContent: "center",
                    alignItems: "center",
                    height: "45px",
                    width: "45px",
                    marginLeft: "15px",
                    marginTop: "9px",
                    borderColor: "rgba(64,128,192,0.5)",
                    borderWidth: "1px",
                    borderStyle: "solid",
                  }}
                >
                  <PersonRoundedIcon
                    sx={{
                      mt: "1px",
                      ml: "2px",
                      mr: "0px",
                      fontSize: "2.3rem",
                      color: blue[400], // Adjust the shade of grey as needed
                    }}
                  />
                </Box>
              </>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
export default ChatDisplay;
