import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Switch,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import Button from "@mui/material/Button";

// MUI dialog modal for creating or editing a user
export const UserModal = ({
  open,
  columns,
  onClose,
  onSubmit,
  chatContexts,
}) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {})
  );
  const [isAdmin, setIsAdmin] = useState(false);
  const [pwdReset, setPwdReset] = useState(true);

  const [selectedContext, setSelectedContext] = useState(
    values.get_default_chat_context_rid
  );

  const isAdminHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.checked });
    setIsAdmin(!isAdmin);
  };
  const pwdResetHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.checked });
    setPwdReset(!pwdReset);
  };

  const handleContextChange = (e) => {
    setSelectedContext(e.target.value);
    setValues({ ...values, get_default_chat_context_rid: e.target.value });
  };
  const handleSubmit = () => {
    // add validation logic here
    // Update the values array with the isAdmin and pwdReset values
    const updatedValues = {
      ...values,
      is_admin: isAdmin,
      force_password_change: pwdReset,
    };
    setValues(updatedValues);

    if (validateEmail(values.email)) {
      onSubmit(updatedValues);
      onClose();
    } else {
      //TODO: Add validation feedback in UI
    }
  };
  const validateRequired = (value) => !!value.length;
  const validateEmail = (email) =>
    !!email.length &&
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center" sx={{ fontSize: "2rem" }}>
        Add New User
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            <TextField
              key="login"
              label="Login Name"
              name="login"
              required
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
            />
            <TextField
              key="full_name"
              label="Full Name"
              name="full_name"
              required
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
            />
            <TextField
              key="email"
              label="Email Address"
              name="email"
              required
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
            />
            <TextField
              key="password"
              label="Password"
              name="password"
              required
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
            />

            <FormControl fullWidth>
              <InputLabel id="chat-context-label">Chat Context</InputLabel>
              <Select
                labelId="chat-context-label"
                id="chat-context"
                value={selectedContext}
                label="Chat Context"
                onChange={handleContextChange}
              >
                {chatContexts.map((context) => (
                  <MenuItem key={context.rid} value={context.rid}>
                    {context.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack
              direction="row"
              sx={{
                width: "100%",
                minWidth: { xs: "300px", sm: "360px", md: "400px" },
                gap: "1.5rem",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h5">
                <AdminPanelSettingsOutlinedIcon
                  sx={{ marginRight: "8px", verticalAlign: "text-bottom" }}
                />
                Administrator Permissions
              </Typography>
              <Switch
                key={"is_admin"}
                name={"is_admin"}
                color={"secondary"}
                checked={isAdmin}
                value={isAdmin}
                onChange={isAdminHandler}
              />
            </Stack>
            <Stack
              direction="row"
              sx={{
                width: "100%",
                minWidth: { xs: "300px", sm: "360px", md: "400px" },
                gap: "1.5rem",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h5">
                <PasswordOutlinedIcon
                  sx={{ marginRight: "8px", verticalAlign: "text-bottom" }}
                />
                Force password change
              </Typography>
              <Switch
                key={"force_password_change"}
                name={"force_password_change"}
                color={"secondary"}
                checked={pwdReset}
                value={pwdReset}
                onChange={pwdResetHandler}
              />
            </Stack>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
