import React, { useMemo, useState, useContext, useRef, useEffect } from "react";
import {
  TextField,
  Autocomplete,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { AllTagsContext, DataSeriesListContext } from "../../App.js";
import { useImmer } from "use-immer";
import { filterDataSeriesDefinitions } from "./metadataFunctions.jsx";
import TagSelector from "./TagSelector.jsx";
import { ActiveChatContextRidContext } from "../../scenes/chat/index.jsx";

const DataSeriesSelector = ({
  defaultTags,
  activeChatContextRid,
  handleAddDataseries,
}) => {
  const textFieldRef = useRef();

  const [filter, setFilter] = useState("");

  const [dataseries, setDataseries] = useImmer(null);

  const dataSeriesList = useContext(DataSeriesListContext);
  const allTags = useContext(AllTagsContext);
  const [selectedTags, setSelectedTags] = useImmer({});

  const onUpdateTags = (newTags) => {
    setSelectedTags((draft) => newTags);
    setDataseries((draft) => null);
  };

  const filteredDataSeriesList = useMemo(
    () => filterDataSeriesDefinitions(dataSeriesList, selectedTags),
    [dataSeriesList, selectedTags]
  );
  const dataSeriesOptions = filteredDataSeriesList.map((item) => ({
    system_sk: item.system_sk,
    descr: item.path_descr
      ? item.path_descr + " (" + item.user_sk + ")"
      : item.user_sk,
  }));

  const emptyDataseries = [
    {
      system_sk: "",
      user_sk: "None selected",
    },
  ];

  const addDataseries = (dataseries) => {
    handleAddDataseries(dataseries.system_sk);
  };

  return (
    <Box
      sx={{
        mb: "40px",
        mt: "10px",
        borderTop: "1px",
        borderTopColor: "rgba(127,127,127,0.2)",
        borderTopStyle: "solid",
        paddingTop: "20px",
      }}
    >
      <Typography variant="h5" fontWeight={"bold"}>
        Add data series
      </Typography>
      <Box
        sx={{
          mb: "40px",
          mt: "20px",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "left",
          gap: 2,
        }}
      >
        <TagSelector
          allTags={allTags[activeChatContextRid]}
          selectedTags={selectedTags}
          onUpdateTags={onUpdateTags}
        />
        <Autocomplete
          id="dataseries-select"
          options={dataSeriesOptions ? dataSeriesOptions : emptyDataseries}
          value={dataseries}
          onChange={(event, newValue) => setDataseries((draft) => newValue)}
          //groupBy={(option) => option.data_source}
          size="small"
          isOptionEqualToValue={(option, value) =>
            option.system_sk === value.system_sk
          }
          getOptionLabel={(option) => option.descr}
          renderInput={(params) => (
            <TextField {...params} label="Search for Data Series" />
          )}
        />
        <IconButton
          color="primary"
          aria-label="add data series"
          onClick={() => addDataseries(dataseries)}
          sx={{
            ml: "3px",
            width: "20px",
            "&:hover": { color: "secondary.main" },
          }}
          disabled={!dataseries} // disable the button if dataset or dataseries is null
        >
          <AddBoxIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DataSeriesSelector;
