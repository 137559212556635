import { ColorModeContext, useMode} from './theme';
import { createContext, useState, useRef, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Form from "./scenes/form";
import Login from "./components/Login";
import PasswordReset from "./components/PasswordReset";
import GetUser from "./components/GetUser";
import App from './App';
import { getAccessToken, clearAccessToken, clearRefreshToken } from './lib/api'

export const UserContext = createContext({});

function Auth() {
  const storedAccessJwt = getAccessToken();
  const [accessJwt, setAccessJwt] = useState(storedAccessJwt || null);
  const [user, setUser] = useState( );

  const signout = () => {
    clearAccessToken();
    clearRefreshToken()
    localStorage.removeItem('lastUserLogin')
    setUser( null )
    setAccessJwt( null )
  }

  //  If we have a valid token, but no user info, try to get the user info
  if (accessJwt && !user){
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login setAcessJwt={setAccessJwt}/>} />
          <Route path="/*" element={<GetUser signout={signout} setUser={setUser} />} />
        </Routes>
      </BrowserRouter>
    )  
  }

  //  If we have no access token, always route to Login page
  if (!accessJwt) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login setAcessJwt={setAccessJwt}/>} />
          <Route path="/*" element={<Navigate to="/login" replace />} />
        </Routes>
      </BrowserRouter>
    )
  }

  // Check if the user is flagged for a password reset.  They will get directed to a form to set a new password.
  if (user.force_password_change){
    return (
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<PasswordReset user={user}/>} />
          </Routes>
        </BrowserRouter>
    )
  }

  //  We have both an access token (which may or may not be expired) and user info
  //  Proceed to app
  //  If access token is not valid, the first failed API call will kick the user
  //  back to the Login page
  return (
    
  <BrowserRouter>
    <UserContext.Provider value = {user}>
        <App signout={signout}></App>
    </UserContext.Provider>
  </BrowserRouter>
  );
}

export default Auth;
