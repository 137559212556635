import { Typography, Box, useTheme, IconButton, SvgIcon} from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../theme";
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { CollapsedContext } from "../App.js";


const Header = ({icon, title, subtitle}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const collapsedContext = useContext( CollapsedContext );

  const IconImage = icon;
  return (
    <Box 
      justifyContent="space-between" 
      display="flex">
      <Box mb="30px" 
        display="inline-flex"
        flexDirection= 'column'
        justifyContent="flex-start" >

        <Typography 
          display="block"
          variant="h2" 
          color={colors.grey[100]} 
          fontWeight="bold" 
          sx={{  mb: "5px"}}>
            <SvgIcon sx={{  mr: "10px", verticalAlign: 'middle', color: colors.greenAccent[500]}} fontSize="large" component={icon}  />
          {title}
        </Typography>
        
        <Typography variant="h5"
          color={colors.greenAccent[400]}>
          {subtitle}
        </Typography>
      </Box>

      <Box display="inline-flex">
        <IconButton sx={{  height:"40px", mr: "10px", verticalAlign: 'top'}} onClick={() => collapsedContext.setCollapsed(! collapsedContext.isCollapsed)}>
                <MenuOutlinedIcon />
              </IconButton>
        <IconButton  sx={{  height:"40px", mr: "10px", verticalAlign: 'top'}} onClick={colorMode.toggleColorMode}>
        {theme.palette.mode === 'dark' ? (
          <DarkModeOutlinedIcon/>
        ) : (
          <LightModeOutlinedIcon/>
        )}
        </IconButton>
      </Box>
    </Box>
  );
}

export default Header;