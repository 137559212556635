import React, { useState, Fragment } from "react";
import {
  Typography,
  Box,
  useTheme,
  Grid,
  Checkbox,
  Button,
  IconButton,
  SvgIcon,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ColorModeContext, useMode, tokens } from "../theme";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      Copyright {new Date().getFullYear()} © facilis.ai '
    </Typography>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#3da58a", // replace hexColor with your color of choice
    },
    secondary: {
      main: "#3da58a", // replace hexColor with your color of choice
    },
  },
});

export default function SignIn(props, { setAcessJwt }) {
  const colors = tokens(theme.palette.mode);

  const [error, setError] = useState();
  const [processing, setProcessing] = useState();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let pwd1 = formData.get("newPassword1");
    let pwd2 = formData.get("newPassword2");

    setProcessing(true);

    // This password validation logic should be improved
    if (pwd1 !== pwd2) {
      setError("Passwords do not match");
      setProcessing(false);
    } else if (pwd1.length < 8) {
      setError("Password must be at least 8 characters");
      setProcessing(false);
    } else {
      const data = {
        rid: props.user.rid,
        password: pwd1,
        force_change_password: false,
      };
      // Send a POST request to the server
      let API_URL = "";
      if (process.env.NODE_ENV === "production") {
        API_URL = `https://${window.location.hostname}:8000`;
      } else {
        API_URL = `http://${window.location.hostname}:8000`;
      }
      axios
        .post(API_URL + `/user/password_reset`, data)
        .then((response) => {
          // Handle the response from the server
          setProcessing(false);
          if (response.status === 201) {
            //navigate('/');
            window.location.reload(false);
          } else {
            // indicate a failure occurred while trying to change the password
            console.error("Something bad happened! " + response);
            setError(
              "An error occurred while trying to update your password. Please try again in a few minutes."
            );
          }
        })
        .catch((error) => {
          // Handle the error if the request fails
          console.error(error);
          if (error.message == "Network Error") {
            setError("Network error");
          } else {
            sessionStorage.removeItem("accessJwt");
            localStorage.removeItem("accessJwt");
            sessionStorage.removeItem("refreshJwt");
            localStorage.removeItem("refreshJwt");
            localStorage.removeItem("lastUserLogin");
            setError("Invalid username or password");
          }
          setProcessing(false);
        });
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h6">
            Please change your password to continue
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="newPassword1"
              label="New password"
              type="password"
              name="newPassword1"
              autoComplete="new-password"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword2"
              label="Confirm new password"
              type="password"
              id="newPassword2"
              autoComplete="new-password"
            />
            <Typography
              variant="body1"
              fontSize="12px"
              sx={{ display: "block" }}
            >
              Choose a password that is at least 8 characters long.
            </Typography>
            {error ? (
              <Typography
                variant="errorMessage"
                fontSize="16px"
                sx={{ display: "block" }}
                color={colors.redAccent[500]}
              >
                {error}
              </Typography>
            ) : null}

            <Button
              type="submit"
              fullWidth
              color="primary"
              disabled={processing}
              variant="contained"
              sx={{ mt: 3, mb: 2, pt: 1.5, pb: 1.5 }}
            >
              {processing ? <>Updating...</> : <>Continue</>}
            </Button>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
