import { useState, useContext } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme, SvgIcon } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import FolderSpecialOutlinedIcon from "@mui/icons-material/FolderSpecialOutlined";
import DatasetLinkedOutlinedIcon from "@mui/icons-material/DatasetLinkedOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import DigitalEyeIcon from "../../assets/digital-eye-cropped2.png";
import DataLensMainLogoPng from "../../assets/logo/data-lens-main-logo.png";
import QuantaLogo from "../../assets/logo/quanta_h_svg.svg";
import QuantaIcon from "../../assets/logo/quanta_i_svg.svg";
import DataLensInvertedLogoPng from "../../assets/logo/data-lens-inverted-logo.png";
import DataLensMainLogoSvg from "../../assets/logo/data-lens-main-logo.svg";
import DataLensInvertedLogoSvg from "../../assets/logo/data-lens-inverted-logo.svg";
import { CollapsedContext, FeatureFlagsContext } from "../../App.js";
import { UserContext } from "../../Auth.js";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import ImageWithShadow from "../../components/subcomponents/ImageWithShadow";

// Genos

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ signout }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const user = useContext(UserContext);
  const featureFlags = useContext(FeatureFlagsContext);

  //const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const collapsedContext = useContext(CollapsedContext);

  const isCollapsed = collapsedContext.isCollapsed;
  const navigate = useNavigate();

  const do_signout = () => {
    signout();
    navigate("/login");
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 25px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#4cceac !important",
        },
        "& .pro-menu-item.active": {
          color: "#3da58a !important",
        },
      }}
    >
      <ProSidebar
        collapsed={isCollapsed}
        width="230px" // Width when the sidebar is expanded
        collapsedWidth="80px" // Width when the sidebar is collapsed
        style={{
          position: "fixed",
        }}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Menu iconShape="square" style={{ flex: 1 }}>
            {/* LOGO AND MENU ICON */}
            <MenuItem
              onClick={() => collapsedContext.setCollapsed(!isCollapsed)}
            >
              <Box
                sx={{
                  width: "185px",
                  height: "100px",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                {isCollapsed ? (
                  theme.palette.mode === "light" ? (
                    <ImageWithShadow
                      imgProps={{
                        style: {
                          width: "42px",
                          height: "auto",
                          marginRight: "0px",
                          marginLeft: "-2px",
                          marginTop: "50px",
                          top: 0,
                          left: 0,

                          filter: "brightness(0.4) grayscale(0%)",
                        },
                        src: QuantaIcon,
                        alt: "Avatar",
                      }}
                      top="0.0px"
                      right="0.0px"
                      brightness="1.2"
                      grayscale="10%"
                      blur="1px"
                      imageHeight="130px"
                    />
                  ) : (
                    <img
                      style={{
                        width: "42px",
                        height: "auto",
                        marginRight: "0px",
                        marginLeft: "-2px",
                        marginTop: "50px",
                        filter: "brightness(1.0) grayscale(0)",
                      }}
                      src={QuantaIcon}
                      alt="Avatar"
                    />
                  )
                ) : null}
                {!isCollapsed &&
                  (theme.palette.mode === "light" ? (
                    <ImageWithShadow
                      imgProps={{
                        style: {
                          width: "185px",
                          height: "auto",
                          marginRight: "0px",
                          marginLeft: "-8px",
                          marginTop: "-30px",
                          top: 0,
                          left: 0,
                          filter:
                            theme.palette.mode === "light"
                              ? "brightness(.4)"
                              : "brightness(1.0)",
                        },
                        src: QuantaLogo,
                        alt: "Avatar",
                      }}
                      top="0px"
                      right="0px"
                      brightness="1.2"
                      grayscale="00%"
                      blur="1px"
                      imageHeight="130px"
                    />
                  ) : (
                    <img
                      style={{
                        width: "185px",
                        height: "auto",
                        marginRight: "0px",
                        marginLeft: "-8px",
                        marginTop: "-30px",
                        marginBottom: "0px",
                        pt: "0px",
                        pb: "0px",
                        filter:
                          theme.palette.mode === "light"
                            ? "brightness(0.92)"
                            : "brightness(1.0)",
                      }}
                      src={QuantaLogo}
                      alt="Avatar"
                    />
                  ))}
              </Box>
            </MenuItem>

            {/*  Menu Items */}
            <Box paddingLeft={isCollapsed ? undefined : "5%"}>
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Analyze
              </Typography>
              <Item
                title="Chat Interface"
                to="/"
                icon={<ChatOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Dashboards"
                to="/dashboards"
                icon={<FolderSpecialOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              {featureFlags.SHOW_RIOTINTO ? (
                <Item
                  title="Furnace Plan"
                  to="/furnace"
                  icon={<CalendarViewMonthIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              ) : null}

              {user.is_admin ? (
                <>
                  {featureFlags.SHOW_QUERY_INSIGHTS ? (
                    <>
                      <Item
                        title="Query Insights"
                        to="/query"
                        icon={<QueryStatsOutlinedIcon />}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    </>
                  ) : null}
                  <Typography
                    variant="h6"
                    color={colors.grey[300]}
                    sx={{ m: "15px 0 5px 20px" }}
                  >
                    Setup
                  </Typography>
                  <Item
                    title="Data Sources"
                    to="/datasources"
                    icon={<StorageOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Data Sets"
                    to="/datasets"
                    icon={<DatasetLinkedOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Typography
                    variant="h6"
                    color={colors.grey[300]}
                    sx={{ m: "15px 0 5px 20px" }}
                  >
                    Admin
                  </Typography>
                  <Item
                    title="Manage Users"
                    to="/users"
                    icon={<PeopleOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="User Permissions"
                    to="/permissions"
                    icon={<GppGoodOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </>
              ) : (
                <></>
              )}
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                {user && !isCollapsed ? user.full_name : ""}
              </Typography>
              <Item
                title="Sign Out"
                to="/" /* Use the correct route for sign out */
                icon={
                  <ExitToAppIcon />
                } /* You can use ExitToAppIcon or another appropriate icon */
                selected={selected}
                setSelected={do_signout}
              />
            </Box>
          </Menu>
          {isCollapsed ? null : (
            <Typography sx={{ ml: "10px", mb: "2px" }} color={colors.grey[100]}>
              Build 1012
            </Typography>
          )}
        </Box>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
