import React, { useState, useContext, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import SendOutlined from "@mui/icons-material/SendOutlined";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageIcon from "@mui/icons-material/Image";
import { tokens } from "../../theme";
import FormControlLabel from "@mui/material/FormControlLabel";
import { UserContext } from "../../Auth";
import { DataSeriesListContext, LlmChatEndpointsContext } from "../../App";
import { createTheme } from "@mui/material/styles";
import { blue, lightBlue, red } from "@mui/material/colors";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import { SelectedModelRidContext } from "./index.jsx";

const userTheme = createTheme({
  palette: {
    primary: { main: blue[500] },
    secondary: { main: lightBlue[300] },
  },
});

const ChatEntry = ({
  sx,
  isWaiting,
  showThoughts,
  setShowThoughts,
  showFullPrompt,
  setShowFullPrompt,
  sendMessage,
  cancelRequest,
  clearChatHistory,
  selectedModel,
  handleModelChange,
  allowWebSearch,
  useWebSearch,
  setUseWebSearch,
  webSearchRid,
}) => {
  const user = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [message, setMessage] = useState("");
  const quillRef = useRef(null);
  const [imageBase64, setImageBase64] = useState(null);

  const dataSeriesList = useContext(DataSeriesListContext);
  const llmChatEndpoints = useContext(LlmChatEndpointsContext);
  const selectedLlmChatEndpointRid = useContext(SelectedModelRidContext);

  const [isListening, setIsListening] = useState(false);
  const [havePermissions, setHavePermissions] = useState(false);

  const resetRecognition = () => {
    if (recognition.current) {
      recognition.current.stop();
    }
  };

  const checkPermissions = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        setHavePermissions(true);
      })
      .catch((err) => {
        setHavePermissions(false);
        console.log(`${err.name} : ${err.message}`);
      });
  };

  const recognition = useRef(null);
  const initializeMic = () => {
    checkPermissions();
    const SpeechRecognition =
      window.webkitSpeechRecognition || window.SpeechRecognition;
    if (SpeechRecognition) {
      recognition.current = new SpeechRecognition();
      recognition.current.continuous = true;
      recognition.current.interimResults = true;

      recognition.current.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");
        setMessage(transcript);
      };

      recognition.current.onend = () => {
        setIsListening(false);
      };

      recognition.current.onerror = (event) => {
        console.error("Recognition error: ", event.error);
      };
    } else {
      console.log(
        "Your Browser does not support speech recognition. Please use a compatible browser like Google Chrome."
      );
    }
  };

  const toggleListen = () => {
    if (isListening) {
      recognition.current.stop();
      setIsListening(false);
    } else {
      if (!recognition.current) {
        initializeMic();
      }
      if (recognition.current) {
        try {
          recognition.current.start();
        } catch (error) {
          console.error("Error starting speech recognition: ", error);
        }
        setIsListening(true);
      } else {
        console.error("Speech recognition is not available.");
      }
    }
  };

  const send = () => {
    if (isListening) {
      resetRecognition();
    }
    if (isWaiting) {
      return false;
    }

    const trimmedMessage = message.replace(/<p><br><\/p>/g, "");
    sendMessage(trimmedMessage, selectedModel, useWebSearch, webSearchRid);
    setMessage("");
    setImageBase64(null);
    return true;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.ctrlKey) {
        event.preventDefault();
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection();
        quill.insertText(range?.index, "\n");
        quill.setSelection(range?.index + 1);
      } else {
        event.preventDefault();
        send();
      }
    }
  };

  const handleImageUpload = (event) => {
    console.log("image uploading");
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
      quill.insertEmbed(range?.index, "image", e.target.result);
      setImageBase64(e.target.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      event.target.value = null;
    }
  };

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        event.preventDefault(); // Prevent Quill from handling the paste event
        const file = items[i].getAsFile();
        const reader = new FileReader();
        reader.onload = (e) => {
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection();
          quill.insertEmbed(range?.index, "image", e.target.result);
          setImageBase64(e.target.result);
        };
        if (file) {
          reader.readAsDataURL(file);
        }
      }
    }
  };

  useEffect(() => {
    const quill = quillRef.current.getEditor();
    quill.root.addEventListener("paste", handlePaste);
    return () => {
      quill.root.removeEventListener("paste", handlePaste);
    };
  }, []);

  return (
    <Box
      sx={{
        mt: "0px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        ...sx,
      }}
    >
      <Box
        sx={{
          mt: "10px",
          width: "100%",
          pr: "30px",
          display: "flex",
          flexDirection: "row",
          height: "100%",
          "& .ql-container.ql-snow": {
            border: "0px solid #ccc",
            backgroundColor: "rgba(0,0,0,0)",
          },
          "& .ql-editor img": {
            maxWidth: "150px",
            height: "auto",
          },
          "& .ql-editor": {
            padding: "7px 12px",
            fontSize: "16px",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            borderWidth: "1px",
            borderColor: userTheme.palette.primary.main,
            borderStyle: "solid",
            borderRadius: "14px",
          }}
        >
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={message}
            onChange={setMessage}
            modules={{ toolbar: false }}
            formats={["image"]}
            onKeyDown={handleKeyDown}
            style={{
              width: "100%",
              color: colors.primary[100],
              borderStyle: "none",
              borderWidth: "0px",
            }}
            placeholder="Type your message"
          />
          <IconButton
            onClick={toggleListen}
            sx={{
              color: theme.palette.text,
              fontSize: "15px",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "rgba(127,127,127,0.0)",
              },
              p: 0,
              ml: "15px",
              mt: "8px",
              width: "25px",
              height: "25px",
            }}
          >
            {isListening ? (
              <MicOffOutlinedIcon
                sx={{
                  color: "rgba(127,127,127,30)",
                  "&:hover": {
                    color: red[300],
                  },
                }}
              />
            ) : (
              <MicOutlinedIcon
                sx={{
                  color: "rgba(127,127,127,0.7)",
                  "&:hover": {
                    color: blue[300],
                  },
                }}
              />
            )}
          </IconButton>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="icon-button-file"
            type="file"
            onChange={handleImageUpload}
          />

          <label htmlFor="icon-button-file">
            <Tooltip title="Include Image">
              <IconButton
                color="primary"
                component="span"
                sx={{
                  backgroundColor: "rgba(0,0,0,0)",
                  color: theme.palette.text,
                  fontSize: "20px",
                  "&:hover": {
                    backgroundColor: "rgba(127,127,127,0.0)",
                  },
                  borderRadius: "4px",
                  ml: "3px",
                  mr: "7px",
                  mt: "7px",
                  width: "25px",
                  height: "25px",
                }}
              >
                <ImageIcon
                  sx={{
                    color: "rgba(127,127,127,0.7)",
                    "&:hover": {
                      color: blue[300],
                    },
                  }}
                />
              </IconButton>
            </Tooltip>
          </label>
        </Box>
        <IconButton
          onClick={isWaiting ? cancelRequest : send}
          disabled={dataSeriesList === undefined}
          sx={
            !isWaiting
              ? {
                  backgroundColor: `${userTheme.palette.primary.main}`,
                  borderRadius: "4px",
                  color: "white",
                  fontSize: "14px",
                  "&:hover": {
                    backgroundColor: userTheme.palette.secondary.main,
                  },
                  p: 0,
                  ml: "5px",
                  width: "38px",
                  height: "38px",
                }
              : {
                  backgroundColor: theme.palette.warning.main,
                  color: "white",
                  fontSize: "14px",
                  "&:hover": {
                    backgroundColor: theme.palette.warning.light,
                  },
                  borderRadius: "4px",
                  p: 0,
                  ml: "5px",
                  width: "38px",
                  height: "38px",
                }
          }
        >
          {dataSeriesList === undefined
            ? "Initializing Chat..."
            : isWaiting
            ? ""
            : ""}
          {dataSeriesList === undefined ? (
            <CircularProgress size={18} sx={{ ml: 1 }} />
          ) : isWaiting ? (
            <CancelOutlined sx={{ mt: "1px", ml: "3px", fontSize: "16px" }} />
          ) : (
            <SendOutlined sx={{ mt: "1px", ml: "3px", fontSize: "16px" }} />
          )}
        </IconButton>

        {dataSeriesList !== undefined ? (
          <Tooltip title="Clear chat">
            <IconButton
              onClick={clearChatHistory}
              sx={{
                backgroundColor: "rgba(127,127,127,0.1)",
                color: theme.palette.text,
                fontSize: "13px",
                "&:hover": {
                  backgroundColor: "rgba(127,127,127,0.0)",
                },
                width: "25px",
                height: "25px",
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: "rgba(127,127,127,0.2)",
                },
                p: 0,
                ml: "10px",
                width: "38px",
                height: "38px",
              }}
            >
              <DeleteIcon
                sx={{
                  color: "rgba(127,127,127,0.8)",
                }}
              />
            </IconButton>
          </Tooltip>
        ) : null}
      </Box>

      <Box
        sx={{
          mb: "15px",
          mt: "5px",
          height: "100%",
          display: "flex",
        }}
      >
        {user.is_admin ? (
          <Box
            sx={{ mb: "10px", mt: "10px" }}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <FormControl
              sx={{ minWidth: 220, height: 30, mb: "7px" }}
              size="small"
            >
              <InputLabel id="endpoint-select-label">Model</InputLabel>
              <Select
                labelId="endpoint-select-label"
                id="endpoint-select"
                value={selectedModel}
                label="Model"
                onChange={handleModelChange}
              >
                {llmChatEndpoints?.map((endpoint) => (
                  <MenuItem key={endpoint.rid} value={endpoint.rid}>
                    {endpoint.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Switch
                  checked={showThoughts}
                  onChange={(e) => setShowThoughts(e.target.checked)}
                  sx={{
                    whiteSpace: "pre-wrap",
                    ml: "10px",
                    color:
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.light
                        : theme.palette.primary.main,
                    "&.Mui-checked": {
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.primary.light
                          : theme.palette.primary.main,
                    },
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.primary.light
                          : theme.palette.primary.main,
                      "&:hover": {
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? theme.palette.primary.dark
                            : theme.palette.primary.light,
                      },
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor:
                        theme.palette.mode === "dark"
                          ? theme.palette.primary.light
                          : theme.palette.primary.main,
                    },
                  }}
                />
              }
              label="Show thoughts"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showFullPrompt}
                  onChange={(e) => setShowFullPrompt(e.target.checked)}
                  sx={{
                    whiteSpace: "pre-wrap",
                    ml: "10px",
                    color:
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.light
                        : theme.palette.primary.main,
                    "&.Mui-checked": {
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.primary.light
                          : theme.palette.primary.main,
                    },
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.primary.light
                          : theme.palette.primary.main,
                      "&:hover": {
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? theme.palette.primary.dark
                            : theme.palette.primary.light,
                      },
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor:
                        theme.palette.mode === "dark"
                          ? theme.palette.primary.light
                          : theme.palette.primary.main,
                    },
                  }}
                />
              }
              label="Show Full Prompt (Debug)"
            />
          </Box>
        ) : (
          <></>
        )}

        {allowWebSearch ? (
          <FormControlLabel
            control={
              <Switch
                checked={useWebSearch}
                onChange={(e) => setUseWebSearch(e.target.checked)}
                sx={{
                  whiteSpace: "pre-wrap",
                  ml: "10px",
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.primary.light
                      : theme.palette.primary.main,
                  "&.Mui-checked": {
                    color:
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.light
                        : theme.palette.primary.main,
                  },
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color:
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.light
                        : theme.palette.primary.main,
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "dark"
                          ? theme.palette.primary.dark
                          : theme.palette.primary.light,
                    },
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.light
                        : theme.palette.primary.main,
                  },
                }}
              />
            }
            label="Web Search"
          />
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default ChatEntry;
