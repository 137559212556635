import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const PillContainer = styled(({ expandable, ...rest }) => <Paper {...rest} />)(
  ({ theme, expandable }) => ({
    borderRadius: "14px",
    padding: theme.spacing(1, 2),
    display: "inline-flex",
    alignItems: "center",
    cursor: expandable ? "pointer" : "default",
    backgroundColor: expandable
      ? theme.palette.primary.main
      : theme.palette.mode === "light"
      ? "rgba(0, 0, 0, 0.25)"
      : "rgba(255, 255, 255, 0.25)",
    color: theme.palette.getContrastText(
      expandable
        ? theme.palette.primary.main
        : theme.palette.mode === "light"
        ? "rgba(192, 192, 192, 1)"
        : "rgba(64, 64, 64, 1)"
    ),
    marginTop: "8px",
    marginBottom: expandable ? "-1px" : "0px",
  })
);

const ExpandedContent = styled(Paper)(({ theme }) => ({
  marginTop: "-1px",
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.getContrastText(theme.palette.primary.main),
}));

const Chevron = styled("div")({
  marginLeft: "8px",
});

const Pill = ({ text, expandable, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  return (
    <div>
      <ButtonBase
        onClick={() => expandable && setIsOpen(!isOpen)}
        disableRipple={!expandable}
      >
        <PillContainer expandable={expandable}>
          {text}
          {expandable && (
            <Chevron>
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Chevron>
          )}
        </PillContainer>
      </ButtonBase>
      {isOpen && <ExpandedContent>{children}</ExpandedContent>}
    </div>
  );
};

export default Pill;
