import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Box from '@mui/material/Box';
import ActionReturnFigure from './RenderActionReturnFigure';



export default function ActionReturnTabbedGroup({tabs}) {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
    <ThemeProvider theme={theme}>
        <Box sx={{ width: '100%' }}>
            <TabContext value={value}>
                <TabList
                    onChange={handleChange}
                    aria-label="wrapped label tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="secondary"
                    indicatorColor="secondary"
                >
                    {tabs && tabs.map((tab, index) =>
                        <Tab
                            value={index}
                            label={tab.title}
                            key={index}
                            sx={{ 
                                fontSize: '15px', // Increase font size
                                fontWeight: 'bold',
                                color: theme.palette.neutral.light // Darken the color
                            }}
                        />
                    )}
                </TabList>
                {tabs && tabs.map((tab, index) =>
                    <TabPanel
                        value={index}
                        key={index}
                    >
                        <ActionReturnFigure figure={tab} />
                    </TabPanel>
                )}
            </TabContext>
        </Box>
    </ThemeProvider>
    );
}