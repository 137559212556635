import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  IconButton,
  useTheme,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { Delete, Edit } from "@mui/icons-material";
import { ApiContext } from "../../App.js";
import { tokens, colorToRgba, ColorModeContext, useMode } from "../../theme";
import QueryBuilder from "../../components/queryBuilder/QueryBuilder";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoveUpOutlinedIcon from "@mui/icons-material/MoveUpOutlined";
import MoveDownOutlinedIcon from "@mui/icons-material/MoveDownOutlined";
import StraightOutlinedIcon from "@mui/icons-material/StraightOutlined";
import VerticalAlignTopOutlinedIcon from "@mui/icons-material/VerticalAlignTopOutlined";
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";
import KeyboardDoubleArrowUpOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowUpOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useImmer } from "use-immer";
import { styled } from "@mui/material/styles";

import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from "@mui/icons-material/Refresh";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CommentIcon from "@mui/icons-material/Comment";

import { EditTitleDialog, DeleteConfirmDialog } from "./dialogs";

// Icon Buttons for Save, Refresh, Set Refresh Interval, Get Commentary
function DisplayActionButtons() {
  return (
    <Box display="flex" justifyContent="right" alignItems="center" gap={1.5}>
      <Tooltip title="Save">
        <IconButton
          aria-label="save"
          sx={{
            color: "rgba(127, 127, 127, 0.9)", // Main color
            "&:hover": {
              ".MuiSvgIcon-root": {
                // Targeting the SVG icon specifically
                color: "rgba(127, 127, 127, 1)", // Hover color
              },
            },
          }}
        >
          <SaveIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Refresh">
        <IconButton
          aria-label="refresh"
          sx={{
            color: "rgba(127, 127, 127, 0.9)", // Main color
            "&:hover": {
              ".MuiSvgIcon-root": {
                color: "rgba(127, 127, 127, 1)", // Hover color
              },
            },
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Set Refresh Period">
        <IconButton
          aria-label="set refresh period"
          sx={{
            color: "rgba(127, 127, 127, 0.9)", // Main color
            "&:hover": {
              ".MuiSvgIcon-root": {
                color: "rgba(127, 127, 127, 1)", // Hover color
              },
            },
          }}
        >
          <ScheduleIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Get Commentary">
        <IconButton
          aria-label="get commentary"
          sx={{
            color: "rgba(127, 127, 127, 0.9)", // Main color
            "&:hover": {
              ".MuiSvgIcon-root": {
                color: "rgba(127, 127, 127, 1)", // Hover color
              },
            },
          }}
        >
          <CommentIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

// Custom styled components
const CustomAccordionSummary = styled(AccordionSummary)({
  background:
    "linear-gradient(to right, rgba(0, 120,200, 0.8), rgba(0,160,180,0.4))", // Stylish gradient fill
  color: "white",
  fontWeight: "bold",
  borderRadius: "8px",
  margin: "0.5rem 0",
  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
});

const BoldTypography = styled(Typography)({
  fontSize: "1.05rem",
});

const Displays = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const colorMode = useContext(ColorModeContext);
  const api = useContext(ApiContext); // Assuming you have an API context as in previous examples
  const [savedDisplays, setSavedDisplays] = useImmer([]);
  const [loading, setLoading] = useState(true);
  const [inTransition, setInTransition] = useState(false);

  const [isConfirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
    useState(false);
  const [isEditTitleDialogOpen, setEditTitleDialogOpen] = useState(false);
  const [sortedDisplayIndexForMenuAction, setSortedDisplayIndexForMenuAction] =
    useState(null);
  const [isAccordionExpanded, setAccordionExpanded] = useState(true);
  const [rowSelection, setRowSelection] = useState({});

  // Find min and max sequence number of displays
  const { min: minSequence, max: maxSequence } = savedDisplays.reduce(
    (acc, display) => ({
      min: Math.min(acc.min, display.sequence),
      max: Math.max(acc.max, display.sequence),
    }),
    { min: Infinity, max: -Infinity }
  );

  const sortedDisplays = [...savedDisplays].sort((a, b) => {
    if (a.sequence < b.sequence) {
      return -1;
    }
    if (a.sequence > b.sequence) {
      return 1;
    }
    if (a.rid < b.rid) {
      return -1;
    }
    if (a.rid > b.rid) {
      return 1;
    }
    return 0;
  });

  // State to keep track of which menu is open
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event, index) => {
    event.stopPropagation(); // Prevents the click from reaching the accordion
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null);
  };

  const handleEditTitleClick = (sortedRowIndex) => {
    setSortedDisplayIndexForMenuAction(sortedRowIndex);
    setEditTitleDialogOpen(true);
  };

  const handleDeleteMenuClick = useCallback((sortedRowIndex) => {
    console.log("Deleting", sortedRowIndex);
    setSortedDisplayIndexForMenuAction(sortedRowIndex);
    setConfirmDeleteDialogOpen(true);
  }, []);

  // -----------

  const getOrigDisplayIndex = (sortedDisplayIndex) => {
    let origIndex = -1;
    for (let i = 0; i < savedDisplays.length; i++) {
      if (savedDisplays[i].rid === sortedDisplays[sortedDisplayIndex].rid) {
        origIndex = i;
        break;
      }
    }
    if (origIndex < 0) {
      console.error(
        "Unexpected error in handleDisplayAccordionChange(): origIndex < 0"
      );
    }
    return origIndex;
  };

  const handleDisplayAccordionChange = (sortedDisplayIndex) => {
    const origIndex = getOrigDisplayIndex(sortedDisplayIndex);
    if (origIndex < 0) {
      return; // Should never happen
    }

    setSavedDisplays((draft) => {
      draft[origIndex].expanded = !draft[origIndex].expanded;
    });

    updateSavedDisplay(sortedDisplays[sortedDisplayIndex].rid, {
      expanded: !sortedDisplays[sortedDisplayIndex].expanded,
    });
  };

  const handleUpdateDisplayTitle = (sortedDisplayIndex, newTitle) => {
    const origIndex = getOrigDisplayIndex(sortedDisplayIndex);
    if (origIndex < 0) {
      return; // Should never happen
    }

    setEditTitleDialogOpen(false);

    setSavedDisplays((draft) => {
      draft[origIndex].title = newTitle;
    });

    updateSavedDisplay(sortedDisplays[sortedDisplayIndex].rid, {
      title: newTitle,
    });
  };

  const handleMoveToEnd = (index, direction) => {
    // Direction must be either -1 (move up) or 1 (move down)
    //  If at end if list, do nothing
    if (
      (direction === -1 && index === 0) ||
      (direction === 1 && index === sortedDisplays.length - 1)
    ) {
      return;
    }
    let updates = [
      {
        rid: sortedDisplays[index].rid,
        new_sequence: direction === -1 ? minSequence - 1 : maxSequence + 1,
      },
    ];

    updateMultipleDisplays(updates);
  };

  const handleMove = (index, direction) => {
    // Direction must be either -1 (move up) or 1 (move down)
    // Build list of changes
    let updates = [];
    //  If at end if list, do nothing
    if (
      (direction === -1 && index === 0) ||
      (direction === 1 && index === sortedDisplays.length - 1)
    ) {
      return;
    }
    // Swap display positions
    updates = [
      {
        rid: sortedDisplays[index].rid,
        new_sequence: sortedDisplays[index + direction].sequence,
      },
      {
        rid: sortedDisplays[index + direction].rid,
        new_sequence: sortedDisplays[index].sequence,
      },
    ];

    updateMultipleDisplays(updates);
  };

  const deleteDisplay = (rid) => {
    api
      .delete(`/timeseries/saved-displays/delete/${rid}`, {
        timeout: 10000, // 10 seconds
      })
      .then((res) => {
        setInTransition(false);
      })
      .catch((error) => {
        console.log(error);
        setInTransition(false);
      });
    setInTransition(true);
  };

  const updateSavedDisplay = (displayId, updateData) => {
    api
      .patch(`/timeseries/saved-displays/update-by-id/${displayId}`, updateData)
      .then((resopnse) => {
        console.log("Update successful");
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error("Error status:", error.response.status);
          console.error("Error data:", error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received:", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error message:", error.message);
        }
      });
  };

  const updateMultipleDisplays = (updates) => {
    // Directly update state changes (to avoid requerying from backend)
    // Could move this to after API call to ensure persistence is successful before updating UI
    // but UI will be less responsive
    setSavedDisplays((draft) => {
      for (let i = 0; i < draft.length; i++) {
        for (let j = 0; j < updates.length; j++) {
          if (draft[i].rid === updates[j].rid) {
            draft[i].sequence = updates[j].new_sequence;
          }
        }
      }
    });
    api
      .post("/timeseries/saved-displays/multi-update", { updates })
      .then((response) => {
        // Update original_sequence
        setSavedDisplays((draft) => {
          draft.forEach((display) => {
            display.original_sequence = display.sequence;
          });
        });
        console.log("Display sequence updated successfully");
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error("Error status:", error.response.status);
          console.error("Error data:", error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received:", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error message:", error.message);
        }
      });
  };

  const fetchSavedDisplays = async () => {
    try {
      const response = await api.get(
        `/timeseries/saved-displays/read-for-user/${"user_id"}`
      );

      // Reassign the sequence property of all displays on read
      let displays = [];
      if (response.data?.length > 0) {
        displays = response.data.map((object, index) => ({
          ...object,
          original_sequence: object.sequence,
          sequence: index,
        }));
      } else {
        displays = [];
      }
      setSavedDisplays(displays);
    } catch (error) {
      console.error("Error fetching saved displays:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSavedDisplays();
  }, [api]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "visible_text",
        header: "Visible",
        size: 50,
      },
      {
        accessorKey: "title",
        header: "Display Title",
        size: 300,
      },
      {
        accessorKey: "owner_full_name",
        header: "Owner",
        size: 120,
      },
      {
        accessorKey: "context",
        header: "Context",
      },
      {
        accessorKey: "created_on",
        header: "Pinned On",
      },
      {
        accessorKey: "action_type",
        header: "Action Type",
        size: 100,
      },
      {
        accessorKey: "comment",
        header: "Comment",
      },
    ],
    []
  );
  const handleAccordionChange = (event, isExpanded) => {
    setAccordionExpanded(isExpanded);
  };

  const handleSetDisplayVisible = (row, new_visible) => {
    setSavedDisplays((draft) => {
      const index = draft.findIndex(
        (display) => display.rid === row.original.rid
      );
      if (index !== -1) {
        // This state is using immer so we can directly mutate the draft at the specified index
        draft[index].visible = new_visible;
      }
    });
  };

  const handleUpdateActiveDisplays = (rows) => {};

  const rows = sortedDisplays.map((display, index) => ({
    id: index,
    rid: display.rid,
    visible: display.visible,
    visible_text: display.visible ? "Visible" : "Hidden",
    expanded: display.expanded,
    sequence: display.sequence,
    title: display.title,
    action_type: display.parameters.action,
    context: display.chat_context.title,
    created_on: display.created_on,
    owner_full_name: display.owner.full_name,
    //comment: display.comment,
  }));

  console.log(rows);
  return (
    <>
      <EditTitleDialog
        open={isEditTitleDialogOpen}
        onClose={() => setEditTitleDialogOpen(false)}
        onTitleChange={(newTitle) =>
          handleUpdateDisplayTitle(sortedDisplayIndexForMenuAction, newTitle)
        }
        currentTitle={sortedDisplays[sortedDisplayIndexForMenuAction]?.title}
      />

      <DeleteConfirmDialog
        open={isConfirmDeleteDialogOpen}
        onClose={() => setConfirmDeleteDialogOpen(false)}
        onConfirm={() => {
          // Perform the delete operation
          if (sortedDisplayIndexForMenuAction >= 0) {
            setSavedDisplays((prevDisplays) =>
              prevDisplays.filter(
                (display) =>
                  display.rid !==
                  sortedDisplays[sortedDisplayIndexForMenuAction].rid
              )
            );

            deleteDisplay(sortedDisplays[sortedDisplayIndexForMenuAction].rid);
            setSortedDisplayIndexForMenuAction(null);
          }
          setConfirmDeleteDialogOpen(false);
        }}
      />
      <Box sx={{ ml: "30px" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between", // Space items so the first is at the start, last at the end
            alignItems: "center", // Optional: if you want to align items vertically
            mt: "65px",
            mb: "30px",
            borderBottomStyle: "solid",
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              color: "rgba(60, 100, 120, 1)",
              fontWeight: "bold",
              fontSize: "1.7rem",
            }}
          >
            Dashboards
          </Typography>
          <IconButton
            sx={{ mt: "-98px", mr: "29px", verticalAlign: "top" }}
            onClick={colorMode.toggleColorMode}
          >
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
        </Box>
        <Box sx={{ ml: "10px", mr: "20px" }}>
          {
            // Suppress display of this component
            true ? null : (
              <Accordion
                expanded={isAccordionExpanded}
                onChange={handleAccordionChange}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Saved Displays</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ width: "100%" }}>
                    {loading ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      <MaterialReactTable
                        data={rows}
                        columns={columns}
                        state={{ rowSelection }}
                        enableRowSorting={false}
                        enableDensityToggle={false}
                        enableGlobalFilter={false}
                        enableFullScreenToggle={false}
                        enablePagination={false}
                        enableRowActions={true}
                        enableBottomToolbar={false}
                        positionToolbarAlertBanner="bottom"
                        initialState={{
                          columnOrder: [
                            "mrt-row-actions",
                            "actions",
                            "title",
                            "visible_text",
                            "action_type",
                            "context",
                            "created_on",
                            "owner_full_name",
                          ],
                        }}
                        renderRowActionMenuItems={({
                          row,
                          table,
                          closeMenu,
                        }) => [
                          !row.original.visible ? (
                            <MenuItem
                              key="show-item"
                              onClick={() => {
                                closeMenu();
                                handleSetDisplayVisible(row, true);
                              }}
                              sx={{ m: 0 }}
                            >
                              <ListItemIcon>
                                <VisibilityOutlinedIcon />
                              </ListItemIcon>
                              Show Item
                            </MenuItem>
                          ) : null,
                          row.original.visible ? (
                            <MenuItem
                              key="hide-item"
                              onClick={() => {
                                closeMenu();
                                handleSetDisplayVisible(row, false);
                              }}
                              sx={{ m: 0 }}
                            >
                              <ListItemIcon>
                                <VisibilityOffOutlinedIcon />
                              </ListItemIcon>
                              Hide Item
                            </MenuItem>
                          ) : null,

                          <Divider key="divider-1" />,
                          <MenuItem
                            key="delete-display"
                            onClick={() => {
                              closeMenu();
                              handleDeleteMenuClick(row.index);
                            }}
                            sx={{ m: 0 }}
                          >
                            <ListItemIcon>
                              <DeleteOutlineOutlinedIcon />
                            </ListItemIcon>
                            Delete display
                          </MenuItem>,
                          <MenuItem
                            key="edit-row"
                            onClick={() => {
                              closeMenu();
                              table.setEditingRow(row);
                            }}
                            sx={{ m: 0 }}
                          >
                            <ListItemIcon>
                              <EditOutlinedIcon />
                            </ListItemIcon>
                            Edit Row
                          </MenuItem>,

                          <Divider key="divider-2" />,

                          <MenuItem
                            key="move-to-top"
                            onClick={() => {
                              closeMenu();
                              console.log("ROW", row);
                              handleMoveToEnd(row.index, -1);
                            }}
                            sx={{ m: 0 }}
                          >
                            <ListItemIcon>
                              <KeyboardDoubleArrowUpOutlinedIcon />
                            </ListItemIcon>
                            Move to Top
                          </MenuItem>,
                          <MenuItem
                            key="move-up"
                            onClick={() => {
                              closeMenu();
                              handleMove(row.index, -1);
                            }}
                            sx={{ m: 0 }}
                          >
                            <ListItemIcon>
                              <KeyboardArrowUpOutlinedIcon />
                            </ListItemIcon>
                            Move Up
                          </MenuItem>,
                          <MenuItem
                            key="move-down"
                            onClick={() => {
                              closeMenu();
                              handleMove(row.index, 1);
                            }}
                            sx={{ m: 0 }}
                          >
                            <ListItemIcon>
                              <KeyboardArrowDownOutlinedIcon />
                            </ListItemIcon>
                            Move Down
                          </MenuItem>,

                          <MenuItem
                            key="move-to-bottom"
                            onClick={() => {
                              closeMenu();
                              handleMoveToEnd(row.index, 1);
                            }}
                            sx={{ m: 0 }}
                          >
                            <ListItemIcon>
                              <KeyboardDoubleArrowDownOutlinedIcon />
                            </ListItemIcon>
                            Move to Bottom
                          </MenuItem>,
                        ]}
                        renderTopToolbarCustomActions={({ table }) => (
                          <Box
                            sx={{
                              display: "flex",
                              gap: "1rem",
                              p: "0.5rem",
                              flexWrap: "wrap",
                              alignItems: "flex-end",
                            }}
                          >
                            <Button
                              sx={{
                                backgroundColor: theme.palette.secondary.main,
                                borderRadius: "4px",
                                color: theme.palette.getContrastText(
                                  theme.palette.secondary.main
                                ),
                                fontSize: "12px", // Set font size
                                "&:hover": {
                                  backgroundColor: theme.palette.secondary.main, // Set hover background color
                                },
                              }}
                              disabled={
                                table.getPrePaginationRowModel().rows.length ===
                                0
                              }
                              onClick={() =>
                                handleUpdateActiveDisplays(
                                  table.getSelectedRowModel().rows
                                )
                              }
                              // startIcon={<FileDownloadIcon />}
                              variant="contained"
                            >
                              Update Dashboard
                            </Button>
                            <Typography
                              sx={{
                                ml: "10px",
                                mb: "6px",
                                pb: "0px",
                                color: theme.palette.neutral.dark,
                              }}
                            >
                              {`${new Intl.NumberFormat().format(
                                savedDisplays.length
                              )} saved displays`}
                            </Typography>
                          </Box>
                        )}
                        muiTablePaperProps={{
                          elevation: 0,
                        }}
                        muiTableHeadCellProps={{
                          sx: (theme) => ({
                            background:
                              theme.palette.mode === "light"
                                ? "rgba(0, 0, 0, 0.08)"
                                : "rgba(255, 255, 255, 0.06)",
                            borderRight: "1px solid ",
                            borderRightColor:
                              theme.palette.mode === "light"
                                ? "rgba(0, 0, 0, 0.1)"
                                : "rgba(255, 255, 255, 0.2)",
                            color: theme.palette.text.primary,
                          }),
                        }}
                        muiTableContainerProps={{
                          sx: {
                            maxHeight: "50vh",
                            borderColor:
                              theme.palette.mode === "light"
                                ? "rgba(0, 0, 0, 0.12)"
                                : "rgba(255, 255, 255, 0.14)",
                            borderWidth: "0px",
                            borderStyle: "solid",
                          },
                        }}
                      />
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            )
          }
          {sortedDisplays.map((value, index) =>
            value.visible ? (
              <Accordion
                elevation={0}
                key={value.rid}
                expanded={value.expanded}
                onChange={() => handleDisplayAccordionChange(index)}
              >
                <CustomAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                >
                  <IconButton
                    aria-label="settings"
                    sx={{ pt: "0px", pb: "0px", mr: "20px", width: "20px" }}
                    onClick={(event) => handleMenuClick(event, index)}
                    size="large"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <BoldTypography sx={{ mt: "0px" }}>
                    {value.title}
                  </BoldTypography>
                </CustomAccordionSummary>
                <Menu
                  id={`simple-menu-${index}`}
                  anchorEl={anchorEl}
                  open={openMenuIndex === index}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      handleDeleteMenuClick(index);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <DeleteOutlineOutlinedIcon />
                    </ListItemIcon>
                    Delete
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      handleEditTitleClick(index);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <EditOutlinedIcon />
                    </ListItemIcon>
                    Edit Title
                  </MenuItem>
                  <Divider key="divider-2" />
                  <MenuItem
                    key="move-to-top"
                    onClick={() => {
                      handleMenuClose();
                      handleMoveToEnd(index, -1);
                    }}
                    disabled={index === 0}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <KeyboardDoubleArrowUpOutlinedIcon />
                    </ListItemIcon>
                    Move to Top
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      handleMove(index, -1);
                    }}
                    disabled={index === 0}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <KeyboardArrowUpOutlinedIcon />
                    </ListItemIcon>
                    Move Up
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      handleMove(index, 1);
                    }}
                    disabled={index === sortedDisplays.length - 1}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <KeyboardArrowDownOutlinedIcon />
                    </ListItemIcon>
                    Move Down
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      handleMoveToEnd(index, 1);
                    }}
                    disabled={index === sortedDisplays.length - 1}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <KeyboardDoubleArrowDownOutlinedIcon />
                    </ListItemIcon>
                    Move to Bottom
                  </MenuItem>
                </Menu>
                <AccordionDetails>
                  <Box>
                    <DisplayActionButtons />
                    <QueryBuilder
                      noFeedbackButtons={true}
                      initialValues={value.parameters}
                      sessionId={value.from_chat_session_id}
                      noCommentary={true}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            ) : null
          )}
        </Box>
      </Box>
    </>
  );
};

export default Displays;
