import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Box } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const CitationList = ({ sourceList }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={{ ml: "10px" }}>
      <Typography variant="h6" component="div" sx={{ fontSize: "0.9em" }}>
        {sourceList.length > 1 ? "Sources" : "Source"}
        <IconButton onClick={handleExpandClick} size="small">
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Typography>
      {expanded && (
        <List sx={{ fontSize: "0.8em" }} dense>
          {sourceList.map((source, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={
                  <>
                    {index + 1}.{" "}
                    <Link href={source.url} target="_blank" rel="noopener">
                      {source.title}
                    </Link>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default CitationList;
