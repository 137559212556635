import React, { useState } from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Stack,
  Box,
  useTheme,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { theme, tokens } from "../../theme";

const TimeRangePicker = ({ setValues, returnValues }) => {
  const now = dayjs().utc();
  const defaultIsRelative =
    returnValues?.start_duration_before_present != null &&
    returnValues?.start_duration_units != null;
  const [isRelative, setIsRelative] = useState(defaultIsRelative); // to toggle between absolute and relative
  const [absoluteStart, setAbsoluteStart] = useState(
    dayjs(returnValues?.absolute_start_date, "YYYY-MM-DD HH:mm:ss")
  );
  const [absoluteEnd, setAbsoluteEnd] = useState(
    dayjs(returnValues?.absolute_end_date, "YYYY-MM-DD HH:mm:ss")
  );
  const [relativeStart, setRelativeStart] = useState(
    returnValues?.start_duration_before_present
  );
  const [relativeEnd, setRelativeEnd] = useState(
    returnValues?.end_duration_before_present
  );
  const [timeUnit, setTimeUnit] = useState(returnValues?.start_duration_units);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const setReturnValue = (prop, value) =>
    setValues((prevValues) => ({ ...prevValues, [prop]: value }));

  const handleAbsoluteStart = (value) => {
    // If both absolute start and end dates are set, clear relative dates to indicate absolute dates should be used
    // For now, there is no absolute/relative separate flag being passed to backend
    if (absoluteEnd && value) {
      setReturnValue("start_duration_before_present", null);
      setReturnValue("end_duration_before_present", null);
    }
    setAbsoluteStart(value);
    setReturnValue("absolute_start_date", value);
  };

  const handleAbsoluteEnd = (value) => {
    if (absoluteStart && value) {
      setReturnValue("start_duration_before_present", null);
      setReturnValue("end_duration_before_present", null);
    }
    setAbsoluteEnd(value);
    setReturnValue("absolute_end_date", value);
  };

  const handleUnitChange = (event) => {
    setTimeUnit(event.target.value);
    setReturnValue("start_duration_units", event.target.value);
    setReturnValue("end_duration_units", event.target.value);
  };

  const handleModeChange = (event) => {
    setIsRelative(event.target.checked);
    if (event.target.checked) {
      setReturnValue("absolute_start_date", null);
      setReturnValue("absolute_end_date", null);
    } else {
      setReturnValue("absolute_start_date", absoluteStart);
      setReturnValue("absolute_end_date", absoluteEnd);
    }
  };

  const handleRelativeStart = (value) => {
    setRelativeStart(value);
    setReturnValue("start_duration_before_present", value);
  };

  const handleRelativeEnd = (value) => {
    setRelativeEnd(value);
    setReturnValue("end_duration_before_present", value);
  };

  return (
    <Stack sx={{ flexDirection: "column", alignItems: "flex-start" }}>
      {/* Switch between absolute and relative */}
      <Typography variant="h5" sx={{ mt: "20px", fontWeight: "bold" }}>
        Start and Ending datetime:
      </Typography>

      <Typography variant="body1" sx={{ mt: "20px", color: colors.grey[600] }}>
        {isRelative
          ? "Specify the start and end time relative to the present"
          : "Specify absolute start and ending datetimes.  Tip: It is recommended to use relative dates when possible, especially to create resuable analytics (e.g. dashboards)"}
      </Typography>
      {/* Container for the date pickers */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row", // Arranges children (date pickers) in a row
          alignItems: "center", // Vertically aligns items in the center
          gap: 1, // Optional: Adds some space between the date pickers
          mt: "1.5em", // Optional: Adds some margin-top for spacing from the switch
          mb: "1em",
        }}
      >
        {!isRelative ? (
          // Render Absolute controls
          <>
            <Typography>From</Typography>
            <DateTimePicker
              size="small"
              label="Start date"
              value={absoluteStart}
              onChange={handleAbsoluteStart}
              renderInput={(params) => <TextField {...params} />}
              // Additional props...
            />
            <Typography>to</Typography>
            <DateTimePicker
              size="small"
              label="End date"
              value={absoluteEnd}
              onChange={handleAbsoluteEnd}
              renderInput={(params) => <TextField {...params} />}
              // Additional props...
            />
          </>
        ) : (
          // Render Relative controls
          <Box
            sx={{
              display: "flex",
              flexDirection: "row", // Places children in a row
              alignItems: "center", // Vertically aligns items in the center
              gap: 2, // Adds some space between the TextField and Select
            }}
          >
            <Typography>From</Typography>
            <TextField
              size="small"
              label="Start (relative)"
              value={relativeStart}
              sx={{ width: "100px", minWidth: "100px" }}
              onChange={(e) => handleRelativeStart(parseFloat(e.target.value))}
              type="number"
              inputProps={{ step: "1" }}
            />
            <Typography>to</Typography>
            <TextField
              size="small"
              label="End (relative)"
              value={relativeEnd}
              sx={{ width: "100px", minWidth: "100px" }}
              onChange={(e) => handleRelativeEnd(parseFloat(e.target.value))}
              type="number"
              inputProps={{ step: "1" }}
            />
            <FormControl variant="standard">
              <Select
                size="small"
                variant="outlined"
                sx={{ width: "120px", minWidth: "100px" }}
                value={timeUnit}
                onChange={handleUnitChange}
              >
                <MenuItem value="seconds">seconds</MenuItem>
                <MenuItem value="minutes">minutes</MenuItem>
                <MenuItem value="hours">hours</MenuItem>
                <MenuItem value="days">days</MenuItem>
                <MenuItem value="weeks">weeks</MenuItem>
                <MenuItem value="months">months</MenuItem>
                <MenuItem value="years">years</MenuItem>
              </Select>
            </FormControl>

            <Typography>before now</Typography>
          </Box>
        )}
      </Box>
      <FormControlLabel
        control={<Switch checked={isRelative} onChange={handleModeChange} />}
        label={
          <Typography variant="body1" sx={{ fontWeight: "" }}>
            Use relative dates
          </Typography>
        }
        sx={{ ml: "3.5em" }}
        labelPlacement="start"
      />
    </Stack>
  );
};

export default TimeRangePicker;
