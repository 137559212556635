import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ToggleButtonGroup,
  Typography,
  useTheme,
  ToggleButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Header from "../../components/Header";
import { useEffect, useState, useContext, createContext, useRef } from "react";
import { tokens } from "../../theme";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import cloneDeep from "lodash/cloneDeep";
import { ApiContext } from "../../App.js";
import axios from "axios";

import SelectDataSeries from "./selectDataSeries";
import {
  default as SelectParameters,
  timeUnitMultipliers,
  calculateSamples,
} from "./selectParameters";
import QueryResults from "./queryResults";
import { useImmer } from "use-immer";
import dayjs from "dayjs";
import {
  buildSqlWithResample,
  buildSqlNative,
  piDataTypes,
  countIntervals,
} from "./querySqlBuilder";
import ActionReturnFigure from "../../components/subcomponents/RenderActionReturnFigure";
//import moment from 'moment';

import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const DataSourcesContext = createContext({});
export const ActiveDataSourceContext = createContext({});
export const DatasetsContext = createContext([]);
export const ActiveDatasetsContext = createContext([]);
export const DatasetAccordionStateContext = createContext([]);
export const MetadataContext = createContext([]);
export const SelectedMetadataContext = createContext([]);
export const LoadMetadataQueueContext = createContext([]);

const Query = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const api = useContext(ApiContext);

  const cancelTokenSourceRef = useRef(null);

  const [dataSources, setDataSources] = useState({
    data: [],
    handler: activeDataSourceChange,
  });
  const [activeDataSource, setActiveDataSource] = useImmer("");
  const [datasets, setDatasets] = useState({});
  const [datasetAccordionState, setDatasetAccordionState] = useImmer({
    accordionExpanded: false,
    changeAccordionExpandedHandler: changeAccordionExpanded,
    handler: activeDatasetsChange,
  });
  const [activeDatasets, setActiveDatasets] = useState([]);
  const [loadMetadataQueue, setLoadMetadataQueue] = useImmer([]);

  const metadata = useRef({});
  const [allDatasets, setAllDatasets] = useState({});

  useEffect(() => {
    api.get("/data_sources").then((res) => {
      setDataSources({ data: res.data, handler: activeDataSourceChange });
    });
  }, []);

  useEffect(() => {
    // Load metadata for new dataset using API call
    if (Object.keys(metadata["current"]).length > 0) {
      return;
    }

    api
      .get("/timeseries/metadata/", {
        params: {
          data_source_id: null,
          dataset_id: null,
        },
      })
      .then((res) => {
        /*  res.data contains a flat list of metadata formatted like:

          [{data_source: 'PI_COATER', raw_dataset: 'GOO_VAC1', pipoint_id: 1234, pipoint_name: 'GOO_VAC1_SC_C051_AV_TargetPressureStdDev'},
          {data_source: 'PI_FLOAT', raw_dataset: 'CAI_FLT1', pipoint_id: 1534, pipoint_name: 'CAI_FLT1_VS_DEFECT_STO_03 Counts'},
          :
          ]

          We will reformat this into a series of lists, stored in an object, organized by a combined datasetId key
          to enable fast lookup and filtering based on dataset.

          {'PI_COATER/GOO_VAC1' : [{data_source: 'PI_COATER', raw_dataset: 'GOO_VAC1', pipoint_id: 1234, pipoint_name: 'GOO_VAC1_SC_C051_AV_TargetPressureStdDev'}, ...],
           'PI_FLOAT/CAI_FLT1': [{data_source: 'PI_FLOAT', raw_dataset: 'CAI_FLT1', pipoint_id: 1534, pipoint_name: 'CAI_FLT1_VS_DEFECT_STO_03 Counts'}, ...]
          }
          */
        metadata.current = res.data.reduce((accumulator, currentObject) => {
          let key = `${currentObject.DATA_SOURCE}/${currentObject.RAW_DATASET}`;

          // If the key doesn't exist yet, initialize it with an array containing the current object
          if (!accumulator[key]) {
            accumulator[key] = [currentObject];
          }
          // If the key already exists, push the current object into its array
          else {
            accumulator[key].push(currentObject);
          }

          return accumulator;
        }, {});
      });
  }, []);

  const [resultSet, setResultSet] = useState({
    pipointIndex: {},
    dataColumns: [],
    resampled: false,
    data: [],
    error: "",
  });
  const [displayColumns, setDisplayColumns] = useState([
    {
      field: "PI_TIMESTAMP",
      headerName: "Timestamp UTC",
    },
  ]);
  const [resultFigure, setResultFigure] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  function changeAccordionExpanded(newExpanded) {
    setDatasetAccordionState((prevState) => {
      prevState.accordionExpanded = newExpanded;
    });
  }

  function activeDataSourceChange(
    newActiveDatasource,
    pdatasets,
    pactiveDatasets
  ) {
    setDatasetAccordionState((prevState) => {
      prevState.accordionExpanded = true;
    });

    setActiveDataSource(newActiveDatasource);

    if (!pdatasets || !pdatasets[newActiveDatasource]) {
      api.get("/datasets/" + newActiveDatasource).then((res) => {
        let resPrefixed = res.data.map((v) => {
          v.id = newActiveDatasource + "/" + v.id;
          return v;
        });
        let newDatasets = cloneDeep(pdatasets);
        if (!newDatasets) {
          newDatasets = {};
        }
        newDatasets[newActiveDatasource] = resPrefixed;
        setDatasets(newDatasets);
      });
    }
  }

  function activeDatasetsChange(newActiveDatasets, ploadMetadataQueue) {
    if (!newActiveDatasets) {
      newActiveDatasets = [];
    }

    setActiveDatasets(newActiveDatasets);

    //  Queue metadata to be loaded, if this is a new dataset
    let needUpdate = false;
    let newUpdateQueue = [...ploadMetadataQueue];
    newActiveDatasets.forEach((element) => {
      if (!newUpdateQueue.includes(element)) {
        newUpdateQueue.push(element);
        needUpdate = true;
      }
    });

    if (needUpdate) {
      setLoadMetadataQueue(newUpdateQueue);
    }
  }

  useEffect(() => {
    api.get("/timeseries/datasets/").then((res) => {
      metadata.datasets = res.data;
    });
  }, []);

  //  ----------------------------------
  //  Used by SelectDataSeries component
  //  ----------------------------------

  const [selectedMetadata, setSelectedMetadata] = useImmer([]);

  //  Remove selected from array
  function removeSelectedMetadata(key) {
    setSelectedMetadata((draft) => {
      let index = draft.indexOf(key);
      if (index !== -1) {
        draft.splice(index, 1);
      }
    });
  }

  //  Add selected key to array if not already present
  function addSelectedMetadata(key) {
    setSelectedMetadata((draft) => {
      let index = draft.indexOf(key);
      if (index === -1) {
        draft.push(key);
      }
    });
  }

  //  ----------------------------------
  //  Used by SelectParameters component
  //  ----------------------------------

  const [parameters, setParameters] = useImmer({
    preset: "24_HOURS",
    startDateTime: dayjs().utc(),
    endDateTime: dayjs().utc(),
    resample: false,
    frequency: 1,
    frequencyUnits: "MINUTES",
    aggregationFunction: "AVG",
  });

  function updateParameters(newParameters, changedField) {
    switch (changedField) {
      case "preset":
        switch (newParameters.preset) {
          case "24_HOURS":
            newParameters.endDateTime = dayjs().utc();
            newParameters.startDateTime = dayjs().utc().subtract(24, "hour");
            break;
          case "3_DAYS":
            newParameters.endDateTime = dayjs().utc();
            newParameters.startDateTime = dayjs().utc().subtract(72, "hour");
            break;
          case "7_DAYS":
            newParameters.endDateTime = dayjs().utc();
            newParameters.startDateTime = dayjs().utc().subtract(7, "day");
            break;
          case "1_MONTH":
            newParameters.endDateTime = dayjs().utc();
            newParameters.startDateTime = dayjs().utc().subtract(1, "month");
            break;
          case "2_MONTHS":
            newParameters.endDateTime = dayjs().utc();
            newParameters.startDateTime = dayjs().utc().subtract(2, "month");
            break;
          case "6_MONTHS":
            newParameters.startDateTime = dayjs().utc();
            newParameters.startDateTime = dayjs().utc().subtract(6, "month");
            break;
          case "CUSTOM":
            break;
          default:
            console.warn(`Unknown preset: ${newParameters.preset}`);
            break;
        }
        break;
      case "startDateTime":
      case "endDateTime":
        newParameters.preset = "CUSTOM";
        break;
      default:
        console.warn(`Unknown field: ${changedField}`);
        break;
    }

    //  Make sure sample rate is not too high

    setParameters((draft) => (draft = { ...draft, ...newParameters }));
  }

  //  Filter Pass #1: Populate filtered metadata list based on selected datasets
  let filteredMetadata = [];
  for (const [datasetKey, metadataArray] of Object.entries(metadata.current)) {
    if (
      activeDatasets.includes(datasetKey) &&
      datasetKey.startsWith(activeDataSource + "/")
    ) {
      for (const metadataObject of metadataArray) {
        let n = {
          id: metadataObject.id,
          item: {
            title: metadataObject.TITLE,
            desc:
              metadataObject.AF_DESCRIPTION > ""
                ? "  (Comment: " + metadataObject.AF_DESCRIPTION + ")"
                : "",
            pipointName: metadataObject.PIPOINT_NAME,
            afPath: metadataObject.AF_PATH.replace("\\\\GRD001P-APP029\\", ""),
          },
          stats: {
            firstMeasurement: metadataObject.FIRST_MEASUREMENT,
            lastMeasurement: metadataObject.LAST_MEASUREMENT,
            numMeasurements: metadataObject.NUM_MEASUREMENTS_24_HRS,
          },
          datasetDesc: metadataObject.DATASET_DESC,
          datasetId: metadataObject.RAW_DATASET,
          title: metadataObject.TITLE,
          snowflakeTargetTable: metadataObject.SNOWFLAKE_TARGET_TABLE,
          pipointId: metadataObject.PIPOINT_ID,
          pointType: metadataObject.POINT_TYPE,
          step: metadataObject.STEP,
          afUom: metadataObject.AF_UOM,
          searchKey:
            (metadataObject.AF_DESCRIPTION
              ? metadataObject.AF_DESCRIPTION
              : ""
            ).toUpperCase() +
            "|" +
            (metadataObject.PIPOINT_NAME
              ? metadataObject.PIPOINT_NAME
              : ""
            ).toUpperCase() +
            "|" +
            (metadataObject.DATASET_DESC
              ? metadataObject.DATASET_DESC
              : ""
            ).toUpperCase(),
        };

        filteredMetadata.push(n);
      }
    }
  }

  function executeQuery() {
    let timeseriesQueryParams = {
      start_time: parameters.startDateTime.utc().format("YYYY-MM-DD HH:mm:ss"),
      end_time: parameters.endDateTime.utc().format("YYYY-MM-DD HH:mm:ss"),
      labels: [],
      data_series_id_list: [],
    };

    timeseriesQueryParams.data_series_id_list = filteredMetadata
      .filter((elem) => selectedMetadata.includes(elem.id))
      .map((elem) => elem.id);

    timeseriesQueryParams.labels = filteredMetadata
      .filter((elem) => selectedMetadata.includes(elem.id))
      .map((elem) => elem.title);
    cancelTokenSourceRef.current = axios.CancelToken.source();
    api
      .post("/timeseries/query", timeseriesQueryParams, {
        timeout: 600000, // 10 minutes in milliseconds
        cancelToken: cancelTokenSourceRef.current.token,
      })
      .then((res) => {
        setIsLoading(false);
        setResultFigure(res.data);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setResultFigure({});
      });
    setIsLoading(true);
  }

  const cancelQuery = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel("Request cancelled manually.");
    }
  };

  return (
    <LoadMetadataQueueContext.Provider value={loadMetadataQueue}>
      <MetadataContext.Provider value={metadata}>
        <DatasetAccordionStateContext.Provider value={datasetAccordionState}>
          <ActiveDatasetsContext.Provider value={activeDatasets}>
            <DatasetsContext.Provider value={datasets}>
              <ActiveDataSourceContext.Provider value={activeDataSource}>
                <DataSourcesContext.Provider value={dataSources}>
                  <Box m="20px">
                    <Box>
                      <Header
                        icon={QueryStatsOutlinedIcon}
                        title="Query Insights"
                        subtitle=""
                      />
                    </Box>

                    <Box>
                      <Accordion
                        disableGutters
                        defaultExpanded="true"
                        sx={{
                          borderRadius: "12px",
                          minHeight: 0,
                          "&:before": {
                            display: "none",
                          },
                          padding: "10px 10px 10px 10px",
                          margin: "30px 30px 10px 0px",
                          backgroundColor: colors.primary[400],
                          color: colors.grey[100],
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography variant="headingPrompt">
                            Which <span>data source</span> would you like to
                            explore?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            sx={{
                              display: "grid",
                              marginTop: "1px",
                              gridTemplateColumns: "repeat(5, 1fr)",
                            }}
                            container
                            spacing={2}
                          >
                            {dataSources.data.map((d) => (
                              <ToggleButton
                                key={d.id}
                                display="block"
                                selected={d.id === activeDataSource}
                                className="DataSourceToggle"
                                sx={{
                                  "&:hover, &.DataSourceToggle.Mui-selected": {
                                    backgroundColor: colors.blueAccent[700],
                                  },
                                  backgroundColor: colors.primary[300],
                                  m: "10px 10px 10px 10px",
                                  color: colors.grey[100],
                                }}
                                variant="text"
                                onClick={() =>
                                  dataSources.handler(
                                    d.id,
                                    datasets,
                                    activeDatasets,
                                    metadata
                                  )
                                }
                              >
                                {d.desc}
                              </ToggleButton>
                            ))}
                          </Box>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        disableGutters
                        expanded={datasetAccordionState.accordionExpanded}
                        sx={{
                          borderRadius: "12px",
                          padding: "10px 10px 10px 10px",
                          "&:before": {
                            display: "none",
                          },
                          margin: "30px 30px 10px 0px",
                          backgroundColor: colors.primary[400],
                          color: colors.grey[100],
                        }}
                        onChange={(e, expanded) => {
                          datasetAccordionState.changeAccordionExpandedHandler(
                            expanded
                          );
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Box display="block">
                            <Typography variant="headingPrompt">
                              Which <span>data sets</span> are you interested
                              in?
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ToggleButtonGroup
                            value={activeDatasets}
                            onChange={(event, newState) =>
                              datasetAccordionState.handler(
                                newState,
                                loadMetadataQueue
                              )
                            }
                            sx={{
                              display: "grid",
                              marginTop: "1px",
                              gridTemplateColumns: "repeat(5, 1fr)",
                            }}
                            container
                            spacing={2}
                          >
                            {((datasets ? datasets : {})[activeDataSource]
                              ? datasets[activeDataSource]
                              : [{}]
                            ).map((d) => (
                              <ToggleButton
                                key={d.id}
                                display="block"
                                width="90%"
                                className="DataSourceToggle"
                                selected={false}
                                sx={{
                                  "&:hover, &.DataSourceToggle.Mui-selected": {
                                    backgroundColor: activeDatasets.includes(
                                      d.id
                                    )
                                      ? colors.blueAccent[800]
                                      : colors.primary[400],
                                  },
                                  backgroundColor: activeDatasets.includes(d.id)
                                    ? colors.blueAccent[700]
                                    : colors.primary[300],
                                  m: "10px 10px 10px 10px",
                                  "&.DataSourceToggle.MuiToggleButtonGroup-grouped":
                                    { borderRadius: "5px", ml: 1 },
                                  color: colors.grey[100],
                                }}
                                variant="text"
                                value={d.id}
                              >
                                {d.desc}
                              </ToggleButton>
                            ))}
                          </ToggleButtonGroup>
                          <Box>
                            <Typography>
                              Number of data series: {filteredMetadata.length}
                            </Typography>
                          </Box>
                        </AccordionDetails>
                      </Accordion>

                      <SelectDataSeries
                        filteredMetadata={filteredMetadata}
                        selectedMetadata={selectedMetadata}
                        removeSelectedMetadata={removeSelectedMetadata}
                        addSelectedMetadata={addSelectedMetadata}
                      />

                      <SelectParameters
                        filteredMetadata={filteredMetadata}
                        selectedMetadata={selectedMetadata}
                        parameters={parameters}
                        updateParameters={updateParameters}
                        executeQuery={executeQuery}
                        cancelQuery={cancelQuery}
                        isLoading={isLoading}
                      />
                      {resultFigure ? (
                        <ActionReturnFigure key={1} figure={resultFigure} />
                      ) : null}
                    </Box>
                  </Box>
                </DataSourcesContext.Provider>
              </ActiveDataSourceContext.Provider>
            </DatasetsContext.Provider>
          </ActiveDatasetsContext.Provider>
        </DatasetAccordionStateContext.Provider>
      </MetadataContext.Provider>
    </LoadMetadataQueueContext.Provider>
  );
};

export default Query;
