export function exportToCSV(objects, fileName = "data") {
  // This function exports a list of objects to a CSV

  // Example usage
  //  const data = [
  //   { name: "John Doe", age: 30, email: "john@example.com" },
  //   { name: "Jane Doe", age: 25, email: "jane@example.com" }
  //  ];
  //  exportToCSV(data, 'users');

  // Extract column headers
  const headers = Object.keys(objects[0]);
  const csvRows = [];

  // Add the headers row
  csvRows.push(headers.join(","));

  // Add the rows for each object
  for (const object of objects) {
    const values = headers.map((header) => {
      const escaped = ("" + object[header]).replace(/"/g, '\\"'); // Escape double quotes
      return `"${escaped}"`; // Wrap values in double quotes
    });
    csvRows.push(values.join(","));
  }

  // Combine rows and create a Blob
  const csvString = csvRows.join("\n");
  const blob = new Blob([csvString], { type: "text/csv" });

  // Create a link and trigger download
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
