import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import {
  Box,
  useTheme,
  Button,
  TextField,
  TextareaAutosize,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { ApiContext } from "../../App.js";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { CircularProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ActionReturnFigure from "../../components/subcomponents/RenderActionReturnFigure";
import ChatCommentaryResponse from "../chat/ChatCommentaryResponse";
import {
  ActiveChatContextRidContext,
  SelectedModelRidContext,
} from "../../scenes/chat";

const { v4: uuidv4 } = require("uuid");

export const ChatResponse = ({
  actionResponse,
  sessionId,
  queryBuilderParamValues,
  noCommentary,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const api = useContext(ApiContext);
  const activeChatContextRid = useContext(ActiveChatContextRidContext);
  const selectedModelRid = useContext(SelectedModelRidContext);

  const cancelCommentaryTokenSourceRef = useRef(null);
  const [commentaryResponse, setCommentaryResponse] = useState(null);
  const [commentaryError, setCommentaryError] = useState(null);
  const [commentaryIsLoading, setCommentaryIsLoading] = useState(false);

  function getCommentary(toLlm) {
    cancelCommentaryTokenSourceRef.current = axios.CancelToken.source();
    api
      .post(
        "get_commentary",
        {
          prompt: "",
          to_llm: toLlm,
          sessionId: sessionId,
          requestId: uuidv4(),
          values: queryBuilderParamValues,
          contextRid: queryBuilderParamValues?.chat_context_rid,
          llmChatEndpointRid: selectedModelRid,
        },
        {
          timeout: 60000, // 1 minute in milliseconds
          cancelToken: cancelCommentaryTokenSourceRef.current.token,
        }
      )
      .then((res) => {
        setCommentaryIsLoading(false);
        setCommentaryResponse(res.data);
        setCommentaryError(null);
      })
      .catch((error) => {
        console.log(error);
        setCommentaryIsLoading(false);
        setCommentaryError(error);
        setCommentaryResponse(null);
      });

    setCommentaryError(null);
    setCommentaryResponse(null);
    setCommentaryIsLoading(true);
  }

  const cancelCommentary = () => {
    if (cancelCommentaryTokenSourceRef.current) {
      cancelCommentaryTokenSourceRef.current.cancel(
        "Request cancelled manually."
      );
    }
  };

  useEffect(() => {
    if (!noCommentary && actionResponse && actionResponse.to_llm) {
      getCommentary(actionResponse.to_llm);
    } else {
      setCommentaryError(null);
      setCommentaryResponse(null);
    }
  }, [actionResponse]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* Show Agent */}
      {actionResponse.figures &&
        actionResponse.figures
          .filter((figure) => figure.type === "PILL_STATIC")
          .map((figure, index) => (
            <ActionReturnFigure key={index} figure={figure} />
          ))}
      {/* Show error message */}
      {actionResponse.error && (
        <ListItemText
          sx={{
            color: colors.redAccent[200],
            "& .chat": { fontWeight: "bold" },
          }}
        >
          <ReactMarkdown remarkPlugins={[remarkGfm]} className="chat">
            {actionResponse.error}
          </ReactMarkdown>
        </ListItemText>
      )}

      {/* Show assistant response */}
      {actionResponse.to_user && (
        <ListItemText
          sx={{
            color: colors.primary[200],
            "& .chat": { fontWeight: "400" },
          }}
        >
          <ReactMarkdown remarkPlugins={[remarkGfm]} className="chat">
            {actionResponse.to_user}
          </ReactMarkdown>
        </ListItemText>
      )}

      {/* Show figures */}
      {actionResponse.figures &&
        actionResponse.figures
          .filter((figure) => figure.type !== "PILL_STATIC")
          .map((figure, index) => (
            <ActionReturnFigure key={index} figure={figure} />
          ))}

      {/* Show commentary */}

      {noCommentary ? null : (
        <>
          {commentaryIsLoading ? (
            <CircularProgress sx={{ mr: "10px" }} size={20} color="secondary" />
          ) : null}

          {commentaryResponse || commentaryError ? (
            <ChatCommentaryResponse
              response={commentaryResponse}
              error={commentaryError}
            />
          ) : null}
        </>
      )}
    </Box>
  );
};

const MemoChatResponse = React.memo(ChatResponse);

export default MemoChatResponse;
