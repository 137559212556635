import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Typography,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  useTheme,
  CircularProgress,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";

const ParameterPill = ({
  children,
  isLoading,
  defaultExpanded,
  defaultCaption,
  loadingCaption,
  iconExpanded,
  iconSmall,
  pillColor,
  sx = {},
}) => {
  const theme = useTheme();

  const [expanded, setExpanded] = React.useState(
    defaultExpanded ? "panel1" : false
  );
  const handleExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  pillColor = pillColor ? pillColor : theme.palette.secondary.main;

  return (
    <Accordion
      sx={{
        ...{
          "&.MuiAccordion-root": {
            borderColor: pillColor,
            borderLeftWidth: "1px",
            borderRightWidth: "1px",
            borderTopWidth: "0px",
            borderBottomWidth: "1px",
            borderStyle: expanded === "panel1" ? "solid" : "none",
            borderRadius: "14px",
            padding: "0px",
          },
          borderRadius: "14px",
          width: expanded === "panel1" ? "100%" : "max-content", // Adjust the width based on expanded state
        },
        ...sx,
      }}
      expanded={expanded === "panel1"}
      onChange={handleExpansion("panel1")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          padding: "0px 16px 0px 16px",
          minHeight: "32px",
          "& .MuiAccordionSummary-content": {
            margin: "4px 0", // Adjust as required
          },
          borderRadius: expanded === "panel1" ? "14px 14px 0px 0px" : "14px",
          backgroundColor: pillColor,
          color: theme.palette.getContrastText(pillColor),
        }}
      >
        {isLoading ? (
          <CircularProgress
            sx={{ mt: "4px", mr: "10px" }}
            size={16}
            color="primary"
          />
        ) : iconExpanded && expanded === "panel1" ? (
          iconExpanded
        ) : iconSmall && expanded !== "panel1" ? (
          iconSmall
        ) : (
          <SettingsIcon
            sx={{
              mr: "10px",
              fontSize: expanded === "panel1" ? "1.8rem" : "1.3rem", // Larger size when expanded
            }}
          />
        )}
        <Typography
          sx={{
            flexShrink: 0,
            fontWeight: expanded === "panel1" ? "bold" : "normal",
            fontSize: expanded === "panel1" ? "1.2rem" : null,
          }}
        >
          {isLoading
            ? loadingCaption
              ? loadingCaption
              : "Performing analysis..."
            : defaultCaption
            ? defaultCaption
            : "Advanced Parameters"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          overflowX: "hidden",
          maxWidth: expanded === "panel1" ? "100%" : "50%",
          transition: "max-width 0.3s ease-in-out",
        }}
      >
        {expanded ? children : null}
      </AccordionDetails>
    </Accordion>
  );
};

export default ParameterPill;
