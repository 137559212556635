import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  Switch,
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Box,
} from "@mui/material";
import { Autocomplete } from "@mui/lab";
import CircularProgressButton from "../subcomponents/CircularProgressButton";
import axios from "axios";
import { ApiContext } from "../../App.js";
import { useImmer } from "use-immer";
import ChatResponse from "../chat/ChatResponse";
import ParameterPill from "../subcomponents/ParameterPill";

const DynamicFormContent = ({
  variables,
  ui_controls,
  auto_submit,
  refresh_url,
  sessionId,
  setIsLoading,
  setActionResponse,
  executeQueryFn,
  cancelQueryFn,
}) => {
  const [formState, setFormState] = useState({ ...variables });

  useEffect(() => {
    if (auto_submit) {
      executeQueryHandler();
    }
  }, []);

  const executeQueryHandler = () => {
    executeQueryFn(refresh_url, formState);
  };

  const handleInputChange = (bind_variable, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [bind_variable]: value,
    }));
  };

  const handleChoiceFilter = (control) => {
    if (control.filters) {
      const filtered = control.choices.filter((choice) =>
        control.filters.every(
          (filterVar) => choice[filterVar] === formState[filterVar]
        )
      );

      return filtered;
    }
    return control.choices;
  };

  const getControl = (control, index) => {
    switch (control.control_type) {
      case "HEADING_TEXT":
        return <Typography variant="h5">{control.label}</Typography>;
      case "BODY_TEXT":
        return <Typography variant="body1">{control.label}</Typography>;
      case "STRING":
      case "INTEGER":
      case "FLOAT":
      case "DATETIME":
        return (
          <TextField
            size="small"
            variant="outlined"
            label={control.label}
            fullWidth
            value={formState[control.bind_variable] || ""}
            onChange={(e) =>
              handleInputChange(control.bind_variable, e.target.value)
            }
          />
        );
      case "SELECT":
        return (
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel shrink htmlFor={`select-${control.bind_variable}`}>
              {control.label}
            </InputLabel>
            <Select
              value={formState[control.bind_variable]}
              onChange={(e) =>
                handleInputChange(control.bind_variable, e.target.value)
              }
              input={
                <OutlinedInput
                  label={control.label}
                  name={`select-${control.bind_variable}`}
                  id={`select-${control.bind_variable}`}
                />
              }
            >
              {control.choices.map((choice, index) => (
                <MenuItem value={choice.key} key={index}>
                  {choice.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "AUTOCOMPLETE":
        const choices = handleChoiceFilter(control);
        return (
          <Autocomplete
            fullWidth
            options={choices}
            size="small"
            getOptionLabel={(option) => option.value}
            value={
              choices.find(
                (choice) => choice.key === formState[control.bind_variable]
              ) || null
            }
            onChange={(_, newValue) =>
              handleInputChange(
                control.bind_variable,
                newValue ? newValue.key : ""
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={control.label}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        );
      case "CHECKBOX":
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={formState[control.bind_variable]}
                onChange={(e) =>
                  handleInputChange(control.bind_variable, e.target.checked)
                }
              />
            }
            label={control.label}
          />
        );
      case "TOGGLE":
        return (
          <FormControlLabel
            control={
              <Switch
                checked={formState[control.bind_variable]}
                onChange={(e) =>
                  handleInputChange(control.bind_variable, e.target.checked)
                }
              />
            }
            label={control.label}
          />
        );
      case "SUBMIT_BUTTON":
        return (
          <Button variant="contained" fullWidth>
            {control.label}
          </Button>
        );
      default:
        return null;
    }
  };

  const generateRows = () => {
    const rows = [[]];

    ui_controls?.forEach((control) => {
      if (control.same_row && rows[rows.length - 1].length) {
        rows[rows.length - 1].push(control);
      } else {
        rows.push([control]);
      }
    });

    return rows.filter((row) => row.length); // Filter out any empty rows
  };

  const rows = generateRows();

  return (
    <Box>
      <form style={{ paddingTop: "16px" }}>
        {" "}
        {/* Added padding to the top */}
        <Grid container spacing={2}>
          {rows.map((row, rowIndex) => (
            <Grid item xs={12} key={rowIndex}>
              <Grid container spacing={2}>
                {row.map((control, index) => (
                  <Grid
                    item
                    xs={
                      control.same_row ||
                      (index < row.length - 1 && row[index + 1].same_row)
                        ? 6
                        : 12
                    }
                    key={index}
                    style={{ minHeight: "64px" }}
                  >
                    {" "}
                    {/* Added minHeight to ensure uniform height */}
                    {getControl(control, index)}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
        {/* Adding CircularProgressButton here, outside the Grid */}
        <div style={{ marginTop: "20px", width: "150px", height: "50px" }}>
          <CircularProgressButton
            buttonLabel="Fetch"
            cancelLabel="Cancel"
            clickHandler={(event) => executeQueryHandler()}
            cancelHandler={(event) => cancelQueryFn()}
            //  isLoading={isLoading}
          />
        </div>
      </form>
    </Box>
  );
};

function DynamicForm({
  variables,
  ui_controls,
  auto_submit,
  refresh_url,
  sessionId,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const [actionResponse, setActionResponse] = useState(false);

  const cancelTokenSourceRef = useRef(null);

  useEffect(() => {
    if (auto_submit) {
      executeQuery(refresh_url, variables);
    }
  }, []);

  function executeQuery(refresh_url, variables) {
    cancelTokenSourceRef.current = axios.CancelToken.source();

    // console.log("Dynamic Form--ui_controls:", JSON.stringify(ui_controls));
    axios
      .post(refresh_url, variables, {
        timeout: 600000, // 10 minutes in milliseconds
        cancelToken: cancelTokenSourceRef.current.token,
      })
      .then((res) => {
        setIsLoading(false);
        setActionResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setActionResponse({});
      });
    setIsLoading(true);
  }

  const cancelQuery = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel("Request cancelled manually.");
    }
  };

  return (
    <Box sx={{ mt: "15px" }}>
      <ParameterPill isLoading={isLoading} defaultExpanded={!auto_submit}>
        <DynamicFormContent
          variables={variables}
          ui_controls={ui_controls}
          auto_submit={false}
          refresh_url={refresh_url}
          sessionId={sessionId}
          setIsLoading={setIsLoading}
          setActionResponse={setActionResponse}
          executeQueryFn={executeQuery}
          cancelQueryFn={cancelQuery}
        ></DynamicFormContent>
      </ParameterPill>
      {actionResponse ? (
        <Box sx={{ mt: "20px" }}>
          <ChatResponse
            actionResponse={actionResponse}
            sessionId={sessionId}
            queryBuilderParamValues={null}
          />
        </Box>
      ) : null}
    </Box>
  );
}

export default DynamicForm;
