import React, { useState } from "react";
import {
  Box,
  useTheme,
  IconButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import AssistantIcon from "@mui/icons-material/Assistant";
import DeleteIcon from "@mui/icons-material/Delete";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown"; // import thumbsUpDown icon
import ThumbUpIcon from "@mui/icons-material/ThumbUp"; // import thumbsUp icon
import ThumbDownIcon from "@mui/icons-material/ThumbDown"; // import thumbsDown icon
import { tokens } from "../../theme";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { CircularProgress } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const ChatCommentaryResponse = ({ response, error }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* Show error message */}
      {error && (
        <ListItemText
          sx={{
            color: colors.redAccent[200],
            "& .chat": { fontWeight: "bold" },
          }}
        >
          <ReactMarkdown remarkPlugins={[remarkGfm]} className="chat">
            {error}
          </ReactMarkdown>
        </ListItemText>
      )}

      {/* Show assistant response */}
      {response && (
        <ListItemText
          sx={{
            pr: "75px",
            color: colors.primary[200],
            "& .chat": { fontWeight: "400" },
          }}
        >
          <ReactMarkdown remarkPlugins={[remarkGfm]} className="chat">
            {response}
          </ReactMarkdown>
        </ListItemText>
      )}
    </Box>
  );
};

export default ChatCommentaryResponse;
